import React, { useEffect, useState } from "react";
import Input from "../../../../components/Input";
import InputRadio from "../../../../components/InputRadio";
import { getIndexFromObjectArray } from "../../../../helpers/helpers";
import { convertToPercentage } from "../../../../helpers/masks";
import { createOptions } from "../../../../helpers/selects";
import { convertIntToMoney, convertMoenyToInt, convertPercentageToFloat } from "../../../../helpers/conversions";

const Percentage = ({param, productParams, setProductParams, totalSalePrice}) => {
    const [index, setIndex] = useState(null);
    const [value, setValue] = useState('R$ 0,00');

    useEffect(() => {
        let toIndex = getIndexFromObjectArray(productParams, 'id', param.id);
        setIndex(toIndex);
    }, [productParams]);

    const handleParamChange = value => {
        setProductParams(prev => {
            let toProductParams = [...prev];
            toProductParams[index].value = value;
            return toProductParams;
        });
    }

    useEffect(() => {
        getValue();
    }, [totalSalePrice, productParams[index]?.value]);

    const getValue = () => {
        let total = totalSalePrice ? convertMoenyToInt(totalSalePrice) : 0;
        let calculatedValue = total * convertPercentageToFloat(productParams[index]?.value ?? '0');
        let convertedValue = convertIntToMoney(calculatedValue, true);
        setValue(convertedValue);
    }

    return (
        <>
            {index !== null &&
                <>
                    <div className="modal-section-title">
                        <div className="left">
                            <h2>{param.name}</h2>
                        </div>
                    </div>
                    <div className="section">
                        <div className="row">
                            <div className="col-3">
                                <Input 
                                    label={`Porcentagem`}
                                    value={productParams[index].value ?? convertToPercentage('0')}
                                    change={handleParamChange}
                                    mask={`convertToPercentage`}
                                />
                            </div>
                            <div className="col-3">
                                <Input
                                    label={`Valor`}
                                    disabled={true}
                                    value={value}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default Percentage;