import moment from "moment";
import React, { useEffect, useState } from "react";
import Input from "../../../components/Input";
import InputCheckboxUnique from "../../../components/InputCheckboxUnique";
import InputEditorTiny from "../../../components/InputEditorTiny";
import InputSelect from "../../../components/InputSelect";
import InputTextarea from "../../../components/InputTextarea";
import { convertIntToMoney } from "../../../helpers/conversions";
import { createOptions } from "../../../helpers/selects";
import api from "../../../services/api";
import { hasPermission } from "../../../services/auth";
import InputDate from "../../../components/InputDate";
import SelectShippingCompany from "../../../components/selects/SelectShippingCompany";

const shippingTypeOptions = [
    {label: 'Não informado', value: null},
    {label: 'CIF', value: 'CIF'},
    {label: 'FOB', value: 'FOB'}
];

const OrderDetailTab = ({
    order, 
    shippingDate,
    setShippingDate,
    financeComments,
    setFinanceComments,
    comments,
    setComments,
    shippingTypeReal,
    setShippingTypeReal,
    shippingFeeReal,
    setShippingFeeReal,
    shippingTimeReal,
    setShippingTimeReal,
    paymentTermReal,
    setPaymentTermReal,
    paymentFormReal,
    setPaymentFormReal,
    billingCompanyCnpjReal,
    setBillingCompanyCnpjReal,
    shippingTypeConference,
    setShippingTypeConference,
    shippingFeeConference,
    setShippingFeeConference,
    shippingTimeConference,
    setShippingTimeConference,
    paymentTermConference,
    setPaymentTermConference,
    paymentFormConference,
    setPaymentFormConference,
    billingCompanyCnpjConference,
    setBillingCompanyCnpjConference,
    shippingCompany,
    setShippingCompany,
    entregue,
    setEntregue
}) => {
    const setOriginalShippingType = e => {
        e.preventDefault();
        setShippingTypeReal(order.shipping_type);
    }

    const setOriginalShippingFee = e => {
        e.preventDefault();
        let fee = order.shipping_consult ? 'R$ 0,00' : convertIntToMoney(order.shipping_fee, true);
        setShippingFeeReal(fee);
    }

    const setOriginalShippingTime = e => {
        e.preventDefault();
        setShippingTimeReal(order.shipping_time);
    }

    const setOriginalPaymentTerm = e => {
        e.preventDefault();
        setPaymentTermReal(order.payment_term);
    }

    const setOriginalPaymentForm = e => {
        e.preventDefault();
        setPaymentFormReal(order.payment_form);
    }

    const setOriginalBillingCompanyCnpj = e => {
        e.preventDefault();
        setBillingCompanyCnpjReal(order.billing_company_cnpj);
    }

    return (
        <>
            <div className="row">
                <div className="col-3">
                    <Input label={`Código do pedido`} value={order?.code} disabled={true} />
                </div>
                <div className="col-3">
                    <Input label={`Consultor`} value={order?.admin_name} disabled={true} />
                </div>
            </div>
            {order?.type === "PJ" &&
                <div className="row">
                    <div className="col-3">
                        <Input label={`CNPJ`} value={order?.customer_company_cnpj} disabled={true} />
                    </div>
                    <div className="col-4">
                        <Input label={`Razão social`} value={order?.customer_company_company_name} disabled={true} />
                    </div>
                    <div className="col-4">
                        <Input label={`Nome fantasia`} value={order?.customer_company_trade_name} disabled={true} />
                    </div>
                </div>
            }
            {order?.type === "PF" &&
                <div className="row">
                    <div className="col-3">
                        <Input label={`CPF`} value={order?.customer_cpf ?? ''} disabled={true} />
                    </div>
                    <div className="col-3">
                        <Input label={`Nome`} value={order?.customer_name} disabled={true} />
                    </div>
                </div>
            }
            
            <div className="row">
                <div className="col-3">
                    <Input label={`Total`} value={`R$ ${convertIntToMoney(order?.total)}`} disabled={true} />
                </div>
                <div className="col-3">
                    <Input label={`Cond. de pagto.`} value={order?.payment_term?.name} disabled={true} />
                </div>
                <div className="col-3">
                    <Input label={`Forma de pagto.`} value={order?.payment_form?.name} disabled={true} />
                </div>
                {/* <div className="col-3">
                    <Input label={`CNPJ do faturamento`} value={order?.billing_company_cnpj} disabled={true} />
                </div> */}
            </div>
            <h2 className="section-title">Dados da entrega</h2>
            <div className="row">
                <div className="col-3">
                    <Input label={`CEP`} value={order?.recipient_zip_code} disabled={true} />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <Input type={`text`} label={`Logradouro`} value={order?.recipient_street} disabled={true} />
                </div>
                <div className="col-3">
                    <Input type={`text`} label={`Número`} value={order?.recipient_number} disabled={true} />
                </div>
                <div className="col-3">
                    <Input type={`text`} label={`Complemento`} value={order?.recipient_complement} disabled={true} />
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <Input label={`Estado`} value={order?.recipient_state} disabled={true} />
                </div>
                <div className="col-3">
                    <Input type={`text`} label={`Cidade`} value={order?.recipient_city} disabled={true} />
                </div>
                <div className="col-3">
                    <Input type={`text`} label={`Bairro`} value={order?.recipient_district} disabled={true} />
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <SelectShippingCompany selectedShippingCompany={shippingCompany} setSelectedShippingCompany={setShippingCompany} />
                </div>
                <div className="col-3">
                    <Input label={`Tipo do frete`} value={order?.shipping_type} disabled={true} />
                </div>
                <div className="col-3">
                    <Input label={`Valor do frete`} value={order?.shipping_consult === true ? 'A consultar' : convertIntToMoney(order?.shipping_fee, true)} disabled={true} />
                </div>
                <div className="col-3">
                    <InputDate 
                        label={`Prazo de entrega`} 
                        value={shippingDate} 
                        change={setShippingDate} 
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3" style={{ paddingTop: 18 }}>
                    <InputCheckboxUnique 
                        label={`Pedido entregue`}
                        checked={entregue} 
                        change={setEntregue} 
                    />
                </div>
            </div>
            <h2 className="section-title">&nbsp;</h2>
            {/* <div className="row">
                {hasPermission('view_margem_order') &&
                    <>
                        <div className="col-3">
                            <Input label={`Margem orçamento`} value={`R$ ${convertIntToMoney(order?.margem_orcamento)}`} disabled={true} />
                        </div>
                        <div className="col-3">
                            <Input label={`Margem real`} value={`R$ ${convertIntToMoney(order?.margem_real)}`} disabled={true} />
                        </div>
                        <div className="col-3" >
                            <Input label={`Diferença de margem`} value={`${convertIntToMoney(order?.margem_dif, true)}`} disabled={true} />
                        </div>
                    </>
                }
            </div> */}
            <div className="row">
                <div className="col">
                    <InputEditorTiny
                        label={`Observações do pedido`}
                        value={comments}
                        change={setComments}
                    />
                </div>
                {/* <div className="col-6">
                    <InputEditorTiny
                        label={`Observações financeiras`}
                        value={financeComments}
                        change={setFinanceComments}
                    />
                </div> */}
            </div>
            
        </>
    );
} 

export default OrderDetailTab;