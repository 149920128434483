import React, { useEffect, useState } from "react";
import InputRadio from "../../../../components/InputRadio";
import { getIndexFromObjectArray } from "../../../../helpers/helpers";
import { createOptions } from "../../../../helpers/selects";
import Input from "../../../../components/Input";
import { impostos } from "../impostos";
import { convertFloatToPercentage, convertIntToMoney, convertMoenyToInt, convertPercentageToFloat } from "../../../../helpers/conversions";
import api from '../../../../services/api';
import InputSelect from "../../../../components/InputSelect";
import states from "../../../../seeds/states";

const calculationTypeOptions = [
    { label: 'Cálculo automático', value: 'automatic' },
    { label: 'Cálculo manual', value: 'manual' }
];

const ICMSCompra = ({
    productDestinacao, 
    productDestinoUf, 
    productOrigem, 
    productNcm, 
    index, 
    param, 
    productParams, 
    setProductParams, 
    productTotalCost,
    productICMSCompraRegime,
    setProductICMSCompraRegime,
    productICMSCompraState,
    setProductICMSCompraState
}) => {
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [value, setValue] = useState('R$ 0,00');

    const [calculationType, setCalculationType] = useState('automatic');

    useEffect(() => {
        getValue();
    }, [productTotalCost, productParams[index]?.value]);

    const getValue = () => {
        let total = productTotalCost ? convertMoenyToInt(productTotalCost) : 0;
        let calculatedValue = total * convertPercentageToFloat(productParams[index].value ?? '0');
        let convertedValue = convertIntToMoney(calculatedValue, true);
        setValue(convertedValue);
    }

    useEffect(() => {
        calculate();
    }, [,productDestinoUf,productOrigem,productNcm, productICMSCompraRegime, productICMSCompraState, calculationType]);

    const calculate = async () => {
        if(calculationType === 'manual') return;

        let icms = await getFromApi();
        handleParamChange(convertFloatToPercentage(icms));
    }
    

    const getFromApi = async () => {
        if(!productICMSCompraRegime || !productICMSCompraState) return;

        setLoading(true);

        try {
            let destinacao = productDestinacao === "consumo" ? "3" : "1";
            let origemProduto = productOrigem === "0" ? "NAC" : "IMP";
            let ufDestino = productDestinoUf;
            let ufOrigem = productICMSCompraState;

            const ufsSulSudeste = ['SP', 'RJ', 'MG', 'ES', 'RS', 'SC', 'PR'];
            const ufsNorte = ['AM', 'RR', 'AP', 'PA', 'TO', 'RO', 'AC'];
            const ufsNordeste = ['MA', 'PI', 'CE', 'RN', 'PB', 'PE', 'AL', 'SE', 'BA'];
            const ufsCentroOeste = ['MT', 'MS', 'GO', 'DF'];

            const ufs12 = ufsSulSudeste.filter(uf => uf !== 'ES');
            const ufs7 = ufsNorte.concat(ufsNordeste, ufsCentroOeste, ['ES']);

            console.log({
                ncm: productNcm,
                estado_origem: ufOrigem,
                estado_destino: ufDestino,
                destinacao_mercadoria: destinacao
            });
            const response = await api.get(`imposto/st`, {
                params: {
                    ncm: productNcm,
                    estado_origem: ufOrigem,
                    estado_destino: ufDestino,
                    destinacao_mercadoria: destinacao
                }
            });

            const hasSt = response.data.st;

            setHasError(false);
            setLoading(false);

            if(hasSt){
                return 0;
            } 

            if(productICMSCompraRegime === 'sn'){
                return 0.024;
            }

            if(origemProduto === "IMP"){
                if(ufOrigem === "SP"){
                    return 0.18;
                } else {
                    return 0.04;
                }
            }

            if(ufOrigem === "SP"){
                return 0.18;
            }

            if(ufs12.includes(ufOrigem)){
                return 0.12;
            }

            if(ufs7.includes(ufOrigem)){
                return 0.07;
            }

            throw new Error('ICMS de compra não encontrado.');

        } catch (error) {
            console.log('errorlegis', error);
            setHasError(true);
        }

        setLoading(false);
    }

    const getFromTable = (origemProduto, ufDestino) => {
        let imposto = impostos.filter(imposto => imposto.uf === `${ufDestino} ${origemProduto}`)[0];
        return parseFloat(imposto.aliquota_interestadual);
    }

    const handleParamChange = value => {
        setProductParams(prev => {
            let toProductParams = [...prev];
            toProductParams[index].value = value;
            return toProductParams;
        });
    }

    return (
        <>
            <div className="modal-section-title">
                <div className="left">
                    <h2>{param.name}</h2>
                </div>
                <div className="right">
                    <InputRadio
                        className={`no-margin`}
                        options={calculationTypeOptions}
                        value={calculationType}
                        change={setCalculationType}
                    />
                </div>
            </div>
            <div className="section">
                {loading &&
                    <p style={{ marginBottom: 15}}>Carregando...</p>
                }
                {!loading && !hasError &&
                    <div className="row">
                        <div className="col-3">
                            <InputSelect 
                                label={`UF do fornecedor`} 
                                options={states} 
                                value={productICMSCompraState} 
                                change={setProductICMSCompraState} 
                            />
                        </div>
                        <div className="col-3">
                            <InputSelect
                                label={`Regime do fornecedor`}
                                options={[
                                    { label: "Lucro real ou presumido", value: "lp"},
                                    { label: "Simples nacional", value: "sn"}
                                ]}
                                value={productICMSCompraRegime}
                                change={setProductICMSCompraRegime}
                            />
                        </div>
                        <div className="col-3">
                            <Input 
                                label={`Porcentagem`} 
                                disabled={calculationType === 'automatic'} 
                                value={productParams[index].value} 
                                change={handleParamChange} 
                                mask={`convertToPercentage`} 
                            />
                        </div>
                        <div className="col-3">
                            <Input label={`Valor`} disabled={true} value={value} />
                        </div>
                    </div>
                }
                {!loading && hasError &&
                    <InputRadio 
                        options={createOptions(param.options, 'description', 'value')}
                        value={productParams[index].value}
                        change={handleParamChange}
                    />
                }
            </div>
        </>
    );
}

export default ICMSCompra;