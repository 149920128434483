import React, { useEffect, useState } from "react";
import { renderError } from "../../../helpers/errors";
import { createOptions } from "../../../helpers/selects";
import api from "../../../services/api";
import InputSelect from "../../InputSelect";

export const SelectContaPagarCategoria = ({selectedContaPagarCategoria, setSelectedContaPagarCategoria}) => {
    const [loading, setLoading] = useState(true);
    const [contaPagarCategorias, setContaPagarCategorias] = useState([]);

    useEffect(() => {
        getContaPagarCategorias();
    }, []);

    const getContaPagarCategorias = () => {
        setLoading(true);

        api.get(`/conta-pagar/categoria`).then(res => {
            setContaPagarCategorias(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => {
            setLoading(false);
        });
    }

    return (
        <InputSelect
            loading={loading}
            label={`Categoria`}
            options={createOptions(contaPagarCategorias, 'nome', 'id')} 
            value={selectedContaPagarCategoria} 
            change={setSelectedContaPagarCategoria}
        />
    );
}

export default SelectContaPagarCategoria;