import React, { useEffect, useState } from "react";

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import moment from "moment";
import PageHeader from "../../components/PageHeader";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import InputFile from "../../components/InputFile";
import InputSelect from "../../components/InputSelect";
import { toast } from "react-toastify";
import BoxTabs from "../../components/BoxTabs";
import InputTextarea from "../../components/InputTextarea";
import { ModalDelete } from "../../components/ModalDelete";
import { convertIntToMoney, convertMoenyToInt } from "../../helpers/conversions";

const starOptions = [
    { label: '4 estrelas', value: 4 },
    { label: '5 estrelas', value: 5 }
];

const CaixaList = () => {
    const navigate = useNavigate();
    
    const [loading, setLoading] = useState(true);
    const [caixas, setCaixas] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const [id, setId] = useState('');
    const [nome, setNome] = useState('');
    const [saldo, setSaldo] = useState('R$ 0,00');
    const [text, setText] = useState('');
    const [stars, setStars] = useState(5);

    const [caixaToDelete, setCaixaToDelete] = useState(null);

    useEffect(() => {
        getCaixas();
    }, []);

    useEffect(() => {
        generateData();
    }, [caixas]);

    useEffect(() => {
        if(!showCreate) clean();
    }, [showCreate]);

    useEffect(() => {
        if(!showEdit) clean();
    }, [showEdit]);

    const getCaixas = () => {
        api.get('/caixa').then(res => {
            setCaixas(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Nome'];
        const rows = [];
        
        caixas.map(caixa => {
            rows.push({
                data: [
                    caixa.nome
                ],
                action: () => editCaixa(caixa)
            });
        });

        setData({header, rows});
    }

    const storeCaixa = () => {
        setLoading(true);

        api.post(`/caixa`, {
            nome,
            saldo: convertMoenyToInt(saldo)
        }).then(res => {
            getCaixas();
            setShowCreate(false);
            toast.success('Caixa cadastrada com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const editCaixa = caixa => {
        setId(caixa.id);
        setNome(caixa.nome);
        setSaldo(convertIntToMoney(caixa.saldo, true));
        setShowEdit(true);
    }

    const updateCaixa = () => {
        setLoading(true);

        api.put(`/caixa/${id}`, {
            nome,
            saldo: convertMoenyToInt(saldo)
        }).then(res => {
            getCaixas();
            setShowEdit(false);
            toast.success('Caixa atualizada com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const clean = () => {
        setId('');
        setNome('');
        setSaldo('R$ 0,00');
    }

    const deleteCaixa = () => {
        setLoading(true);

        api.delete(`/caixa/${caixaToDelete}`).then(res => {
            setCaixaToDelete(null);
            setShowEdit(false);
            getCaixas();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            <Modal
                title={`${showCreate ? 'Cadastrar' : 'Editar'} caixa`}
                show={showCreate || showEdit}
                close={() => showCreate ? setShowCreate(false) : setShowEdit(false)}
                footer={
                    <>
                        {showEdit &&
                            <Button
                                type={`secondary`}
                                size={`small`}
                                text={`Excluir`}
                                svg={`delete-small`}
                                action={() => setCaixaToDelete(id)}
                                loading={loading} 
                            />
                        }
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={showCreate ? 'Cadastrar' : 'Salvar alterações'}
                            svg={`save-white`}
                            action={() => showCreate ? storeCaixa() : updateCaixa()}
                            loading={loading} 
                        />
                    </>
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`Nome`} value={nome} change={setNome} />
                        </div>
                        <div className="col-6">
                            <Input type={`text`} label={`Nome`} value={saldo} change={setSaldo} mask={`convertToMoney`} />
                        </div>
                    </div>
                </div>
            </Modal>
            <ModalDelete
                show={caixaToDelete !== null}
                action={deleteCaixa}
                close={() => setCaixaToDelete(null)}
                loading={loading}
            />
            <PanelTemplate id="caixa-list">
                <PageHeader
                    right={
                        <>
                            <Button type={`primary`} size={`small`} text={`Cadastrar caixa`} svg="plus-white" action={() => setShowCreate(true)}  />
                        </>
                    }
                />
                <Box className="no-padding">
                    <Table header={data.header} rows={data.rows} loading={loading} />
                </Box>
            </PanelTemplate>
        </>
    );
}

export default CaixaList;