import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import DotStatus from "../../../components/DotStatus";
import Input from "../../../components/Input";
import InputDate from "../../../components/InputDate";
import InputEditorTiny from "../../../components/InputEditorTiny";
import InputSelect from "../../../components/InputSelect";
import Modal from "../../../components/Modal";
import { ModalDelete } from "../../../components/ModalDelete";
import SelectProductCompany from "../../../components/selects/SelectProductCompany";
import SelectStatus from "../../../components/selects/SelectStatus";
import Table from "../../../components/Table";
import TableImage from "../../../components/TableImage";
import { convertFloatToInt, convertIntToFloat, convertIntToMoney, convertIntToPercentage, convertMoenyToInt } from "../../../helpers/conversions";
import { renderError } from "../../../helpers/errors";
import { createItemOptions, createOptions, createProductOptions } from "../../../helpers/selects";
import api from "../../../services/api";
import { getToken } from "../../../services/auth";
import InputSelectCreatable from "../../../components/InputSelectCreatable";

const printers = [
    {label: 'SLP 650', value: 'slp650'},
    {label: 'SLP 440', value: 'slp440'}
];

const ProductionTab = ({order}) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({rows: [], header:[]});
    const [showCreateProduction, setShowCreateProduction] = useState(false);
    const [showEditProduction, setShowEditProduction] = useState(false);
    const [showPrint, setShowPrint] = useState(false);

    const [orderProductions, setOrderProductions] = useState([]);
    const [orderProductionIdToDelete, setOrderProductionIdToDelete] = useState(null);

    const [orderProductionId , setOrderProductionId ] = useState(null);
    const [orderItemId , setOrderItemId ] = useState(null);
    const [quantityOfPieces , setQuantityOfPieces ] = useState('0');
    const [boxNumber , setBoxNumber ] = useState('');
    const [weight, setWeight] = useState('0,000');

    const [boxSizes, setBoxSizes] = useState([]);
    const [originalBoxSizes, setOriginalBoxSizes] = useState([]);

    const [selectedPrinter, setSelectedPrinter] = useState('slp650');
    const [selectedNf, setSelectedNf] = useState(null);

    const [linhas, setLinhas] = useState({
        linha1: 'asdas',
        linha2: '',
        linha3: '',
        linha4: '',
        linha5: '',
        linha6: '',
        linha7: '',
        linha8: '',
        linha9: '',
        linha10: '',
    });
    
    useEffect(() => {
        if(order){
            getBoxSizes();
            getOrderProductions();
        }
    }, [order]);

    useEffect(() => {
        if(showCreateProduction === false) clean();
    }, [showCreateProduction]);

    useEffect(() => {
        if(showEditProduction === false) clean();
    }, [showEditProduction])

    useEffect(() => {
        generateData();
    }, [orderProductions]);

    const getBoxSizes = () => {
        api.get('/boxsize').then(res => {
            setBoxSizes(res.data.sizes);
            setOriginalBoxSizes(res.data.sizes);
        }).catch(error => renderError(error));
    }

    const getOrderProductions = () => {
        api.get(`/order/production?filters[0][order_id][equal]=${order.id}`).then(res => {
            setOrderProductions(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Código', 'Imagem', 'SKU', 'Nome', 'Quant. de peças', 'Tamanho da caixa', 'Peso'];
        const rows = [];
        
        orderProductions.map(orderProduction => {
            rows.push({
                data: [
                    orderProduction?.code,
                    <TableImage url={orderProduction?.item_image_url} />,
                    orderProduction?.item_sku,
                    orderProduction?.item_name,
                    orderProduction?.quantity_of_pieces,
                    orderProduction?.box_number,
                    convertIntToFloat(orderProduction?.weight, 3) + ' kg'
                ],
                action: () => edit(orderProduction)
            });
        });

        setData({header, rows});
    }

    const edit = orderProduction => {
        setOrderProductionId(orderProduction.id);
        setQuantityOfPieces(orderProduction.quantity_of_pieces);
        setBoxNumber(orderProduction.box_number);
        setWeight(convertIntToFloat(orderProduction.weight, 3));
        setOrderItemId(orderProduction.order_item_id);
        setShowEditProduction(true);
    }

    const storeProduction = () => {
        setLoading(true);

        api.post(`order/production`, {
            quantity_of_pieces: quantityOfPieces,
            box_number: boxNumber,
            weight: convertFloatToInt(weight),
            order_item_id: orderItemId,
            order_id: order.id
        }).then(res => {
            getBoxSizes();
            getOrderProductions();
            setShowCreateProduction(false);
            toast.success('Produção cadastrada com sucesso');
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const updateProduction = () => {
        setLoading(true);

        api.put(`order/production/${orderProductionId}`, {
            quantity_of_pieces: quantityOfPieces,
            box_number: boxNumber,
            weight: convertFloatToInt(weight),
            order_item_id: orderItemId,
            order_id: order.id
        }).then(res => {
            getBoxSizes();
            getOrderProductions();
            setShowEditProduction(false);
            toast.success('Produção atualizada com sucesso');
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const clean = () => {
        setOrderProductionId(null);
        setQuantityOfPieces('0');
        setBoxNumber('');
        setWeight('0,000');
        setOrderItemId(null);
        setBoxSizes(originalBoxSizes);
    }

    const deleteProduction = () => {
        setLoading(true);

        api.delete(`/order/production/${orderProductionIdToDelete}`).then(res => {
            toast.success('Produção deletada com sucesso');
            setOrderProductionIdToDelete(null);
            getOrderProductions();
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const confirmDelete = id => {
        setOrderProductionIdToDelete(id);
        setShowEditProduction(false);
    }

    const print = () => {
        const urlLinhas = Object.keys(linhas).map(key => `${key}=${linhas[key]}`).join('&');
        window.open(`${process.env.REACT_APP_URL}/order/${order.id}/etiquetas?impressora=${selectedPrinter}&nf=${selectedNf}&${urlLinhas}&_token=${getToken()}`);
    }

    const handleChangeBoxNumber = value => {
        if(!boxSizes.includes(value)){
            setBoxSizes([...boxSizes, value]);
        }
        setBoxNumber(value);
    }

    const handleLinhaChange = (linha, value) => {
        let toLinhas = {...linhas};
        toLinhas[`linha${linha}`] = value;
        console.log(toLinhas);
        setLinhas(toLinhas);
    }

    return (
        <>
            <ModalDelete
                action={deleteProduction}
                close={() => setOrderProductionIdToDelete(null)}
                show={orderProductionIdToDelete !== null}
                loading={loading}
            />
            {(showCreateProduction || showEditProduction) &&
            <Modal 
                show={true}
                size={'big'}
                close={() => showCreateProduction ?  setShowCreateProduction(false) : setShowEditProduction(false)}
                title={`${showEditProduction ? 'Editar' : 'Cadastrar'} produção`}
                footer={
                    <>
                        {showEditProduction &&
                            <Button
                                type={`secondary`}
                                size={`small`}
                                text={`Excluir`}
                                svg={`delete-small`}
                                action={() => confirmDelete(orderProductionId)}
                                loading={loading} 
                            />
                        }
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={showCreateProduction ? 'Cadastrar' : 'Salvar alterações'}
                            svg={`save-white`}
                            action={() => showCreateProduction ? storeProduction() : updateProduction()}
                            loading={loading} 
                        />
                    </>
                }
            >
                <div className="section">
                    <InputSelect
                        label={`Produto`}
                        options={createItemOptions(order.items)} 
                        value={orderItemId} 
                        change={setOrderItemId}
                    />
                </div>
                <div className="section">
                    <div className="row">
                        <div className="col-4">
                            <Input 
                                label={`Quant. de peças`} 
                                value={quantityOfPieces} 
                                change={setQuantityOfPieces}
                                mask={`convertToInt`}
                            />
                        </div>
                        <div className="col-4">
                            <InputSelectCreatable 
                                isCreatable={true}
                                options={createOptions(boxSizes)}
                                label={`Tamanho da caixa`} 
                                value={boxNumber} 
                                change={handleChangeBoxNumber} 
                                // change={setBoxNumber} 
                            />
                        </div>
                        <div className="col-4">
                            <Input 
                                label={`Peso`} 
                                value={weight} 
                                change={setWeight}
                                mask={`convertToWeight`}
                                right={<span>kg</span>}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            }
            {showPrint &&
                <Modal 
                    show={true}
                    close={() => setShowPrint(false)}
                    title={`Imprimir etiquetas`}
                    footer={
                        <>
                            <Button
                                type={`primary`}
                                size={`small`}
                                text={`Imprimir etiquetas`}
                                // svg={`save-white`}
                                action={print}
                                loading={loading} 
                            />
                        </>
                    }
                >
                    <div className="section">
                        <div className="row">
                            <div className="col-6">
                                <InputSelect
                                    label={`Impressora`} 
                                    options={printers}
                                    value={selectedPrinter} 
                                    change={setSelectedPrinter}
                                />
                            </div>
                            <div className="col-6">
                                <InputSelect
                                    label={`Numéro da nota fiscal`} 
                                    options={createOptions(order.nfs, 'numero', 'numero')}
                                    value={selectedNf} 
                                    change={setSelectedNf}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-section-title">
                        <h2>Linhas adicionais</h2>
                        
                    </div>
                    {[1,2,3,4,5,6,7,8,9,10].map(linha => (
                        <div className="section">
                            <Input
                                label={`Linha adicional ${linha}`}
                                value={linhas[`linha${linha}`]}
                                change={value => handleLinhaChange(linha, value)}
                            />
                        </div>
                    ))}
                </Modal>
            }
            <div className="row">
                <div className="col-2">
                    <Button 
                        full={true}
                        type={`secondary`}
                        size={`small`}
                        text={`Cadastrar produção`}
                        action={() => setShowCreateProduction(true)}
                    />
                    <br />
                </div>
                <div className="col-2">
                    <Button 
                        full={true}
                        type={`secondary`}
                        size={`small`}
                        text={`Imprimir etiquetas`}
                        action={() => setShowPrint(true)}
                    />
                    <br />
                </div>
            </div>
            <Table rows={data.rows} header={data.header} loading={loading} />
        </>
    );
} 

export default ProductionTab;