import React, { useEffect, useState } from "react";
import Input from "../../../components/Input";
import InputSelect from "../../../components/InputSelect";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import api from "../../../services/api";
import { renderError } from "../../../helpers/errors";
import { toast } from "react-toastify";
import MultipleValueOptions from "./MultipleValueOptions";
import MultiplePercentageOptions from "./MultiplePercentageOptions";
import { ModalDelete } from "../../../components/ModalDelete";
import { createOptions } from "../../../helpers/selects";
import ConditionalPercentageOptions from "./ConditionalPercentageOptions";
import Extra from "./Extra";
import { convertToInt } from "../../../helpers/masks";
import InputTextarea from "../../../components/InputTextarea";
import InputCheckboxUnique from "../../../components/InputCheckboxUnique";

const variableTypeOptions = [
    { label: 'Múltiplos valores', value: 'multiple_value'},
    { label: 'Múltiplos valores com quantidade', value: 'multiple_value_with_quantity'},
    { label: 'Múltiplas porcentagens', value: 'multiple_percentage'},
    { label: 'Porcentagem', value: 'percentage'},
    // { label: 'Porcentagem condicional', value: 'conditional_percentage'},
    { label: 'Quantidade e preço', value: 'quantity_and_price'},
    { label: 'Extra', value: 'extra'}
];

const ModalProductVariable = ({close, params, getParams, loading, setLoading, paramToEdit, users}) => {
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [options, setOptions] = useState([]);
    const [position, setPosition] = useState(0);
    const [relationalParam, setRelationalParam] = useState('');
    const [formulaTotalReal, setFormulaTotalReal] = useState('');
    const [formulaTotalQuote, setFormulaTotalQuote] = useState('');
    const [required, setRequired] = useState(false);
    const [restricted, setRestricted] = useState(false);
    const [value, setValue] = useState('0,0000 %');

    const [confirmDeleteParam, setConfirmDeleteParam] = useState(false);

    useEffect(() => {
        getParam();
    }, []);

    useEffect(() => {
        if(paramToEdit === null){
            setOptions([]);
        }
    }, [type]);

    const getParam = () => {
        if(paramToEdit !== null){
            setId(paramToEdit.id);
            setName(paramToEdit.name);
            setPosition(paramToEdit.position);
            setValue(paramToEdit.value);
            setType(paramToEdit.type);
            setOptions(paramToEdit.options);
            setRelationalParam(paramToEdit.param_id ?? '');
            setFormulaTotalReal(paramToEdit.formula_total_real ?? '');
            setFormulaTotalQuote(paramToEdit.formula_total_quote ?? '');
            setRequired(paramToEdit.required);
            setRestricted(paramToEdit.restricted);
        }
    }

    const storeParam = () => {
        const validation = validate();
        if(!validation) return;

        setLoading(true);

        api.post(`/param`, {
            name,
            type,
            options,
            value,
            param_id: relationalParam,
            position,
            formula_total_real: formulaTotalReal,
            formula_total_quote: formulaTotalQuote,
            required,
            restricted
        }).then(res => {
            toast.success('Variável cadastrada com sucesso');
            getParams();
            close();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const updateParam = () => {
        const validation = validate();
        if(!validation) return;

        setLoading(true);

        api.put(`/param/${id}`, {
            name,
            type,
            options,
            value,
            param_id: relationalParam,
            position,
            formula_total_real: formulaTotalReal,
            formula_total_quote: formulaTotalQuote,
            required,
            restricted
        }).then(res => {
            toast.success('Variável atualizada com sucesso');
            getParams();
            close();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const validate = () => {
        if(type === 'multiple_percentage'){
            let optionsHasValue = false;
            options.map(option => {
                if(option.value === value) optionsHasValue = true;
            });
            if(!optionsHasValue){
                alert('O valor padrão precisa estar entre as opções');
                return false;
            }
        }

        return true;
    }

    const deleteParam = () => {
        setLoading(true);

        api.delete(`/param/${id}`).then(res => {
            toast.success('Variável excluída com sucesso');
            getParams();
            close();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            {confirmDeleteParam &&
                <ModalDelete
                    show={true}
                    close={() => setConfirmDeleteParam(false)}
                    action={deleteParam}
                    loading={loading}
                />
            }
            {!confirmDeleteParam &&
                <Modal
                    close={close}
                    show={true}
                    title={`${paramToEdit !== null ? 'Editar' : 'Cadastrar'} variável`}
                    footer={
                        <>
                            {paramToEdit !== null &&
                                <Button 
                                    type={`secondary`} 
                                    size={`small`} 
                                    text={`Excluir`} 
                                    svg={`delete-small`} 
                                    action={() => setConfirmDeleteParam(true)}
                                />
                            }
                            <Button 
                                type={`primary`} 
                                size={`small`} 
                                text={paramToEdit !== null ? 'Salvar alterações' : 'Cadastrar'} 
                                svg={`save-white`} 
                                action={() => paramToEdit !== null ? updateParam() : storeParam()}
                                loading={loading}
                            />
                        </>
                    }
                >
                    <div className="section">
                        
                            <div className="row">
                                {type === 'multiple_percentage' &&
                                    <div className="col">
                                        <InputCheckboxUnique label={`Preenchimento obrigatório`} change={setRequired} checked={required} />
                                    </div>
                                }
                                <div className="col">
                                    <InputCheckboxUnique label={`Visível somente com permissão`} change={setRestricted} checked={restricted} />
                                </div>
                            </div>
                        
                        <div className="row">
                            <div className="col-6">
                                <Input type={`text`} label={`Nome`} value={name} change={setName} />
                            </div>
                            <div className="col-6">
                                <InputSelect label={`Tipo`} isDisabled={paramToEdit !== null} options={variableTypeOptions} value={type} change={setType} />
                            </div>
                        </div>
                        {type === 'conditional_percentage' &&
                            <InputSelect 
                                label={`Variável relacional`}
                                isDisabled={paramToEdit !== null}
                                options={createOptions(
                                    params.filter(p => p.type === 'multiple_percentage'),
                                    'name',
                                    'id'
                                )}
                                value={relationalParam}
                                change={setRelationalParam} 
                            />
                        }
                        <div className="row">
                            {(type === 'percentage' || type === 'multiple_percentage') &&
                                <div className="col-6">
                                    <Input type={`text`} 
                                        label={`Valor padrão`} 
                                        value={value} 
                                        change={setValue} 
                                        mask={`convertToPercentage`}
                                    />
                                </div>
                            }
                            <div className="col-6">
                                <Input type={`text`} label={`Posição`} value={position} change={value => setPosition( convertToInt(value) )} />
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col">
                                <InputTextarea
                                    label={`Fórmula para o total orçamento`} 
                                    value={formulaTotalQuote} 
                                    change={setFormulaTotalQuote} 
                                    rows={3}
                                />
                                <InputTextarea
                                    label={`Fórmula para o total real`} 
                                    value={formulaTotalReal} 
                                    change={setFormulaTotalReal} 
                                    rows={3}
                                />
                                <p>Use apenas os seguintes caraceteres: +, -, /, *, (, ) <br />
                                Para utilizar variáveis, coloque-as entre colchetes. Exemplo: [Custo unitário]<br />
                                Você pode usar a variável [Preço de venda]
                                </p>
                                <br />
                            </div>
                        </div> */}
                    </div>

                    
                    

                    {(type === 'multiple_value' || type === 'multiple_value_with_quantity') &&
                        <MultipleValueOptions options={options} setOptions={setOptions} />
                    }

                    {type === 'multiple_percentage' &&
                        <MultiplePercentageOptions users={users} options={options} setOptions={setOptions} />
                    }

                    {type === 'conditional_percentage' &&
                        <ConditionalPercentageOptions 
                            params={params} 
                            relationalParam={relationalParam} 
                            options={options} 
                            setOptions={setOptions}
                        />
                    }

                    {type === 'extra' &&
                        <Extra
                            options={options} 
                            setOptions={setOptions}
                        />
                    }

                </Modal>
            }
        </>
    );
}

export default ModalProductVariable;