import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import InputDate from "../../../components/InputDate";
import InputEditorTiny from "../../../components/InputEditorTiny";
import InputSelect from "../../../components/InputSelect";
import Modal from "../../../components/Modal";
import { ModalDelete } from "../../../components/ModalDelete";
import SelectProductCompany from "../../../components/selects/SelectProductCompany";
import Table from "../../../components/Table";
import TableImage from "../../../components/TableImage";
import { convertIntToMoney, convertMoenyToInt } from "../../../helpers/conversions";
import { renderError } from "../../../helpers/errors";
import { createItemOptions, createProductOptions } from "../../../helpers/selects";
import api from "../../../services/api";
import SelectShippingCompany from "../../../components/selects/SelectShippingCompany";
import SelectPaymentForm from "../../../components/selects/SelectPaymentForm";
import SelectPaymentTerm from "../../../components/selects/SelectPaymentTerm";

const logisticsOptions = [
    {label: 'Asga retira', value: 'asga_retira'},
    {label: 'Fornecedor entrega', value: 'fornecedor_entrega'}
];

const PurchaseTab = ({order}) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({rows: [], header:[]});
    const [showCreatePurchase, setShowCreatePurchase] = useState(false);
    const [showEditPurchase, setShowEditPurchase] = useState(false);

    const [orderPurchases, setOrderPurchases] = useState([]);
    const [orderPurchaseIdToDelete, setOrderPurchaseIdToDelete] = useState(null);

    const [orderPurchaseId , setOrderPurchaseId ] = useState(null);
    const [quantity , setQuantity ] = useState('0');
    const [originalQuantity , setOriginalQuantity ] = useState(0);
    const [originalCompany , setOriginalCompany ] = useState('');
    const [quebra , setQuebra ] = useState('0');
    const [unitCost , setUnitCost ] = useState('R$ 0,00');
    const [originalCost , setOriginalCost ] = useState('R$ 0,00');
    const [quebraCost , setQuebraCost ] = useState('R$ 0,00');
    const [totalCost, setTotalCost] = useState('R$ 0,00');
    const [shippingCost, setShippingCost] = useState('R$ 0,00');
    const [logistic , setLogistic ] = useState(null);
    const [deadline , setDeadline ] = useState(null);
    const [comments , setComments ] = useState('');
    const [paymentFormId , setPaymentFormId ] = useState(null);
    const [paymentTermId , setPaymentTermId ] = useState(null);
    const [orderItemId , setOrderItemId ] = useState(null);
    const [productCompanyId, setProductCompanyId] = useState(null);
    const [shippingCompanyId, setShippingCompanyId] = useState(null);

    useEffect(() => {
        if(order){
            getOrderPurchases();
        }
    }, [order]);

    useEffect(() => {
        if(showCreatePurchase === false) clean();
    }, [showCreatePurchase]);

    useEffect(() => {
        if(showEditPurchase === false) clean();
    }, [showEditPurchase])

    useEffect(() => {
        generateData();
    }, [orderPurchases]);

    useEffect(() => {
        getTotalCost();
    }, [unitCost, quebraCost, quantity, quebra]);

    useEffect(() => {
        getQuantityAndCost();
    }, [orderItemId]);


    const getQuantityAndCost = () => {
        let items = order.items.filter(item => item.id === orderItemId);
        if(items.length === 0){
            return;
        } else {
            if(orderPurchaseId === null){
                setQuantity(items[0].quantity);
            }
            setOriginalCost( convertIntToMoney(items[0].cost, true) );
            setOriginalQuantity( items[0].quantity );
            setOriginalCompany( items[0].company_trade_name ?? '' );
        }
    }

    const getOrderPurchases = () => {
        api.get(`/order/purchase?filters[0][order_id][equal]=${order.id}`).then(res => {
            setOrderPurchases(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const getTotalCost = () => {
        let productValue = convertMoenyToInt(unitCost);
        let costValue = convertMoenyToInt(quebraCost);
        let total = (productValue * quantity) + (costValue * quebra);
        setTotalCost( convertIntToMoney(total, true) );
    }

    const generateData = () => {
        const header = ['Pedido', 'Imagem', 'Produto', 'Fornecedor', 'Prazo do fornecedor','Total', 'Download'];
        const rows = [];
        
        orderPurchases.map(orderPurchase => {
            const buttons = [
                <Button 
                    type={`secondary`}
                    size={`small`}
                    svg={`download`}
                    text={`Download`}
                    action={() => window.open(orderPurchase.pdf_url)}
                />
            ];

            rows.push({
                data: [
                    orderPurchase?.code,
                    <TableImage url={orderPurchase?.item_image_url} />,
                    `${orderPurchase.item_code} - ${orderPurchase?.item_sku} ${orderPurchase?.item_name}`,
                    orderPurchase?.company_name,
                    moment(orderPurchase?.deadline).format('DD/MM/YYYY'),
                    convertIntToMoney(orderPurchase?.total, true)
                ],
                action: () => edit(orderPurchase),
                buttons
            });
        });

        setData({header, rows});
    }

    const edit = orderPurchase => {
        setOrderPurchaseId(orderPurchase.id);
        setQuantity(orderPurchase.quantity);
        setQuebra(orderPurchase.quebra);
        setUnitCost(convertIntToMoney(orderPurchase.unit_cost, true));
        setQuebraCost(convertIntToMoney(orderPurchase.quebra_cost, true));
        setShippingCost(convertIntToMoney(orderPurchase.shipping_cost, true));
        setShippingCompanyId(orderPurchase.shipping_company_id);
        setLogistic(orderPurchase.logistic);
        setDeadline(new Date(`${orderPurchase.deadline} 00:00:00`));
        setComments(orderPurchase.comments);
        setPaymentFormId(orderPurchase.payment_form_id);
        setPaymentTermId(orderPurchase.payment_term_id);
        setOrderItemId(orderPurchase.order_item_id);
        setProductCompanyId(orderPurchase.product_company_id);
        setShowEditPurchase(true);
    }

    const storePurchase = () => {
        setLoading(true);

        api.post(`order/purchase`, {
            quantity,
            quebra,
            unit_cost: convertMoenyToInt(unitCost),
            quebra_cost: convertMoenyToInt(quebraCost),
            shipping_cost: convertMoenyToInt(shippingCost),
            shipping_company_id: shippingCompanyId,
            logistic,
            deadline: moment(deadline).format('YYYY-MM-DD'),
            comments,
            payment_form_id: paymentFormId,
            payment_term_id: paymentTermId,
            order_item_id: orderItemId,
            order_id: order.id,
            product_company_id: productCompanyId
        }).then(res => {
            getOrderPurchases();
            setShowCreatePurchase(false);
            toast.success('Pedido cadastrado com sucesso');
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const updatePurchase = () => {
        setLoading(true);

        api.put(`order/purchase/${orderPurchaseId}`, {
            quantity,
            quebra,
            unit_cost: convertMoenyToInt(unitCost),
            quebra_cost: convertMoenyToInt(quebraCost),
            shipping_cost: convertMoenyToInt(shippingCost),
            shipping_company_id: shippingCompanyId,
            logistic,
            deadline: moment(deadline).format('YYYY-MM-DD'),
            comments,
            payment_form_id: paymentFormId,
            payment_term_id: paymentTermId,
            order_item_id: orderItemId,
            order_id: order.id,
            product_company_id: productCompanyId
        }).then(res => {
            getOrderPurchases();
            setShowEditPurchase(false);
            toast.success('Pedido atualizado com sucesso');
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const clean = () => {
        setOrderPurchaseId(null);
        setQuantity('0');
        setQuebra('0');
        setUnitCost('R$ 0,00');
        setQuebraCost('R$ 0,00');
        setShippingCost('R$ 0,00');
        setOriginalCost('R$ 0,00');
        setOriginalQuantity(0);
        setOriginalCompany('');
        setShippingCompanyId(null);
        setLogistic(null);
        setDeadline(null);
        setComments('');
        setPaymentFormId(null);
        setPaymentTermId(null);
        setOrderItemId(null);
        setProductCompanyId(null);
    }

    const deletePurchase = () => {
        setLoading(true);

        api.delete(`/order/purchase/${orderPurchaseIdToDelete}`).then(res => {
            toast.success('Pedido deletado com sucesso');
            setOrderPurchaseIdToDelete(null);
            getOrderPurchases();
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const confirmDelete = id => {
        setOrderPurchaseIdToDelete(id);
        setShowEditPurchase(false);
    }

    return (
        <>
            <ModalDelete
                action={deletePurchase}
                close={() => setOrderPurchaseIdToDelete(null)}
                show={orderPurchaseIdToDelete !== null}
                loading={loading}
            />
            {(showCreatePurchase || showEditPurchase) &&
            <Modal 
                show={true}
                size={'big'}
                close={() => showCreatePurchase ?  setShowCreatePurchase(false) : setShowEditPurchase(false)}
                title={`${showEditPurchase ? 'Editar' : 'Cadastrar'} pedido de compra`}
                footer={
                    <>
                        {showEditPurchase &&
                            <Button
                                type={`secondary`}
                                size={`small`}
                                text={`Excluir`}
                                svg={`delete-small`}
                                action={() => confirmDelete(orderPurchaseId)}
                                loading={loading} 
                            />
                        }
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={showCreatePurchase ? 'Cadastrar' : 'Salvar alterações'}
                            svg={`save-white`}
                            action={() => showCreatePurchase ? storePurchase() : updatePurchase()}
                            loading={loading} 
                        />
                    </>
                }
            >
                <div className="section">
                    <InputSelect
                        label={`Produto`}
                        options={createItemOptions(order.items)} 
                        value={orderItemId} 
                        change={setOrderItemId}
                    />
   
                    <div className="row">
                        <div className="col">
                            <Input label={`Quantidade`} value={quantity} change={setQuantity} mask={`convertToInt`} />
                            <span className="pos-input">Original: {originalQuantity}</span>
                        </div>
                        <div className="col">
                            <Input label={`Quebra`} value={quebra} change={setQuebra} mask={`convertToInt`} />
                        </div>
                        <div className="col">
                            <Input label={`Custo unit.`} value={unitCost} change={setUnitCost} mask={`convertToMoney`} />
                            <span className="pos-input">Original: {originalCost}</span>
                        </div>
                        <div className="col">
                            <Input label={`Custo quebra`} value={quebraCost} change={setQuebraCost} mask={`convertToMoney`} />
                        </div>
                        <div className="col">
                            <Input label={`Total`} value={totalCost} disabled={true} />
                        </div>
                    </div>
                </div>
                <div className="section">
                    <SelectProductCompany
                        selectedProductCompany={productCompanyId}
                        setSelectedProductCompany={setProductCompanyId}
                    />
                    <span className="pos-input">Original: {originalCompany !== '' ? originalCompany : 'Não informado'}</span>
                </div>
                <div className="section">
                    <div className="row">
                        <div className="col-4">
                            <SelectPaymentForm
                                selectedPaymentForm={paymentFormId}
                                setSelectedPaymentForm={setPaymentFormId}
                            />
                        </div>
                        <div className="col-4">
                            <SelectPaymentTerm
                                selectedPaymentTerm={paymentTermId}
                                setSelectedPaymentTerm={setPaymentTermId}
                            />
                        </div>
                        <div className="col-4">
                            <InputSelect
                                label={`Logística de entrega`}
                                options={logisticsOptions}
                                value={logistic}
                                change={setLogistic}
                            />
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <InputDate
                                label={`Prazo do fornecedor`} 
                                value={deadline}
                                change={setDeadline}
                            />
                        </div>
                        <div className="col-6">
                            <Input label={`Prazo do cliente`} value={order?.shipping_date ? moment(order.shipping_date).format('DD/MM/YYYY') : ''} disabled={true} />
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <SelectShippingCompany
                                selectedShippingCompany={shippingCompanyId}
                                setSelectedShippingCompany={setShippingCompanyId}
                            />
                        </div>
                        <div className="col-6">
                            <Input label={`Valor do frete`} value={shippingCost} change={setShippingCost} mask={`convertToMoney`} />
                        </div>
                    </div>
                </div>
                <div className="section">
                    <InputEditorTiny
                        label={`Observações`}
                        value={comments}
                        change={setComments}
                    />
                </div>
            </Modal>
            }
            <div className="row">
                <div className="col">
                    <Button 
                        type={`secondary`}
                        size={`small`}
                        text={`Cadastrar pedido de compra`}
                        action={() => setShowCreatePurchase(true)}
                    />
                    <br />
                </div>
            </div>
            <Table rows={data.rows} header={data.header} loading={loading} />
        </>
    );
} 

export default PurchaseTab;