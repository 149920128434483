import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import DotStatus from "../../../components/DotStatus";
import Input from "../../../components/Input";
import InputDate from "../../../components/InputDate";
import InputEditorTiny from "../../../components/InputEditorTiny";
import InputSelect from "../../../components/InputSelect";
import Modal from "../../../components/Modal";
import { ModalDelete } from "../../../components/ModalDelete";
import SelectCustomizationCompany from "../../../components/selects/SelectCustomizationCompany";
import SelectProductCompany from "../../../components/selects/SelectProductCompany";
import SelectStatus from "../../../components/selects/SelectStatus";
import Table from "../../../components/Table";
import TableImage from "../../../components/TableImage";
import { convertIntToMoney, convertMoenyToInt } from "../../../helpers/conversions";
import { renderError } from "../../../helpers/errors";
import { createItemOptions, createOptions, createProductOptions } from "../../../helpers/selects";
import api from "../../../services/api";
import SelectPaymentTerm from "../../../components/selects/SelectPaymentTerm";
import SelectPaymentForm from "../../../components/selects/SelectPaymentForm";

const logisticsOptions = [
    {label: 'Asga retira', value: 'asga_retira'},
    {label: 'Fornecedor entrega', value: 'fornecedor_entrega'}
];

const CustomizationTab = ({order}) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({rows: [], header:[]});
    const [showCreateCustomization, setShowCreateCustomization] = useState(false);
    const [showEditCustomization, setShowEditCustomization] = useState(false);

    const [orderCustomizations, setOrderCustomizations] = useState([]);
    const [orderCustomizationIdToDelete, setOrderCustomizationIdToDelete] = useState(null);

    const [orderCustomizationId , setOrderCustomizationId ] = useState(null);
    const [quantity , setQuantity ] = useState('');
    const [quebra , setQuebra ] = useState('');
    const [cost , setCost ] = useState('R$ 0,00');
    const [logistic , setLogistic ] = useState(null);
    const [deadline , setDeadline ] = useState(null);
    const [comments , setComments ] = useState('');
    const [statusId , setStatusId ] = useState(null);
    const [statusComments, setStatusComments] = useState('');
    const [paymentFormId , setPaymentFormId ] = useState('');
    const [paymentTermId , setPaymentTermId ] = useState('');
    const [orderItemId , setOrderItemId ] = useState(null);
    const [orderArtId , setOrderArtId ] = useState(null);
    const [customizationId , setCustomizationId ] = useState(null);
    const [customizationCompanyId, setCustomizationCompanyId] = useState(null);

    const [size, setSize] = useState('');
    const [numOfColors, setNumOfColors] = useState('');
    const [numOfCustomizations, setNumOfCustomizations] = useState('');

    const [customizations, setCustomizations] = useState([]);
    const [arts, setArts] = useState([]);

    const [originalCustomizations, setOriginalCustomizations] = useState(null);
    const [originalProductQuantity, setOriginalProductQuantity] = useState(null);
    const [originalNumOfColors, setOriginalNumOfColors] = useState(null);
    const [originalNumOfCustomizations, setOriginalNumOfCustomizations] = useState(null);
    const [originalCompanyName, setOriginalCompanyName] = useState(null);
    const [originalCost, setOriginalCost] = useState(null);

    useEffect(() => {
        if(order){
            getCustomizations();
            getArts();
            getOrderCustomizations();
        }
    }, [order]);

    useEffect(() => {
        if(showCreateCustomization === false) clean();
    }, [showCreateCustomization]);

    useEffect(() => {
        if(showEditCustomization === false) clean();
    }, [showEditCustomization])

    useEffect(() => {
        generateData();
    }, [orderCustomizations]);

    useEffect(() => {
        if(orderItemId) setDataFromItem();
    }, [orderItemId]);

    useEffect(() => {
        if(customizationId) setDataFromCustomization();
    }, [customizationId, orderItemId]);

    const setDataFromItem = () => {
        let items = order.items.filter(item => item.id === orderItemId);
        if(items.length === 0){
            return;
        } else {
            let item = items[0];
            console.log('custitem', item);
            setOriginalProductQuantity(item.quantity);
            setQuantity(item.quantity);
            if(item.order_item_customizations.length > 0){
                if(item.order_item_customizations.length === 1){
                    setCustomizationId(item.order_item_customizations[0].customization_id);
                } else {

                }

                let toOriginalCustomizations = item.order_item_customizations.map(customization => customization.customization_name);
                setOriginalCustomizations( toOriginalCustomizations.join(',') );
            }
        }
    }

    const setDataFromCustomization = () => {
        let items = order.items.filter(item => item.id === orderItemId);
        if(items.length === 0){
            return;
        } else {
            let item = items[0];
            let customizations = item.order_item_customizations.filter(customization => customization.customization_id === customizationId);
            console.log('a', customizations)
            if(customizations.length > 0){
                let customization = customizations[0];
                setOriginalNumOfColors(customization.quantity_colors);
                setOriginalNumOfCustomizations(customization.quantity);
                setOriginalCompanyName(customization.company_trade_name);
                setOriginalCost( convertIntToMoney(customization.total_cost) );
                // setNumOfColors(customization.quantity_colors);
                // setNumOfCustomizations(customization.quantity);
                // setCustomizationCompanyId(customization.company_id);
            }
        }
    }

    const getCustomizations = () => {
        api.get(`/customization`).then(res => {
            setCustomizations(res.data.resources);
            console.log('customizations', res.data.resources);
        });
    }
    
    const getArts = () => {
        api.get(`/order/art?filters[0][order_id][equal]=${order.id}`).then(res => {
            console.log('arts', res.data.resources)
            setArts(res.data.resources);
        });
    }

    const getOrderCustomizations = () => {
        api.get(`/order/customization?filters[0][order_id][equal]=${order.id}`).then(res => {
            setOrderCustomizations(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Pedido', 'Imagem', 'Nome', 'Fornecedor', 'Tipo', 'Prazo do fornecedor','Custo total','Download'];
        const rows = [];
        
        orderCustomizations.map(orderCustomization => {
            const buttons = [
                <Button 
                    type={`secondary`}
                    size={`small`}
                    svg={`download`}
                    text={`Download`}
                    action={() => window.open(orderCustomization.pdf_url)}
                />
            ];
            rows.push({
                data: [
                    orderCustomization?.code,
                    <TableImage url={orderCustomization?.item_image_url} />,
                    `${orderCustomization?.item_code} - ${orderCustomization?.item_sku} - ${orderCustomization?.item_name}`,
                    orderCustomization?.company_name,
                    orderCustomization?.customization_name,
                    moment(orderCustomization?.deadline).format('DD/MM/YYYY'),
                    convertIntToMoney(orderCustomization?.cost, true),
                    // <>
                    //     <DotStatus color={orderCustomization?.status_color} size={15} />
                    //     {orderCustomization?.status_name}
                    // </>
                ],
                action: () => edit(orderCustomization),
                buttons
            });
        });

        setData({header, rows});
    }

    const edit = orderCustomization => {
        setOrderCustomizationId(orderCustomization.id);
        setQuantity(orderCustomization.quantity);
        setQuebra(orderCustomization.quebra);
        setCost(convertIntToMoney(orderCustomization.cost, true));
        setLogistic(orderCustomization.logistic);
        setDeadline(new Date(`${orderCustomization.deadline} 00:00:00`));
        setCustomizationId(orderCustomization.customization_id);
        setOrderArtId(orderCustomization.order_art_id);
        setComments(orderCustomization.comments);
        setStatusId(orderCustomization.status_id);
        setPaymentFormId(orderCustomization.payment_form_id);
        setPaymentTermId(orderCustomization.payment_term_id);
        setOrderItemId(orderCustomization.order_item_id);
        setCustomizationCompanyId(orderCustomization.customization_company_id);
        setSize(orderCustomization.size);
        setNumOfColors(orderCustomization.num_of_colors);
        setNumOfCustomizations(orderCustomization.num_of_customizations);
        setShowEditCustomization(true);
    }

    const storeCustomization = () => {
        setLoading(true);

        api.post(`order/customization`, {
            quantity,
            quebra,
            cost: convertMoenyToInt(cost),
            logistic,
            deadline: moment(deadline).format('YYYY-MM-DD'),
            comments,
            payment_form_id: paymentFormId,
            payment_term_id: paymentTermId,
            order_item_id: orderItemId,
            customization_id: customizationId,
            order_art_id: orderArtId,
            order_id: order.id,
            customization_company_id: customizationCompanyId,
            size,
            num_of_colors: numOfColors,
            num_of_customizations: numOfCustomizations
        }).then(res => {
            getOrderCustomizations();
            setShowCreateCustomization(false);
            toast.success('Pedido cadastrado com sucesso');
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const updateCustomization = () => {
        setLoading(true);

        api.put(`order/customization/${orderCustomizationId}`, {
            quantity,
            quebra,
            cost: convertMoenyToInt(cost),
            logistic,
            deadline: moment(deadline).format('YYYY-MM-DD'),
            comments,
            status_id: statusId,
            payment_form_id: paymentFormId,
            payment_term_id: paymentTermId,
            order_item_id: orderItemId,
            customization_id: customizationId,
            order_art_id: orderArtId,
            order_id: order.id,
            customization_company_id: customizationCompanyId,
            size,
            num_of_colors: numOfColors,
            num_of_customizations: numOfCustomizations
        }).then(res => {
            getOrderCustomizations();
            setShowEditCustomization(false);
            toast.success('Pedido atualizado com sucesso');
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const clean = () => {
        setOrderCustomizationId(null);
        setQuantity('');
        setQuebra('');
        setLogistic(null);
        setDeadline(null);
        setComments('');
        setStatusId(null);
        setPaymentFormId('');
        setPaymentTermId('');
        setOrderItemId(null);
        setOrderArtId(null);
        setCustomizationId(null);
        setCustomizationCompanyId(null);
        setSize('');
        setNumOfColors('');
        setNumOfCustomizations('');
        setCost('R$ 0,00');
        setOriginalCustomizations(null);
        setOriginalProductQuantity(null);
        setOriginalNumOfColors(null);
        setOriginalNumOfCustomizations(null);
        setOriginalCompanyName(null);
        setOriginalCost(null);
    }

    const deleteCustomization = () => {
        setLoading(true);

        api.delete(`/order/customization/${orderCustomizationIdToDelete}`).then(res => {
            toast.success('Pedido deletado com sucesso');
            setOrderCustomizationIdToDelete(null);
            getOrderCustomizations();
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const confirmDelete = id => {
        setOrderCustomizationIdToDelete(id);
        setShowEditCustomization(false);
    }

    return (
        <>
            <ModalDelete
                action={deleteCustomization}
                close={() => setOrderCustomizationIdToDelete(null)}
                show={orderCustomizationIdToDelete !== null}
                loading={loading}
            />
            {(showCreateCustomization || showEditCustomization) &&
            <Modal 
                show={true}
                size={'big'}
                close={() => showCreateCustomization ?  setShowCreateCustomization(false) : setShowEditCustomization(false)}
                title={`${showEditCustomization ? 'Editar' : 'Cadastrar'} pedido de gravação`}
                footer={
                    <>
                        {showEditCustomization &&
                            <Button
                                type={`secondary`}
                                size={`small`}
                                text={`Excluir`}
                                svg={`delete-small`}
                                action={() => confirmDelete(orderCustomizationId)}
                                loading={loading} 
                            />
                        }
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={showCreateCustomization ? 'Cadastrar' : 'Salvar alterações'}
                            svg={`save-white`}
                            action={() => showCreateCustomization ? storeCustomization() : updateCustomization()}
                            loading={loading} 
                        />
                    </>
                }
            >
                {/* {showEditCustomization &&
                    <div className="section">
                        <div className="row">
                            <div className="col-4">
                                <SelectStatus
                                    selectedStatus={statusId}
                                    setSelectedStatus={setStatusId}
                                    type="order_customization"
                                    />
                            </div>
                        </div>
                    </div>
                } */}
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <InputSelect
                                label={`Produto`}
                                options={createItemOptions(order.items)} 
                                value={orderItemId} 
                                change={setOrderItemId}
                            />
                        </div>
                        <div className="col-6">
                            <InputSelect
                                label={`Gravação`}
                                options={createOptions(customizations, 'name', 'id')} 
                                value={customizationId} 
                                change={setCustomizationId}
                            />
                            {originalCustomizations &&
                                <span className="pos-input">Original: {originalCustomizations}</span>
                            }
                        </div>
                    </div>
   
                    <div className="row">
                        <div className="col-3">
                            <Input label={`Quant. de produtos`} value={quantity} change={setQuantity} mask={`convertToInt`} />
                            {originalProductQuantity &&
                                <span className="pos-input">Original: {originalProductQuantity}</span>
                            }
                        </div>
                        <div className="col-3">
                            <Input label={`Quant. cores`} value={numOfColors} change={setNumOfColors} mask={`convertToInt`} />
                            {originalNumOfColors &&
                                <span className="pos-input">Original: {originalNumOfColors}</span>
                            }
                        </div>
                        <div className="col-3">
                            <Input label={`Quant. de impressões`} value={numOfCustomizations} change={setNumOfCustomizations} mask={`convertToInt`} />
                            {originalNumOfCustomizations &&
                                <span className="pos-input">Original: {originalNumOfCustomizations}</span>
                            }
                        </div>
                        <div className="col-3">
                            <Input label={`Custo total`} value={cost} change={setCost} mask={`convertToMoney`} />
                            {originalCost &&
                                <span className="pos-input">Original: {originalCost}</span>
                            }
                        </div>

                    </div>
                </div>                
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <InputSelect
                                label={`Arte`}
                                options={createOptions(arts, 'description', 'id')} 
                                value={orderArtId} 
                                change={setOrderArtId}
                            />
                        </div>
                        <div className="col-6">
                            <Input label={`Dimensões do logo`} value={size} change={setSize} placeholder={`Ex: 10cm x 15cm`} />
                        </div>
                    </div>
                </div>
                <div className="section">
                    <SelectCustomizationCompany
                        selectedCompany={customizationCompanyId}
                        setSelectedCompany={setCustomizationCompanyId}
                    />
                    {originalCompanyName &&
                        <span className="pos-input">Original: {originalCompanyName}</span>
                    }
                    <div className="row">
                        <div className="col-6">
                            <SelectPaymentForm
                                selectedPaymentForm={paymentFormId}
                                setSelectedPaymentForm={setPaymentFormId}
                            />
                        </div>
                        <div className="col-6">
                            <SelectPaymentTerm
                                selectedPaymentTerm={paymentTermId}
                                setSelectedPaymentTerm={setPaymentTermId}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <InputDate
                                label={`Prazo do fornecedor`} 
                                value={deadline}
                                change={setDeadline}
                            />
                        </div>
                        <div className="col-6">
                            <Input label={`Prazo do cliente`} value={order?.shipping_date ? moment(order.shipping_date).format('DD/MM/YYYY') : ''} disabled={true} />
                        </div>
                    </div>
                </div>
                <div className="section">
                    <InputEditorTiny
                        label={`Observações`}
                        value={comments}
                        change={setComments}
                    />
                </div>
            </Modal>
            }
            <div className="row">
                <div className="col">
                    <Button 
                        type={`secondary`}
                        size={`small`}
                        text={`Cadastrar pedido de gravação`}
                        action={() => setShowCreateCustomization(true)}
                    />
                    <br />
                </div>
            </div>
            <Table rows={data.rows} header={data.header} loading={loading} />
        </>
    );
} 

export default CustomizationTab;