import React, { useEffect, useState } from "react";
import { renderError } from "../../../helpers/errors";
import { createOptions } from "../../../helpers/selects";
import api from "../../../services/api";
import InputSelect from "../../InputSelect";
import { convertIntToMoney } from "../../../helpers/conversions";
import moment from "moment";

export const SelectContaReceber = ({selectedContaReceber, setSelectedContaReceber}) => {
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getContasReceber();
    }, []);

    const getContasReceber = () => {
        setLoading(true);

        api.get(`/conta-receber`).then(res => {
            getOptions(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => {
            setLoading(false);
        });
    }

    const getOptions = contas => {
        let toOptions = [];
        contas.map(conta => {
            toOptions.push({
                value: conta.id,
                label: `${moment(conta.vencimento).format('DD/MM/YYYY')} - ${convertIntToMoney(conta.valor, true)} - ${conta?.cliente?.nome}`
            });
        });
        setOptions(toOptions);
    }

    return (
        <InputSelect
            loading={loading}
            label={`Conta a receber`}
            options={options} 
            value={selectedContaReceber} 
            change={setSelectedContaReceber}
        />
    );
}

export default SelectContaReceber;