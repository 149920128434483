import React, { useState, useEffect } from "react";

import PanelTemplate from "../../templates/Panel";
import Box from "../../components/Box";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import Table from "../../components/Table";
import moment from "moment";
import InputDate from "../../components/InputDate";
import Input from "../../components/Input";
import SelectCompany from "../../components/selects/SelectCompany";
import Button from "../../components/Button";
import InputSelect from "../../components/InputSelect";

export const Logistics = () => {
    const today = new Date();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({header: [], rows: []});
    const [items, setItems] = useState([]);

    const [orderCode, setOrderCode] = useState('');
    const [selectedTipo, setSelectedTipo] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [previsaoFornecedor, setPrevisaoFornecedor] = useState(null);
    const [previsaoCliente, setPrevisaoCliente] = useState(null);

    useEffect(() => {
        getItems();
    }, []);

    useEffect(() => {
        generateData();
    }, [items]);

    const getItems = () => {
        setLoading(true);

        api.get(`/logistics`, { 
            params: {
                order_code: orderCode,
                tipo: selectedTipo,
                company_id: selectedCompany,
                previsao_forncedor: previsaoFornecedor ? moment(previsaoFornecedor).format('YYYY-MM-DD') : null,
                previsao_cliente: previsaoCliente ? moment(previsaoCliente).format('YYYY-MM-DD') : null
            }
        }).then(res => {
            setItems(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        let header = ['Pedido', 'Tipo', 'Previsão cliente', 'Previsão fornecedor', 'Recebimento', 'Fornecedor', 'Produto', 'Quant. total', 'Quant. recebida', 'Consultor'];
        const rows = [];
        
        items.map(item => {
            let data = [
                item.order_code,
                item.type,
                moment(item.order_shipping_date).format('DD/MM/YYYY'),
                moment(item.estimated_delivery).format('DD/MM/YYYY'),
                item.received ? moment(item.received_date).format('DD/MM/YYYY') : 'Pendente',
                item.company_trade_name,
                item.item_name,
                item.quantity_total,
                item.quantity_received,
                item.admin_name
            ];
            rows.push({ data, red: item.received ? false : true });
        });

        setData({header, rows});
    }

    return (
        <>
            <PanelTemplate id="logistics">
                <Box>
                    <div className="row" style={{ marginBottom: -15 }}>
                        <div className="col">
                            <Input label={'Cód. do pedido'} value={orderCode} change={setOrderCode} />
                        </div>
                        <div className="col">
                            <InputSelect 
                                options={[
                                    {label: 'Gravação', value: 'customization'},
                                    {label: 'Compra', value: 'purshase'}
                                ]} 
                                label={'Tipo do pedido'} 
                                value={selectedTipo}
                                change={setSelectedTipo}
                            />
                        </div>
                        <div className="col">
                            <SelectCompany
                                label={'Fornecedor'}
                                selectedCompany={selectedCompany}
                                setSelectedCompany={setSelectedCompany}
                            />
                        </div>
                        <div className="col">
                            <InputDate 
                                label={'Previsão cliente'} 
                                value={previsaoCliente}
                                change={setPrevisaoCliente}
                            />
                        </div>
                        <div className="col">
                            <InputDate 
                                label={'Previsão fornecedor'} 
                                value={previsaoFornecedor}
                                change={setPrevisaoFornecedor}
                            />
                        </div>
                        <div className="col">
                            <Button 
                                text={'Limpar'}
                                type={'secondary'}
                                full={true}
                                style={{ height: 38, marginTop: 5}}
                                action={() => window.location.reload()}
                            />
                        </div>
                        <div className="col">
                            <Button 
                                text={'Filtrar'}
                                type={'primary'}
                                full={true}
                                style={{ height: 38, marginTop: 5}}
                                action={getItems}
                            />
                        </div>
                    </div>
                </Box>
                <Box className={`no-padding`}>
                    <Table rows={data.rows} header={data.header} loading={loading} crop={true} />
                </Box>
            </PanelTemplate>
        </>
    );
}

export default Logistics;