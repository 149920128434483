import React, { useEffect, useState } from "react";
import { renderError } from "../../../helpers/errors";
import { createOptions } from "../../../helpers/selects";
import api from "../../../services/api";
import InputSelect from "../../InputSelect";

export const SelectPaymentTerm = ({selectedPaymentTerm, setSelectedPaymentTerm}) => {
    const [loading, setLoading] = useState(true);

    const [paymentTerms, setPaymentTerms] = useState([]);

    useEffect(() => {
        getPaymentTerms();
    }, []);

    const getPaymentTerms = () => {
        setLoading(true);

        api.get(`/paymentterm`).then(res => {
            setPaymentTerms(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => {
            setLoading(false);
        });
    }

    return (
        <InputSelect
            loading={loading}
            label={`Prazo de pagamento`}
            options={createOptions(paymentTerms, 'name', 'id')} 
            value={selectedPaymentTerm} 
            change={setSelectedPaymentTerm}
        />
    );
}

export default SelectPaymentTerm;