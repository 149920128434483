import React, { useEffect, useState } from "react";
import { renderError } from "../../../helpers/errors";
import { createOptions } from "../../../helpers/selects";
import api from "../../../services/api";
import InputSelect from "../../InputSelect";

export const SelectProductCompany = ({selectedProductCompany, setSelectedProductCompany}) => {
    const [loading, setLoading] = useState(true);

    const [productCompanies, setProductCompanies] = useState([]);

    useEffect(() => {
        getProductCompanies();
    }, []);

    const getProductCompanies = () => {
        setLoading(true);

        api.get(`/company?filters[0][type][contain]=product`).then(res => {
            setProductCompanies(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => {
            setLoading(false);
        });
    }

    return (
        <InputSelect
            loading={loading}
            label={`Fornecedor`}
            options={createOptions(productCompanies, 'trade_name', 'id')} 
            value={selectedProductCompany} 
            change={setSelectedProductCompany}
        />
    );
}

export default SelectProductCompany;