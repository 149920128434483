import React, { useEffect, useState } from "react";

import InputSelect from "../../../components/InputSelect";
import Input from "../../../components/Input";
import InputRadio from "../../../components/InputRadio";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import { renderError } from "../../../helpers/errors";
import api from "../../../services/api";
import { createOptions, createProductOptions } from '../../../helpers/selects';
import { convertIntToMoney, convertIntToPercentage, convertMoenyToInt } from "../../../helpers/conversions";
import CustomizationSection from "./CustomizationSection";
import ParamSection from "./ParamSection";
import { Calculation } from "./calculationPrice";
import { calculateCustomizationTotal } from "./customizationCalculate";
import { ModalDelete } from "../../../components/ModalDelete";
import { toast } from "react-toastify";
import SelectBillingCompany from "../../../components/selects/SelectBillingCompany";
import SelectProduct from "../../../components/selects/SelectProduct";
import states from "../../../seeds/states";
import destinacoes from "../../../seeds/destinacoes";
import { codigoOrigem } from "../../../seeds/fiscal";
import { hasPermission } from "../../../services/auth";

const calculationTypeOptions = [
    { label: 'Cálculo automático', value: 'automatic' },
    { label: 'Cálculo manual', value: 'manual' }
];

const contribuinteOptions = [
    { label: 'Contribuinte', value: true },
    { label: 'Não Contribuinte', value: false },
];

const ModalProduct = ({close, quote, itemToEdit, selectedBillingCompany, shippingState, getQuote, updateQuote}) => {
    const [loading, setLoading] = useState(true);
    const [params, setParams] = useState([]);
    const [customizations, setCustomizations] = useState([]);
    const [products, setProducts] = useState([]);
    const [subproducts, setSubproducts] = useState([]);

    const [gettingPrice, setGettingPrice] = useState(false);

    const [product, setProduct] = useState(null);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedSubproduct, setSelectedSubproduct] = useState(null);

    const [companiesProduct, setCompaniesProduct] = useState([]);
    const [selectedCompanyProduct, setSelectedCompanyProduct] = useState(null);

    const [productCalculationType, setProductCalculationType] = useState('automatic');
    const [productNcm, setProductNcm] = useState(null);
    const [productOrigem, setProductOrigem] = useState(null);
    const [productDestinoUf, setProductDestinoUf] = useState('');
    const [productDestinacao, setProductDestinacao] = useState('');
    const [productQuantity, setProductQuantity] = useState(1);
    const [productQuantityColors, setProductQuantityColors] = useState(0);
    const [productUnitCost, setProductUnitCost] = useState('0,00');
    const [productUnitCostError, setProductUnitCostError] = useState('');
    const [productTotalCost, setProductTotalCost] = useState('0,00');
    const [productSupplierCost, setProductSupplierCost] = useState(0);

    const [productICMSCompraRegime, setProductICMSCompraRegime] = useState(null);
    const [productICMSCompraState, setProductICMSCompraState] = useState(null);

    const [productCustomizations, setProductCustomizations] = useState([]);
    const [productParams, setProductParams] = useState([]);

    const [formulasPrice, setFormulasPrice] = useState([]);
    const [companyFormulasPrice, setCompanyFormulasPrice] = useState([]);
    const [selectedFormulaPrice, setSelectedFormulaPrice] = useState(null);

    const [formulaPrice, setFormulaPrice] = useState('');
    const [formulaCommission, setFormulaCommission] = useState('');

    const [salePrice, setSalePrice] = useState('R$ 0,00');
    const [totalSalePrice, setTotalSalePrice] = useState('R$ 0,00');
    const [commission, setCommission] = useState('R$ 0,00');

    const [gettingBillingCompanies, setGettingBillingCompanies] = useState(true);
    const [billingCompanies, setBillingCompanies] = useState([]);

    const [showDelete, setShowDelete] = useState(false);

    const [subproductPrices, setSubproductPrices] = useState([]);

    const [difal, setDifal] = useState(0);
    const [stSaida, setStSaida] = useState(0);
    const [contribuinte, setContribuinte] = useState(null);

    useEffect(() => {
        getProduct();
        getParams();
        getCustomizations();
        getCompaniesProduct();
        getBillingCompanies();
    }, []);

    useEffect(() => {
        getSubproducts();
        getSelectedProduct();
    }, [selectedProduct, products]);

    useEffect(() => {
        getPrices();
    }, [selectedSubproduct]);

    useEffect(() => {
        setFirstSubproductAsSelected();
    }, [subproducts]);

    useEffect(() => {
        getProductUnitCost();
        getCompaniesProduct();
    }, [subproductPrices]);
    
    useEffect(() => {
        getCompaniesProduct();
    }, [products, selectedSubproduct]);

    const getSelectedProduct = () => {
        let product = products.filter(product => product.id === selectedProduct);
        if(product.length > 0){
            setProduct(product[0]);
            setProductNcm(product[0].ncm);
        } else {
            setProduct(null);
        }
    }

    const setFirstSubproductAsSelected = () => {
        if(subproducts.length > 0 && subproducts[0].color === null){
            setSelectedSubproduct(subproducts[0].id);
        }
    }

    const getBillingCompanies = () => {
        setGettingBillingCompanies(true);

        api.get(`/company?filters[0][type][contain]=billing`).then(res => {
            setBillingCompanies(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setGettingBillingCompanies(false));
    }
    
    const handleProductChange = productId => {
        setSelectedSubproduct(null);
        setCompaniesProduct([]);
        setSelectedCompanyProduct(null);
        setSelectedProduct(productId);
    }

    const getSubproducts = () => {
        let toSubproducts = [];
        let product = products.filter(product => product.id === selectedProduct)[0];
        if(!product) return;
        product.subproducts.map(subproduct => {
            toSubproducts.push({
                id: subproduct.id,
                color: subproduct.color,
                desc:  subproduct.color ? `${subproduct.color.name} - ${subproduct.sku}` : `Cor única`
            });
        });
        setSubproducts(toSubproducts);
    }

    useEffect(() => {
        calculateProductTotalCost();
    }, [productQuantity, productUnitCost]);

    useEffect(() => {
        getProductUnitCost();
    }, [selectedCompanyProduct, productQuantity, productCalculationType, selectedSubproduct, selectedProduct, products]);

    useEffect(() => {
        calculatePrice();
    }, [formulaPrice, billingCompanies, selectedBillingCompany, productQuantity, productUnitCost, productCustomizations, productParams, productDestinoUf, productDestinacao, productOrigem]);

    useEffect(() => {
        getTotalSalePrice();
    }, [productQuantity, salePrice]);

    useEffect(() => {
        getProductParams();
    }, [params]);

    useEffect(() => {
        getCompanyFormulasPrice();
    }, [selectedBillingCompany, formulasPrice]);

    const getCompanyFormulasPrice = () => {
        let toCompanyFormulasPrice = [];
        let companyFormulasPriceIds = [];
        formulasPrice.map(fPrice => {
            if(fPrice.company_ids.includes(selectedBillingCompany)){
                toCompanyFormulasPrice.push(fPrice);
                companyFormulasPriceIds.push(fPrice.id);
            }
        });
        if(toCompanyFormulasPrice.length > 0){
            if(!companyFormulasPriceIds.includes(selectedFormulaPrice)){
                setSelectedFormulaPrice(toCompanyFormulasPrice[0].id);
            }
        }
        setCompanyFormulasPrice(toCompanyFormulasPrice);
    }

    useEffect(() => {
        getCurrentFormulaPrice()
    }, [selectedFormulaPrice, companyFormulasPrice]);

    const getCurrentFormulaPrice = () => {
        if(companyFormulasPrice.length > 0){
            let formula = companyFormulasPrice.filter(formula => formula.id === selectedFormulaPrice);
            if(formula.length > 0){
                setFormulaPrice(formula[0].value);
                return;
            }
        }
        setFormulaPrice('0');
    }

    const getProduct = () => {
        if(!itemToEdit) {
            setProductDestinoUf(shippingState);
            setProductDestinacao('consumo');
            return;
        };

        let toQuantity = itemToEdit.quantity;

        setSelectedProduct(itemToEdit.subproduct.product_id);
        setSelectedSubproduct(itemToEdit.subproduct_id);
        setSelectedCompanyProduct(itemToEdit.company_id);
        setProductCalculationType(itemToEdit.calculation_type);
        setProductOrigem(itemToEdit.origem);
        setProductDestinoUf(itemToEdit.destino_uf ?? shippingState);
        setProductDestinacao(itemToEdit.destinacao);
        setProductQuantity(toQuantity);
        setProductNcm(itemToEdit.ncm);
        setProductQuantityColors(itemToEdit.quantity_colors);
        setProductUnitCost( `R$ ${convertIntToMoney(itemToEdit.cost)}` );
        setProductICMSCompraRegime(itemToEdit.icms_compra_regime ?? null);
        setProductICMSCompraState(itemToEdit.icms_compra_state ?? null);
        setSelectedFormulaPrice(itemToEdit.formula_id);

        getProductCustomizations(itemToEdit.budget_item_customizations, toQuantity);
    }

    const getTotalSalePrice = () => {
        let quantity = productQuantity;
        let price = convertMoenyToInt(salePrice);
        let total = price * quantity;
        total = `R$ ${convertIntToMoney(total)}`;
        setTotalSalePrice(total);
    }

    const calculatePrice = () => {
        if(billingCompanies.length === 0){
            return;
        }
        const taxes = getTaxes();
        const calculation = new Calculation(
            formulaPrice, 
            formulaCommission, 
            productQuantity, 
            productUnitCost, 
            productCustomizations, 
            productParams,
            params,
            taxes.tax_percentage,
            taxes.tax_pis,
            taxes.tax_cofins,
            taxes.tax_csll,
            taxes.tax_irpj,
            taxes.tax_adicionais_irpj,
            productDestinacao,
            productDestinoUf,
            productOrigem
        );

        console.log({
            formulaPrice, 
            formulaCommission, 
            productQuantity, 
            productUnitCost, 
            productCustomizations, 
            productParams,
            params,
            taxes,
            productDestinacao,
            productDestinoUf,
            productOrigem
        });

        const toSalePrice = calculation.getSalePrice();
        const toCommission = calculation.getCommission();

        const toDifal = calculation.getDifal();
        const toStSaida = calculation.getStSaida();
        
        setDifal(toDifal);
        setStSaida(toStSaida);

        setSalePrice( `R$ ${convertIntToMoney(toSalePrice)}`);
        setCommission( `R$ ${convertIntToMoney(toCommission)}`);
    }

    const getTaxes = () => {
        const company = billingCompanies.filter(billingCompany => billingCompany.id === selectedBillingCompany)[0];
        return {
            tax_percentage: company.tax_percentage,
            tax_pis: company.tax_pis,
            tax_cofins: company.tax_cofins,
            tax_csll: company.tax_csll,
            tax_irpj: company.tax_irpj,
            tax_adicionais_irpj: company.tax_adicionais_irpj
        };
    }

    const addProductCustomization = () => {
        const toProductCustomization = {
            calculationType: 'automatic',
            selectedCustomization: null,
            selectedCompanyCustomization: null,
            quantityColors: productQuantityColors,
            quantity: 1,
            customizationUnitCost: `R$ 0,00`,
            customizationTotalCost: `R$ 0,00`,
            table: product.origin === 'integracao_spot' && product.spot_components.length > 0 ? 'spot' : 'registered'
        };

        setProductCustomizations(prev => [...prev, toProductCustomization]);
    }

    const getCompaniesProduct = async () => {
        let toCompaniesProduct = [];

        subproductPrices.map(price => {
            toCompaniesProduct.push(price.company);
        });
        setCompaniesProduct(toCompaniesProduct);
    }

    const getPrices = () => {
        if(!selectedSubproduct){
            setSubproductPrices([]);
            // setStock(0);
            return;
        };

        setGettingPrice(true);
        
        api.get(`/subproduct/${selectedSubproduct}/search`).then(res => {
            let prices = [];
            res.data.resource.prices.map(price => {
                prices.push(price);
            });
            // setStock(res.data.resource.stock);
            setSubproductPrices(prices);
        }).catch(error => {
            console.log('error', error);
            renderError(error);
            setSubproductPrices([]);
        }).then(() => setGettingPrice(false));
    }
    
    const getParams = () => {
        api.get('/param').then(res => {
            let toParams = [];
            let toFormulasPrice = [];
            res.data.resources.map(resource => {
                if(resource.type === 'price_calculation'){
                    toFormulasPrice.push({
                        id: resource.id,
                        name: resource.name,
                        value: resource.value,
                        company_ids: resource?.options?.company_ids ?? []
                    });
                    return;
                }
                if(resource.type === 'commission_calculation'){
                    setFormulaCommission(resource.value);
                    return;
                }
                toParams.push(resource);
            })
            
            setFormulasPrice(toFormulasPrice);
            setParams(toParams);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const getProductCustomizations = (currentProductCustomizations, productQuantity) => {
        let toProductCustomizations = [];
        currentProductCustomizations.map(current => {
            let total = current.table === 'registered' ? calculateCustomizationTotal(
                current.quantity,
                current.cost,
                current.customization.calculation_per,
                productQuantity
            ) : 0;
            toProductCustomizations.push({
                calculationType: current.calculation_type,
                selectedCustomization: current.customization_id,
                selectedSupplierCustomization: current.company_id ? current.company_id : current.customer_id,
                selectedSupplierTypeCustomization: current.company_id ? "PJ" : "PF",
                quantityColors: current.quantity_colors,
                quantity: current.quantity,
                customizationUnitCost: `R$ ${convertIntToMoney( current.cost )}`,
                customizationTotalCost: `R$ ${convertIntToMoney( total )}`,
                spot_component: current?.spot_component ?? null,
                spot_location: current?.spot_location ?? null,
                spot_area: current?.spot_area ?? null,
                spot_customization: current?.spot_customization ?? null,
                table: current.table
            });
        });

        setProductCustomizations(toProductCustomizations);
    }

    const getProductParams = () => {
        let toProductParams = [];
        let currentParams = itemToEdit?.params ? JSON.parse( itemToEdit.params ) : [];
        params.map(param => {
            let currentParam = currentParams.filter(currentParam => currentParam.id === param.id);
            if(currentParam.length === 0){
                toProductParams.push({
                    type: param.type,
                    name: param.name,
                    options: param.options,
                    position: param.position,
                    param_id: param.param_id,
                    id: param.id,
                    formula_total_real: param.formula_total_real,
                    formula_total_quote: param.formula_total_quote,
                    value: null,
                    values: []
                });
            } else {
                toProductParams.push(currentParam[0]);
            }
        });

        setProductParams(toProductParams);
    }

    const getCustomizations = () => {
        api.get('/customization').then(res => {
            setCustomizations(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }
    
    const calculateProductTotalCost = () => {
        let quantity = productQuantity;
        let cost = productUnitCost;
        cost = convertMoenyToInt(cost);
        let total = cost * quantity;
        total = `R$ ${convertIntToMoney(total)}`;
        setProductTotalCost(total);
    }

    const getProductUnitCost = async () => {
        setProductUnitCostError('');

        let error = 'Custo não encontrado no fornecedor. Mude a origem do custo para manual.';

        if(!selectedSubproduct){
            error = 'Informe o produto e a cor para obter o custo.';
        }

        let cost = 0;

        subproductPrices.map(price => {
            if(price.init_quantity <= productQuantity && price.final_quantity >= productQuantity){
                error = '';
                cost = price.price;
            }
        });

        setProductSupplierCost(cost);

        if(productCalculationType === 'manual'){
            setProductUnitCost( `R$ ${convertIntToMoney(itemToEdit.cost)}` );
        } else {
            setProductUnitCost( `R$ ${convertIntToMoney(cost)}` );
            setProductUnitCostError(error);
        }
    }

    const updateProduct = (create = false) => {
        const validation = validate();
        if(!validation) return;

        setLoading(true);

        let itemToStore = {
            budget_id: quote.id,
            subproduct_id: selectedSubproduct,
            company_id: selectedCompanyProduct,
            origem: productOrigem,
            destino_uf: productDestinoUf,
            destinacao: productDestinacao,
            quantity: productQuantity,
            ncm: productNcm,
            icms_compra_regime: productICMSCompraRegime,
            icms_compra_state: productICMSCompraState,
            calculation_type: productCalculationType,
            quantity_colors: 0,
            cost: convertMoenyToInt( productUnitCost ),
            price: convertMoenyToInt( salePrice ),
            params: JSON.stringify( productParams ),
            customizations: getCustomizationsToStore(),
            formula_id: selectedFormulaPrice
        };
        
        api({
            method: create ? 'post' : 'put',
            url: create ? `/budget/item` : `/budget/item/${itemToEdit.id}`,
            data: itemToStore
        }).then(res => {
            updateQuote();
            if(create){
                toast.success('Produto adicionado com sucesso'); 
            } else {
                close();
            }
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const validate = () => {
        let result = true;

        if(productCustomizations.length === 0){
            toast.error('Nenhuma gravação informada');
            result = false;
        } else {
            productCustomizations.map(productCustomization => {
                // validar se não é uma customização vazia
            });
        }

        productParams.map(productParam => {
            if(productParam.value === null){
                let param = params.filter(param => param.id === productParam.id)[0];
                if(param.required){
                    toast.error(`O campo ${param.name} precisa ser informado`);
                    result = false;
                }
            }
        });

        if(!productOrigem){
            toast.error('Informar a origem do produto');
            result = false;
        }
        
        if(!productNcm){
            toast.error('Configure o NCM do produto');
            result = false;
        }

        return result;
    }

    const refreshProduct = e => {
        e.preventDefault();
        setLoading(true);

        api.get(`/product/${selectedProduct}`).then(res => {
            setProductNcm(res.data.resource.ncm);
            toast.success('NCM atualizado com base no cadastro do produto');
        }).catch(error => renderError(error))
        .then(() => setLoading(false));
    }

    const getCustomizationsToStore = () => {
        let customizationsToStore = [];
        productCustomizations.map(productCustomization => {
            customizationsToStore.push({
                customization_id: productCustomization.table === 'registered' ? productCustomization.selectedCustomization : null,
                company_id: productCustomization.selectedSupplierTypeCustomization === "PJ" ? productCustomization.selectedSupplierCustomization : null,
                customer_id: productCustomization.selectedSupplierTypeCustomization === "PF" ? productCustomization.selectedSupplierCustomization : null,
                quantity_colors: productCustomization.quantityColors,
                quantity: productCustomization.quantity,
                cost: convertMoenyToInt( productCustomization.customizationUnitCost ),
                total_cost: convertMoenyToInt( productCustomization.customizationTotalCost ),
                calculation_type: productCustomization.calculationType,
                table: productCustomization.table,
                spot_component: productCustomization?.spot_component ?? null,
                spot_location: productCustomization?.spot_location ?? null,
                spot_area: productCustomization?.spot_area ?? null,
                spot_customization: productCustomization?.spot_customization ?? null
            });
        });
        return customizationsToStore;
    }

    const deleteProduct = () => {
        api.delete(`/budget/item/${itemToEdit.id}`).then(res => {
            getQuote();
            close();
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    return (
        <>
            <Modal
                title={`Configuração do produto`}
                size={`big`}
                show={true}
                close={close}
                footer={
                    <>
                        <Input label={`Preço unit.`} value={salePrice} disabled={true} />
                        <Input label={`Total`} value={totalSalePrice} disabled={true} />
                        {/* <Input label={`ST Saída`} value={convertIntToMoney(stSaida, true)} disabled={true} /> */}
                        <Input label={`Comissão`} value={commission} disabled={true} />
                        <Button type={`secondary`} svg={`plus-blue`} text={`Adicionar`} loading={loading} action={() => updateProduct(true)} />
                        {itemToEdit &&
                            <Button type={`primary`}  svg={`save-white`} loading={loading} text={`Atualizar`} action={() => updateProduct()} />
                        }
                    </>
                }
            >
                {gettingBillingCompanies && 
                    <p>Carregando...</p>
                }
                {!gettingBillingCompanies && billingCompanies.length === 0 &&
                    <p>Nenhuma empresa de faturamento encontrada</p>
                }
                {!gettingBillingCompanies && billingCompanies.length > 0 &&
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <InputSelect
                                label={`UF de destino`}
                                options={states}
                                value={productDestinoUf}
                                change={setProductDestinoUf}
                            />
                        </div>
                        <div className="col-6">
                            <InputSelect
                                label={`Destinada a`}
                                options={destinacoes}
                                value={productDestinacao}
                                change={setProductDestinacao}
                            />
                        </div>
                        {/* <div className="col-4">
                            <InputSelect
                                label={`Contribuinte`}
                                options={contribuinteOptions}
                                value={contribuinte}
                                change={setContribuinte}
                            />
                        </div> */}
                    </div>
                </div>
                }
                {!gettingBillingCompanies && productDestinoUf && productDestinacao && billingCompanies.length > 0 &&
                <>
                    {/* <div className="section">
                        <div className="row">
                            <div className="col-6">
                                <SelectBillingCompany
                                    selectedBillingCompany={selectedBillingCompany}
                                    setSelectedBillingCompany={setSelectedBillingCompany}
                                />
                            </div>
                            <div className="col-6">
                                <InputSelect 
                                    isDisabled={companyFormulasPrice.length < 2}
                                    label={`Fórmula do cálculo`}
                                    options={createOptions(companyFormulasPrice, 'name', 'id')} 
                                    value={selectedFormulaPrice} 
                                    change={setSelectedFormulaPrice}
                                />
                            </div>
                        </div>
                    </div> */}
                    <div className="modal-section-title">
                        <div className="left">
                            <h2>Produto</h2>
                        </div>
                        <div className="right">
                            <InputRadio
                                className={`no-margin`}
                                options={calculationTypeOptions}
                                value={productCalculationType}
                                change={setProductCalculationType}
                            />
                        </div>
                    </div>
                    <div className="section">
                        <div className="row">
                            <div className="col-4">
                                <SelectProduct
                                    changeProducts={setProducts}
                                    selectedProduct={selectedProduct}
                                    setSelectedProduct={handleProductChange}
                                />
                                <div className="row">
                                    <div className="col">
                                        <a href={`/product/create`} className="link-product-details" target="_blank">Cadastrar novo</a>
                                    </div>
                                    <div className="col">
                                        {selectedProduct &&
                                            <a href={`/product/edit/${selectedProduct}`} className="link-product-details" target="_blank">Editar detalhes</a>
                                        }
                                    </div>
                                </div>
                                
                                
                            </div>
                            <div className="col-4">
                                <InputSelect 
                                    label={`Cor`}
                                    options={createOptions(subproducts, 'desc', 'id')} 
                                    value={selectedSubproduct} 
                                    change={setSelectedSubproduct}
                                    isDisabled={subproducts[0] && subproducts[0].color === null}
                                />
                            </div>
                            <div className="col-4">
                                <Input
                                    label={`Fornecedor`}
                                    disabled={true}
                                    value={product?.company_trade_name ?? 'Sem informação'}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <InputSelect 
                                    label={`Origem`}
                                    options={codigoOrigem}
                                    value={productOrigem}
                                    change={setProductOrigem}
                                    isDisabled={false}
                                />
                            </div>
                            <div className="col-4">
                                <Input 
                                    label={`NCM`}
                                    value={productNcm}
                                    disabled={true}
                                />
                                {selectedProduct &&
                                    <a href="#" style={{ pointerEvents: loading ? 'none' : 'auto'}}  onClick={refreshProduct} className="link-product-details" target="_blank">Atualizar do cadastro</a>
                                }
                            </div>
                            <div className="col-4">
                                <Input label={`DIFAL`} value={convertIntToPercentage(difal * 1000000)} disabled={true} />
                            </div>
                            <div className="col">
                                <Input 
                                    label={`Quantidade`}
                                    value={productQuantity}
                                    change={setProductQuantity}
                                    mask={`convertToInt`}
                                />
                            </div>
                            <div className="col">
                                <Input
                                    label={`Custo unit.`}
                                    value={gettingPrice ? 'Buscando...' : productUnitCost}
                                    change={setProductUnitCost}
                                    mask={`convertToMoney`}
                                    disabled={productCalculationType === 'automatic'}
                                    error={productUnitCostError}
                                />
                            </div>
                            <div className="col">
                                <Input
                                    label={`Custo total`}
                                    value={gettingPrice ? 'Buscando...' : productTotalCost}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    
                    {customizations.length > 0 && productCustomizations.map((productCustomization, index) => (

                        <CustomizationSection
                            key={index}
                            index={index}
                            product={product}
                            calculationTypeOptions={calculationTypeOptions}
                            customizations={customizations}
                            productCustomization={productCustomization}
                            productCustomizations={productCustomizations}
                            setProductCustomizations={setProductCustomizations}
                            productQuantity={productQuantity}
                            productSupplierCost={productSupplierCost}
                        />
                    ))}

                    {customizations.length > 0 && product &&
                        <Button 
                            type={`link`}
                            size={`small`}
                            full={true}
                            text={`Adicionar gravação`}
                            action={addProductCustomization}
                        />
                    }

                    {params.map((param, index) => (
                        <div key={index}>
                            {formulaPrice.includes(param.name) && (!param.restricted || (param.restricted && hasPermission('view_restricted_params'))) &&
                                <ParamSection
                                    productDestinacao={productDestinacao}
                                    productDestinoUf={productDestinoUf}
                                    productOrigem={productOrigem}
                                    productNcm={productNcm}
                                    param={param}
                                    productParams={productParams}
                                    setProductParams={setProductParams}
                                    totalSalePrice={totalSalePrice}
                                    productTotalCost={productTotalCost}
                                    productICMSCompraRegime={productICMSCompraRegime}
                                    setProductICMSCompraRegime={setProductICMSCompraRegime}
                                    productICMSCompraState={productICMSCompraState}
                                    setProductICMSCompraState={setProductICMSCompraState}
                                />
                            }
                        </div>
                    ))}
                </>
                }
            </Modal>
            <ModalDelete
                action={deleteProduct}
                close={() => setShowDelete(false)}
                show={showDelete}
                loading={loading}
            />
        </>
    );
}

export default ModalProduct;