import React, { useEffect, useState } from "react";
import Input from "../Input";
import InputCheckboxes from "../InputCheckboxes";
import InputEditor from "../InputEditor";
import InputRadio from "../InputRadio";
import InputSelect from "../InputSelect";

export const BoxTabs = ({className, titles, contents, tab, setTab}) => {
    const [currentTab, setCurrentTab] = useState(tab ?? 0);

    useEffect(() => {
        if(tab !== currentTab){
            setCurrentTab(tab ?? 0);
        }
    }, [tab]);

    useEffect(() => {
        if(tab !== currentTab && setTab){
            setTab(currentTab);
        }
    }, [currentTab]);

    return (
        <div className={`box-tabs ${className ?? ''}`}>
            <div className="tabs">
                {titles.map((title, index) => (
                    <button
                        key={index}
                        className={index === currentTab ? 'active' : ''}
                        onClick={() => setCurrentTab(index)}
                    >
                        {title}
                    </button>
                ))}
            </div>
            <div className="body">
                {contents[currentTab]}
            </div>
        </div>
    );
}

export default BoxTabs;