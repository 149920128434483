import React, { useEffect, useState } from "react";
import Select from 'react-select';
import PanelTemplate from "../../templates/Panel";
import PageHeader from "../../components/PageHeader";
import Button from "../../components/Button";
import FilterSimple from "../../components/FilterSimple";
import Box from "../../components/Box";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import InputDate from "../../components/InputDate";
import InputTextarea from "../../components/InputTextarea";
import DatePicker from "react-date-picker";
import SelectCaixa from "../../components/selects/SelectCaixa";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import { convertIntToMoney } from "../../helpers/conversions";
import moment from "moment";
import SelectContaPagar from "../../components/selects/SelectContaPagar";
import SelectContaReceber from "../../components/selects/SelectContaReceber";
import { toast } from "react-toastify";

const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '35px',
      height: '35px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: '3px',
      borderBottomRightRadius: '3px',
      boxShadow: state.isFocused ? null : null,
      fontSize: '14px',
      width: 250
    }),
}

const headerDefault = ['Data', 'Categoria', 'Descrição', 'Cliente ou fornecedor', 'Conta', 'Valor', 'Status'];

export const FluxoCaixa = () => {
    const today = new Date();
    const sDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const fDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const [filterStartDate, setFilterStartDate] = useState(sDate);
    const [filterFinalDate, setFilterFinalDate] = useState(fDate);

    const [loading, setLoading] = useState(false);
    const [contas, setContas] = useState([]);
    const [data, setData] = useState({header: headerDefault, rows: []});

    const [saldoCaixas, setSaldoCaixas] = useState(0);
    const [saldoInicialPeriodo, setSaldoInicialPeriodo] = useState(0);
    const [saldoFinalPeriodo, setSaldoFinalPeriodo] = useState(0);
    const [entradas, setEntradas] = useState(0);
    const [saidas, setSaidas] = useState(0);

    const [selectedConta, setSelectedConta] = useState(null);

    const [showImportLancamentos, setShowImportLancamentos] = useState(false);

    const [transacaoToEdit, setTransacaoToEdit] = useState(null);
    const [showEditTransacao, setShowEditTransacao] = useState(false);

    const [selectedContaPagar, setSelectedContaPagar] = useState(null);
    const [selectedContaReceber, setSelectedContaReceber] = useState(null);

    useEffect(() => {
        getContas();
    }, [filterStartDate, filterFinalDate]);

    useEffect(() => {
        generateData();
    }, [contas]);

    const getContas = () => {
        setLoading(true);

        api.get(`/caixa/fluxo`, {
            params: {
                data_inicial: moment(filterStartDate).format('YYYY-MM-DD'),
                data_final: moment(filterFinalDate).format('YYYY-MM-DD')
            }
        }).then(res => {
            setContas(res.data.contas);
            setSaldoCaixas(res.data.saldo_caixas);
            setSaldoInicialPeriodo(res.data.saldo_inicial_periodo);
            setSaldoFinalPeriodo(res.data.saldo_final_periodo);
            setEntradas(res.data.entradas);
            setSaidas(res.data.saidas);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Data', 'Empresa', 'Valor', 'Status'];
        const rows = [];
        
        contas.map(conta => {
            rows.push({
                data: [
                    moment(conta.vencimento).format('DD/MM/YYYY'),
                    conta?.empresa,
                    convertIntToMoney(conta.valor, true),
                    <span className={`conta-status ${conta.status}`}>{conta.status}</span>,
                    // <span className={`conta-pagamento-status ${conta.pagamento_status}`}>{conta.pagamento_status_texto}</span>
                ]
            });
        });

        setData({header, rows});
    }


    return (
        <>
            <PanelTemplate>
                <PageHeader
                    left={
                        <>
                            {/* <Select
                                placeholder="Escolha uma empresa..."
                                options={empresas}
                                onChange={e => setSelectedEmpresa(e.value)}
                                value={selectedEmpresa}
                                styles={customStyles}
                            /> */}

                            {/* <SelectCaixa 
                                removeLabel={true}
                                selectedCaixa={selectedConta}
                                setSelectedCaixa={setSelectedConta}
                            /> */}

                            <span>Início:</span>
                            <DatePicker 
                                className={`date-filter`}
                                format="dd/MM/y"
                                onChange={setFilterStartDate}
                                value={filterStartDate}
                            />
                            <span>Fim:</span>
                            <DatePicker 
                                className={`date-filter`}
                                format="dd/MM/y"
                                onChange={setFilterFinalDate}
                                value={filterFinalDate}
                            />
                        </>
                    }

                    right={
                        <>
                            <Button type={`primary`} size={`small`} text={`Importar extrato`} action={() => setShowImportLancamentos(true)} />
                        </>
                    }
                />
                <div className="row-caixa">
                    <div className="col-caixa-list">
                        <Box className={`no-padding with-pagination with-filter-inline`}>
                            <Table 
                                header={data.header}
                                rows={data.rows}
                                loading={loading}
                            />
                        </Box>
                    </div>
                    <div className="col-caixa-details">
                        <div className="detail">
                            <span className="title">Saldo atual da conta</span>
                            <span className={`value ${saldoCaixas < 0 ? 'red' : 'green'}`}>{convertIntToMoney(saldoCaixas, true)}</span>
                        </div>
                        <h2>Informações do período</h2>
                        <div className="detail">
                            <span className="title">Saldo inicial</span>
                            <span className={`value ${saldoInicialPeriodo < 0 ? 'red' : 'green'}`}>{convertIntToMoney(saldoInicialPeriodo, true)}</span>
                        </div>
                        <div className="detail">
                            <span className="title">Entradas</span>
                            <span className="value green">{convertIntToMoney(entradas, true)}</span>
                        </div>
                        <div className="detail">
                            <span className="title">Saídas</span>
                            <span className="value red">{convertIntToMoney(saidas, true)}</span>
                        </div>
                        <div className="detail">
                            <span className="title">Saldo final</span>
                            <span className={`value ${saldoFinalPeriodo < 0 ? 'red' : 'green'}`}>{convertIntToMoney(saldoFinalPeriodo, true)}</span>
                        </div>
                        {/* <h2>Outras opções</h2>
                        <ul>
                            <li><a href="#">Editar contas</a></li>
                            <li><a href="#">Editar categorias</a></li>
                        </ul> */}
                    </div>
                </div>
            </PanelTemplate>
        </>
    );
}

export default FluxoCaixa;