import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { cropFileName, getBase64 } from "../../helpers/conversions";

export const InputFile = ({label, name,  changeBase64, changeName, description}) => {
    const [loading, setLoading] = useState(false);

    const ref = useRef();

    const upload = e => {
        let file = ref.current.files[0];

        if(!['image/svg+xml', 'image/png', 'image/gif', 'image/jpeg'].includes(file.type)){
            toast.error('O formato do arquivo é inválido')
            return;
        }

        setLoading(true);
        changeName(file.name);
        getBase64(file, res => {
            changeBase64(res);
            setLoading(false);
        });
    }

    return (
        <div className="input-group">
            {label &&
                <label>{label}</label>
            }
            <div className="input-file-content">
                {name && name.includes('http') &&
                    <img src={name} />
                }
                <input ref={ref} type={`file`} onChange={upload}  accept=".jpeg, .jpg, .png, .gif, .svg" />
                {name === '' && !loading &&
                    <button className="choose" onClick={() => ref.current.click()}><img src={ require(`../../assets/images/svgs/upload-small.svg`).default} /> Escolher</button>
                }
                {loading &&
                    <button className="loading"><img src={ require(`../../assets/images/svgs/loading.svg`).default} /> Carregando...</button>
                }
                {name !== '' && !loading &&
                    <button className="complete" onClick={() => ref.current.click()}>
                        {cropFileName(name)}
                    </button>
                }
            </div>
                {description &&
                    <span className="desc">{description}</span>
                }
        </div>
    );
}

export default InputFile;