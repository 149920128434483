import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import InputDate from "../../../components/InputDate";
import InputEditorTiny from "../../../components/InputEditorTiny";
import InputSelect from "../../../components/InputSelect";
import Modal from "../../../components/Modal";
import { ModalDelete } from "../../../components/ModalDelete";
import Table from "../../../components/Table";
import TableImage from "../../../components/TableImage";
import { convertIntToMoney, convertMoenyToInt } from "../../../helpers/conversions";
import { renderError } from "../../../helpers/errors";
import { createItemOptions, createProductOptions } from "../../../helpers/selects";
import api from "../../../services/api";

const ArtTab = ({order}) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({rows: [], header:[]});
    const [showCreateArt, setShowCreateArt] = useState(false);
    const [showEditArt, setShowEditArt] = useState(false);

    const [orderArts, setOrderArts] = useState([]);
    const [orderArtIdToDelete, setOrderArtIdToDelete] = useState(null);

    const [orderArtId , setOrderArtId ] = useState(null);
    const [description , setDescription ] = useState('');
    const [comments , setComments ] = useState('');
    const [orderItemId , setOrderItemId ] = useState(null);
    const [fileUrl, setFileUrl] = useState('');

    const [removeFile, setRemoveFile] = useState(false);

    useEffect(() => {
        if(order){
            getOrderArts();
        }
    }, [order]);

    useEffect(() => {
        if(showCreateArt === false) clean();
    }, [showCreateArt]);

    useEffect(() => {
        if(showEditArt === false) clean();
    }, [showEditArt])

    useEffect(() => {
        generateData();
    }, [orderArts]);

    const getOrderArts = () => {
        api.get(`/order/art?filters[0][order_id][equal]=${order.id}`).then(res => {
            setOrderArts(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Imagem', 'SKU', 'Nome', 'Descrição', 'Pré-visualizar'];
        const rows = [];
        
        
        
        orderArts.map(orderArt => {
            let buttons = [
                <Button 
                    type={`secondary`}
                    size={`small`}
                    text={`Pré-visualizar da arte`}
                    action={() => window.open(orderArt.file_url)}
                />
            ];

            rows.push({
                data: [
                    <TableImage url={orderArt.file_url} />,
                    orderArt?.item_sku,
                    orderArt?.item_name,
                    orderArt?.description
                ],
                action: () => edit(orderArt),
                buttons
            });
        });

        setData({header, rows});
    }

    const edit = orderArt => {
        setOrderArtId(orderArt.id);
        setDescription(orderArt.description);
        setComments(orderArt.comments);
        setOrderItemId(orderArt.order_item_id);
        setFileUrl(orderArt.file_url);
        setShowEditArt(true);
    }

    const storeArt = () => {
        let form = new FormData();
        let el = document.getElementById('file');
        let file = el.files[0];

        if(file === undefined){
            toast.error('Você precisa informar um arquivo');
            return;
        }

        form.append('description', description);
        form.append('comments', comments);
        form.append('order_item_id', orderItemId);
        form.append('order_id', order.id);
        form.append('file', file);

        setLoading(true);
        
        api.post(`/order/art`, form, { 
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(res => {
            getOrderArts();
            setShowCreateArt(false);
            toast.success('Arte cadastrada com sucesso');
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const updateArt = () => {
        let form = new FormData();
        form.append('description', description);
        form.append('comments', comments);
        form.append('order_item_id', orderItemId);
        form.append('order_id', order.id);

        if(removeFile){
            let el = document.getElementById('file');
            let file = el.files[0];
            
            if(file === undefined){
                toast.error('Você precisa informar um arquivo');
                return;
            }

            form.append('file', file);
        }

        setLoading(true);
        
        api.post(`/order/art/${orderArtId}?_method=PUT`, form, { 
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(res => {
            getOrderArts();
            setShowEditArt(false);
            toast.success('Arte atualizada com sucesso');
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const clean = () => {
        setOrderArtId(null);
        setDescription('');
        setFileUrl('');
        setComments('');
        setRemoveFile(false);
        setOrderItemId(null);
    }

    const deleteArt = () => {
        setLoading(true);

        api.delete(`/order/art/${orderArtIdToDelete}`).then(res => {
            toast.success('Arte deletada com sucesso');
            setOrderArtIdToDelete(null);
            getOrderArts();
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const confirmDelete = id => {
        setOrderArtIdToDelete(id);
        setShowEditArt(false);
    }

    const downloadFile = fileUrl => {
        window.open(fileUrl);
    }

    return (
        <>
            <ModalDelete
                action={deleteArt}
                close={() => setOrderArtIdToDelete(null)}
                show={orderArtIdToDelete !== null}
                loading={loading}
            />
            {(showCreateArt || showEditArt) &&
            <Modal 
                show={true}
                size={'big'}
                close={() => showCreateArt ?  setShowCreateArt(false) : setShowEditArt(false)}
                title={`${showEditArt ? 'Editar' : 'Cadastrar'} arte`}
                footer={
                    <>
                        {showEditArt &&
                            <Button
                                type={`secondary`}
                                size={`small`}
                                text={`Excluir`}
                                svg={`delete-small`}
                                action={() => confirmDelete(orderArtId)}
                                loading={loading} 
                            />
                        }
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={showCreateArt ? 'Cadastrar' : 'Salvar alterações'}
                            svg={`save-white`}
                            action={() => showCreateArt ? storeArt() : updateArt()}
                            loading={loading} 
                        />
                    </>
                }
            >
                <div className="section">
                    <InputSelect
                        label={`Produto`}
                        options={createItemOptions(order.items)} 
                        value={orderItemId} 
                        change={setOrderItemId}
                    />
                </div>
                <div className="section">
                    <Input 
                        label={`Descrição`}
                        value={description}
                        change={setDescription}
                    />
                </div>
                <div className="section" style={{ paddingBottom: 15}}>
                    {(removeFile || showCreateArt) &&
                        <div className="row">
                            <div className="col-7">
                                <input type={`file`} id="file" accept=".png,.jpeg,.jpg" />
                            </div>
                        </div>
                    }
                    <p style={{ marginTop: 10 }}><b>Formatos aceitos:</b> jpeg ou png</p>
                    {showEditArt && !removeFile &&
                        <div className="row">
                            <div className="col-4">
                                <Button 
                                    full={true}
                                    type={`secondary`}
                                    size={`small`}
                                    svg={`download`}
                                    text={`Download arte`}
                                    action={() => downloadFile(fileUrl)}
                                />
                            </div>
                            <div className="col-4">
                                <Button 
                                    full={true}
                                    type={`secondary`}
                                    size={`small`}
                                    svg={`delete`}
                                    text={`Remover arte`}
                                    action={() => setRemoveFile(true)}
                                />
                            </div>
                            <div className="col-4"></div>
                        </div>
                    }
                </div>
                <div className="section">
                    <InputEditorTiny
                        label={`Observações`}
                        value={comments}
                        change={setComments}
                    />
                </div>
            </Modal>
            }
            <div className="row">
                <div className="col">
                    <Button 
                        type={`secondary`}
                        size={`small`}
                        text={`Cadastrar arte`}
                        action={() => setShowCreateArt(true)}
                    />
                    <br />
                </div>
            </div>
            <Table rows={data.rows} header={data.header} loading={loading} />
        </>
    );
} 

export default ArtTab;