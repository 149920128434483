import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import PanelTemplate from "../../templates/Panel";
import PageFooter from "../../components/PageFooter";
import Button from "../../components/Button";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";

import { ModalDelete } from "../../components/ModalDelete";
import BoxTabs from "../../components/BoxTabs";
import OrderDetailTab from "./tabs/detail";
import OrderNfsTab from "./tabs/nfs";
import BankSlipTab from "./tabs/bankslips";
import { toast } from "react-toastify";
import Box from "../../components/Box";
import Table from "../../components/Table";
import { convertIntToMoney, convertMoenyToInt } from "../../helpers/conversions";
import ModalProductDetails from "./modals/ModalProductDetails";
import { hasPermission } from "../../services/auth";
import { checkComissao, countProductPendingConferences, countProductPendingConferencesTag } from "./helpers/conference";
import PurchaseTab from "./tabs/purchase";
import ArtTab from "./tabs/art";
import CustomizationTab from "./tabs/customization";
import ProductionTab from "./tabs/production";
import ReceiptTab from "./tabs/receipt";
import { TableProductStatus } from "../../components/TableProductStatus";
import { Approval } from "./tabs/approval";
import LogTab from "./tabs/log";
import moment from "moment";

export const OrderEdit = () => {
    const { id } = useParams();
    const navigete = useNavigate();

    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const [items, setItems] = useState([]);

    const [financeComments, setFinanceComments] = useState('');
    const [comments, setComments] = useState('');

    const [shippingDate, setShippingDate] = useState(null);

    const [shippingTypeReal, setShippingTypeReal] = useState(null);
    const [shippingFeeReal, setShippingFeeReal] = useState('R$ 0,00');
    const [shippingTimeReal, setShippingTimeReal] = useState('');
    const [paymentTermReal, setPaymentTermReal] = useState('');
    const [paymentFormReal, setPaymentFormReal] = useState('');
    const [billingCompanyCnpjReal, setBillingCompanyCnpjReal] = useState('');

    const [shippingTypeConference, setShippingTypeConference] = useState(false);
    const [shippingFeeConference, setShippingFeeConference] = useState(false);
    const [shippingTimeConference, setShippingTimeConference] = useState(false);
    const [paymentTermConference, setPaymentTermConference] = useState(false);
    const [paymentFormConference, setPaymentFormConference] = useState(false);
    const [billingCompanyCnpjConference, setBillingCompanyCnpjConference] = useState(false);

    const [shippingCompany, setShippingCompany] = useState(null);

    const [entregue, setEntregue] = useState(false);

    const [itemToShowDetails, setItemToShowDetails] = useState(null)

    useEffect(() => {
        getOrder();
    }, []);

    const getOrder = () => {
        setLoading(true);

        api.get(`/order/${id}`).then(res => {
            let resource = res.data.resource;
            console.log('resource', resource);
            setOrder(resource);
            setShippingDate(resource.shipping_date ? new Date(`${resource.shipping_date} 00:00:00`) : null);
            setFinanceComments(resource.finance_comments);
            setComments(resource.comments);
            setShippingTypeReal(resource.shipping_type_real);
            setShippingTypeConference(resource.shipping_type_conference);
            setShippingFeeReal(convertIntToMoney(resource.shipping_fee_real, true));
            setShippingFeeConference(resource.shipping_fee_conference);
            setShippingTimeReal(resource.shipping_time_real);
            setShippingTimeConference(resource.shipping_time_conference);
            setPaymentTermReal(resource.payment_term_real);
            setPaymentTermConference(resource.payment_term_conference);
            setPaymentFormReal(resource.payment_form_real);
            setPaymentFormConference(resource.payment_form_conference);
            setBillingCompanyCnpjReal(resource.billing_company_cnpj_real);
            setBillingCompanyCnpjConference(resource.billing_company_cnpj_conference);
            setShippingCompany(resource.shipping_company_id);
            setEntregue(resource.entregue);
            getItems(resource.items);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const deleteOrder = () => {
        setLoading(true);

        api.delete(`/order/${id}`).then(res => {
            navigete(`/order/list`);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const updateOrder = () => {
        setLoading(true);

        api.put(`/order/${order.id}`, {
            shipping_date: shippingDate ? moment(shippingDate).format('YYYY-MM-DD') : null,
            finance_comments: financeComments,
            comments: comments,
            shipping_type_real: shippingTypeReal,
            shipping_type_conference: shippingTypeConference,
            shipping_fee_real: convertMoenyToInt(shippingFeeReal),
            shipping_fee_conference: shippingFeeConference,
            shipping_time_real: shippingTimeReal,
            shipping_time_conference: shippingTimeConference,
            payment_term_real: paymentTermReal,
            payment_term_conference: paymentTermConference,
            payment_form_real: paymentFormReal,
            payment_form_conference: paymentFormConference,
            billing_company_cnpj_real: billingCompanyCnpjReal,
            billing_company_cnpj_conference: billingCompanyCnpjConference,
            shipping_company_id: shippingCompany,
            entregue
        }).then(res => {
            toast.success('Pedido atualizado com sucesso');
            getOrder();
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const getItems = items => {
        let toItems = [];

        items.map(item => {
            let image = <img src={ require(`../../assets/images/svgs/default.svg`).default } />;
            if(item.subproduct_image !== null){
                image = <img src={item.subproduct_image_url} />;
            }

            toItems.push({
                ...item,
                image,
                price: `R$${convertIntToMoney(item.price)}`, 
                total: `R$${convertIntToMoney(item.price * item.quantity)}`
            });
        });

        setItems(toItems);
    }

    const sendOrder = () => {
        setLoading(true);

        api.post(`/order/${order.id}/send`).then(res => {
            toast.success('Pedido enviado com sucesso');
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const emitirNf = () => {
        setLoading(true);

        api.post(`/order/${order.id}/nf`).then(res => {
            window.location.href = '/contas-a-receber';
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const getTitles = () => {
        let titles = ['Ordem de Serviço'];


        if(hasPermission('view_compras')) titles.push('Compras');
        if(hasPermission('view_artes')) titles.push('Artes');
        if(hasPermission('view_gravacao')) titles.push('Gravação');
        if(hasPermission('view_producao')) titles.push('Produção');
        if(hasPermission('view_recebimento')) titles.push('Recebimento');
        if(hasPermission('view_aprovacao')) titles.push('Aprovação');
        if(hasPermission('view_logs')) titles.push('Logs');

        return titles;
    }

    return (
        <>
            {itemToShowDetails &&
                <ModalProductDetails
                    close={() => setItemToShowDetails(null)}
                    item={itemToShowDetails}
                    getOrder={getOrder}
                />
            }
            <ModalDelete
                show={confirmDelete}
                action={deleteOrder}
                loading={loading}
                close={() => setConfirmDelete(false)}
            />
            <PanelTemplate id="order-edit">
                <BoxTabs
                    titles={getTitles()}
                    contents={
                        [
                            <OrderDetailTab
                                order={order}
                                shippingDate={shippingDate}
                                setShippingDate={setShippingDate}
                                financeComments={financeComments}
                                setFinanceComments={setFinanceComments}
                                comments={comments}
                                setComments={setComments}
                                shippingTypeReal={shippingTypeReal}
                                setShippingTypeReal={setShippingTypeReal}
                                shippingFeeReal={shippingFeeReal}
                                setShippingFeeReal={setShippingFeeReal}
                                shippingTimeReal={shippingTimeReal}
                                setShippingTimeReal={setShippingTimeReal}
                                paymentTermReal={paymentTermReal}
                                setPaymentTermReal={setPaymentTermReal}
                                paymentFormReal={paymentFormReal}
                                setPaymentFormReal={setPaymentFormReal}
                                shippingTypeConference={shippingTypeConference}
                                setShippingTypeConference={setShippingTypeConference}
                                shippingFeeConference={shippingFeeConference}
                                setShippingFeeConference={setShippingFeeConference}
                                shippingTimeConference={shippingTimeConference}
                                setShippingTimeConference={setShippingTimeConference}
                                paymentTermConference={paymentTermConference}
                                setPaymentTermConference={setPaymentTermConference}
                                paymentFormConference={paymentFormConference}
                                setPaymentFormConference={setPaymentFormConference}
                                billingCompanyCnpjReal={billingCompanyCnpjReal}
                                setBillingCompanyCnpjReal={setBillingCompanyCnpjReal}
                                billingCompanyCnpjConference={billingCompanyCnpjConference}
                                setBillingCompanyCnpjConference={setBillingCompanyCnpjConference}
                                setShippingCompany={setShippingCompany}
                                shippingCompany={shippingCompany}
                                entregue={entregue}
                                setEntregue={setEntregue}
                            />,
                            <PurchaseTab
                                order={order}
                            />,
                            <ArtTab
                                order={order}
                            />,
                            <CustomizationTab
                                order={order}
                            />,
                            <ProductionTab
                                order={order}
                            />,
                            <ReceiptTab
                                order={order}
                            />,
                            <Approval 
                                order={order}
                            />,
                            <LogTab 
                                order={order}
                            />
                        ]
                    }
                />
                {items.map((item, index) => (
                    <div key={index} className="order-product">
                        <div className="product-details">
                            {item.image}
                            <div className="detail">
                                <strong>Código</strong>
                                <span>{item.code}</span>
                            </div>
                            <div className="detail">
                                <strong>SKU</strong>
                                <span>{item.subproduct_sku}</span>
                            </div>
                            <div className="detail">
                                <strong>Nome</strong>
                                <span>{item.product_name}</span>
                            </div>
                            <div className="detail">
                                <strong>Quantidade</strong>
                                <span>{item.quantity}</span>
                            </div>
                            <div className="detail">
                                <strong>Preço unit.</strong>
                                <span>{item.price}</span>
                            </div>
                            <div className="detail">
                                <strong>Total</strong>
                                <span>{item.total}</span>
                            </div>
                            <div className="detail">
                                <Button 
                                    type={`transparent`} 
                                    text={`+ Mais detalhes`}
                                    action={() => setItemToShowDetails(item)}
                                />
                            </div>
                        </div>
                        <TableProductStatus item={item} />
                    </div>
                ))}
                {/* <Box
                    className={`no-padding`}
                    title={`Produtos do pedido`}
                >
                    <Table header={itemsData.header} rows={itemsData.rows} loading={loading} />
                </Box> */}
                <PageFooter spaceBetween={true}>
                    <div>
                        {hasPermission('edit_order') &&
                            <Button type={`primary`} size={`small`} svg={`save-white`} text={`Salvar alterações`} action={updateOrder} loading={loading} />
                        }
                        <Button type={`secondary`} size={`small`} svg={`pdf`} text={`Visualizar pedido`} action={() => window.open(order?.pdf_url)} loading={loading} />
                        <Button type={`secondary`} size={`small`} svg={`send`} text={`Faturar pedido`} action={emitirNf} loading={loading} />
                    </div>
                    <div>
                        {hasPermission('delete_order') &&
                            <Button type={`secondary`} size={`small`} loading={loading} text={`Excluir`} svg={`delete-small`} action={() => setConfirmDelete(true)} />
                        }
                    </div>
                </PageFooter>
            </PanelTemplate>
        </>
    );
}

export default OrderEdit;