import React, { useState } from "react";
import Box from "../../components/Box";
import PanelTemplate from "../../templates/Panel";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import InputDate from "../../components/InputDate";
import InputCheckboxUnique from "../../components/InputCheckboxUnique";
import states from "../../seeds/states";
import Table from "../../components/Table";
import Button from "../../components/Button";
import InputTextarea from "../../components/InputTextarea";
import SelectPaymentForm from "../../components/selects/SelectPaymentForm";
import SelectPaymentTerm from "../../components/selects/SelectPaymentTerm";
import PageFooter from "../../components/PageFooter";
import ModalItem from "./ModalItem";

const finalidades = [
    {label: 'NF-e normal', value: 'normal'},
    {label: 'NF-e complementar', value: 'complementar'},
    {label: 'NF-e de ajuste', value: 'ajuste'},
    {label: 'Devolução de mercadoria', value: 'devolucao'}
];

const presencas = [
    {label: '9 - Operação não presencial, outros', value: 9}
];

const destinatarioTipos = [
    {label: 'Pessoa física', value: 'PF'},
    {label: 'Pessoa jurídica', value: 'PJ'}
];

const itemDefault = {
    name: "",
    sku: "",
    quantity: ""
};


export const NfCreate = () => {
    const [finalidade, setFinalidade] = useState('normal');
    const [presenca, setPresenca] = useState(9);
    const [destinatarioTipo, setDestinatarioTipo] = useState('PJ');
    const [item, setItem] = useState(null);

    return (
        <PanelTemplate id="nf-create">
            {item !== null &&
                <ModalItem />
            }
            <Box>
                <div className="row">
                    <div className="col-4">
                        <InputSelect
                            label={`Natureza de operação *`}
                            options={[]}
                        />
                    </div>
                    <div className="col-2">
                        <InputDate label={`Data de emissão *`} value={new Date()} />
                    </div>
                    <div className="col-2">
                        <Input label={`Hora de emissão`}/>
                    </div>
                    <div className="col-2">
                        <InputDate label={`Data de saída`} value={new Date()} />
                    </div>
                    <div className="col-2">
                        <Input label={`Hora de saída`}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <InputSelect
                            label={`Código do regime tributário *`}
                            options={[]}
                        />
                    </div>
                    <div className="col-4">
                        <InputSelect
                            label={`Finalidade`}
                            options={finalidades}
                            value={finalidade}
                            change={setFinalidade}
                        />
                    </div>
                    <div className="col-4">
                        <InputSelect
                            label={`Indicador de presença`}
                            options={presencas}
                            value={presenca}
                            change={setPresenca}
                        />
                    </div>
                </div>
                <h2>Destinatário</h2>
                <div className="row">
                    <div className="col-4">
                        <InputSelect
                            label={`Tipo de pessoa *`}
                            options={destinatarioTipos}
                            value={destinatarioTipo}
                            change={setDestinatarioTipo}
                        />
                    </div>
                    <div className="col-4">
                        <InputSelect
                            label={`Empresa`}
                            options={[]}
                        />
                    </div>
                    <div className="col-4">
                        <Input disabled={true} label={`CNPJ`} />
                    </div>
                    <InputCheckboxUnique label={`Consumidor final`} />
                </div>
                <div className="row">
                    <div className="col-3">
                        <Input type={`text`} label={`CEP`} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Input type={`text`} label={`Logradouro`} />
                    </div>
                    <div className="col-3">
                        <Input type={`text`} label={`Número`} />
                    </div>
                    <div className="col-3">
                        <Input type={`text`} label={`Complemento`} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <InputSelect label={`Estado`} options={states} />
                    </div>
                    <div className="col-3">
                        <Input type={`text`} label={`Cidade`} />
                    </div>
                    <div className="col-3">
                        <Input type={`text`} label={`Bairro`} />
                    </div>
                </div>
                <h2>Itens da nota fiscal</h2>
                <Table
                    header={['Produto', 'Código', 'Quant.', 'Preço unit.', 'Total', 'NCM']}
                    rows={[]}
                />
                <Button style={{ paddingLeft: 0 }} type={`link`} clas text={`Adicionar item`} action={setItem(itemDefault)}  />
                <h2>Cálculo de impostos</h2>
                <div className="row">
                    <div className="col-2">
                        <Input type={`text`} label={`Total dos itens`} disabled={true} />
                    </div>
                    <div className="col-2">
                        <Input type={`text`} label={`Valor do frete`} />
                    </div>
                    <div className="col-2">
                        <Input type={`text`} label={`Valor do seguro`} />
                    </div>
                    <div className="col-2">
                        <Input type={`text`} label={`Outras despesas`} />
                    </div>
                    <div className="col-2">
                        <Input type={`text`} label={`Desconto`} />
                    </div>
                    <div className="col-2">
                        <Input type={`text`} label={`Total da nota`} disabled={true} />
                    </div>
                </div>
                <h2>Transporte</h2>
                <div className="row">
                    <div className="col-4">
                        <InputSelect
                            label={`Transporte`}
                            options={[]}
                        />
                    </div>
                    <div className="col-4">
                        <InputSelect
                            label={`Frete por conta`}
                            options={[]}
                        />
                    </div>
                </div>
                <h2>Pagamento</h2>
                <div className="row">
                    <div className="col-4">
                        <SelectPaymentForm />
                    </div>
                    <div className="col-4">
                        <SelectPaymentTerm />
                    </div>
                    <div className="col-4">
                        <InputSelect
                            label={`Categoria`}
                            options={[]}
                        />
                    </div>
                </div>
                <h2>Informações adicionais</h2>
                <InputTextarea label={`Informações complementares`} />
            </Box>
            <PageFooter>
                <Button
                    type={`primary`}
                    size={`small`}
                    text={`Salvar nota fiscal`}
                    svg={`save-white`}
                    link={`/nf/list`}
                />
                 <Button
                    type={`secondary`}
                    size={`small`}
                    text={`Voltar`}
                    link={`/nf/list`}
                />
            </PageFooter>
        </PanelTemplate>
    );
}

export default NfCreate;