import React, { useState, useEffect} from "react";
import Input from "../../../components/Input";
import InputSelect from "../../../components/InputSelect";
import { codigoOrigem } from "../../../seeds/fiscal";

export const ProductTributacao = ({origem, setOrigem, ncm, setNcm, cest, setCest}) => {
    return (
        <>
            {/* <div className="row">
                <div className="col">
                    <InputSelect
                        label={'Origem'}
                        options={codigoOrigem}
                        value={origem}
                        change={setOrigem}
                    />
                </div>
            </div> */}
            <div className="row">
                <div className="col-3">
                    <Input
                        label={'NCM'}
                        value={ncm}
                        change={setNcm}
                    />
                </div>
                <div className="col-3">
                    <Input
                        label={'CEST'}
                        value={cest}
                        change={setCest}
                    />
                </div>
            </div>
        </>
    );
}