import React, { useEffect, useState } from "react";
import Box from "../../components/Box";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Modal from "../../components/Modal";
import { ModalDelete } from "../../components/ModalDelete";
import PageHeader from "../../components/PageHeader";
import Table from "../../components/Table";
import PanelTemplate from "../../templates/Panel";
import api from "../../services/api";
import { toast } from "react-toastify";
import { renderError } from "../../helpers/errors";

export const PaymentForm = () => {
    const [loading, setLoading] = useState(false);
    const [metodos, setMetodos] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const [id, setId] = useState('');
    const [name, setName] = useState('');

    const [metodoToDelete, setMetodoToDelete] = useState(null);

    useEffect(() => {
        getMetodos();
    }, []);

    useEffect(() => {
        generateData();
    }, [metodos]);

    useEffect(() => {
        if(!showCreate) clean();
    }, [showCreate]);

    useEffect(() => {
        if(!showEdit) clean();
    }, [showEdit]);

    const getMetodos = () => {
        api.get('/paymentform').then(res => {
            setMetodos(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Nome'];
        const rows = [];
        
        metodos.map(metodo => {
            rows.push({
                data: [
                    metodo.name,
                ],
                action: () => editMetodo(metodo)
            });
        });

        setData({header, rows});
    }

    const storeMetodo = () => {
        setLoading(true);

        api.post(`/paymentform`, {
            name
        }).then(res => {
            getMetodos();
            setShowCreate(false);
            toast.success('Método de pagamento cadastrado com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const editMetodo = metodo => {
        setId(metodo.id);
        setName(metodo.name);
        setShowEdit(true);
    }

    const updateMetodo = () => {
        setLoading(true);

        api.put(`/paymentform/${id}`, {
            name
        }).then(res => {
            getMetodos();
            setShowEdit(false);
            toast.success('Método de pagamento atualizado com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const clean = () => {
        setId('');
        setName('');
    }

    const deleteMetodo = () => {
        setLoading(true);

        api.delete(`/paymentform/${metodoToDelete}`).then(res => {
            setMetodoToDelete(null);
            setShowEdit(false);
            getMetodos();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }
    
    return (
        <>
            <Modal
                title={`${showCreate ? 'Cadastrar' : 'Editar'} método de pagamento`}
                show={showCreate || showEdit}
                close={() => showCreate ? setShowCreate(false) : setShowEdit(false)}
                footer={
                    <>
                        {showEdit &&
                            <Button
                                type={`secondary`}
                                size={`small`}
                                text={`Excluir`}
                                svg={`delete-small`}
                                action={() => setMetodoToDelete(id)}
                                loading={loading} 
                            />
                        }
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={showCreate ? 'Cadastrar' : 'Salvar alterações'}
                            svg={`save-white`}
                            action={() => showCreate ? storeMetodo() : updateMetodo()}
                            loading={loading} 
                        />
                    </>
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col">
                            <Input type={`text`} label={`Nome`} value={name} change={setName} />
                        </div>
                    </div>
                </div>
            </Modal>
            <ModalDelete
                show={metodoToDelete !== null}
                action={deleteMetodo}
                close={() => setMetodoToDelete(null)}
                loading={loading}
            />
            <PanelTemplate id="metodo-pagamento">
                <PageHeader
                    right={
                        <>
                            <Button type={`primary`} size={`small`} text={`Cadastrar método de pagamento`} svg="plus-white" action={() => setShowCreate(true)}  />
                        </>
                    }
                />
                <Box className="no-padding">
                    <Table header={data.header} rows={data.rows} loading={loading} />
                </Box>
            </PanelTemplate>
        </>
    )
}