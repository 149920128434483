import React, { useEffect, useState } from "react";
import Select from 'react-select';
import PanelTemplate from "../../templates/Panel";
import PageHeader from "../../components/PageHeader";
import Button from "../../components/Button";
import FilterSimple from "../../components/FilterSimple";
import Box from "../../components/Box";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import InputDate from "../../components/InputDate";
import InputTextarea from "../../components/InputTextarea";
import DatePicker from "react-date-picker";

const headerDefault = ['Número', 'Data emissão', 'Cliente', 'Valor', 'Situação'];

export const NfList = () => {
    const [filterStartDate, setFilterStartDate] = useState(new Date());
    const [filterFinalDate, setFilterFinalDate] = useState(new Date());

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({header: headerDefault, rows: []});

    const [selectedIds, setSelectedIds] = useState([]);
    const [showConfirmMultipleDelete, setShowConfirmMultipleDelete] = useState(false);

    const handleSetFilter = () => {

    }

    return (
        <>
            <PanelTemplate>
                <PageHeader
                    left={
                        <>

                            <FilterSimple 
                                params={[
                                    {name: 'Número', key: '[origin]', operation: 'contain', type: 'text' },
                                    {name: 'Nome fantasia', key: '[related][company.trade_name]', operation: 'contain', type: 'text' },
                                    {name: 'Razão social', key: '[related][company.company_name]', type: 'text' },
                                    {name: 'CNPJ', key: '[related][company.cnpj]', operation: 'contain', type: 'text' },
                                    {name: 'E-mail', key: '[related][customer.email]', operation: 'contain', type: 'email' }
                                ]}
                                filter={handleSetFilter}
                            />
                            <span>Início:</span>
                            <DatePicker 
                                className={`date-filter`}
                                format="dd/MM/y"
                                onChange={setFilterStartDate}
                                value={filterStartDate}
                            />
                            <span>Fim:</span>
                            <DatePicker 
                                className={`date-filter`}
                                format="dd/MM/y"
                                onChange={setFilterFinalDate}
                                value={filterFinalDate}
                            />
                        </>
                    }

                    right={
                        <>
                            {selectedIds.length > 0 &&
                                <Button
                                    type={`secondary`} 
                                    size={`small`} 
                                    svg={`delete`}
                                    text={`Excluir ${selectedIds.length} selecionados`} 
                                    action={() => setShowConfirmMultipleDelete(true)}  
                                />
                            }
                            <Button type={`primary`} size={`small`} text={`Cadastrar nota fiscal`} svg="plus-white" link={`/nf/create`}  />
                        </>
                    }
                />
                <div className="row-nfs">
                    <div className="col-nfs-list">
                        <Box className={`no-padding with-pagination with-filter-inline`}>
                            <Table 
                                header={data.header}
                                rows={data.rows}
                                checks={true}
                                loading={loading}
                                selectedIds={selectedIds}
                                setSelectedIds={setSelectedIds}
                            />
                        </Box>
                    </div>
                    <div className="col-nfs-details">
                        <h2>Ações</h2>
                        <ul style={{ marginBottom: 15 }}>
                            <li><a className={`disabled`} href="#">Enviar selecionadas</a></li>
                        </ul>
                        <h2>Informações do período</h2>
                        <div className="detail">
                            <span className="title">Quantidade de notas</span>
                            <span className="value green">0</span>
                        </div>
                        <div className="detail">
                            <span className="title">Valor total</span>
                            <span className="value green">R$ 0,00</span>
                        </div>
                        {/* <h2>Outras opções</h2>
                        <ul>
                            <li><a href="#">Configurações</a></li>
                        </ul> */}
                    </div>
                </div>
            </PanelTemplate>
        </>
    );
}

export default NfList;