import React, { useEffect, useState } from "react";
import Select from 'react-select';
import PanelTemplate from "../../templates/Panel";
import PageHeader from "../../components/PageHeader";
import Button from "../../components/Button";
import FilterSimple from "../../components/FilterSimple";
import Box from "../../components/Box";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import InputDate from "../../components/InputDate";
import InputTextarea from "../../components/InputTextarea";
import DatePicker from "react-date-picker";
import SelectCaixa from "../../components/selects/SelectCaixa";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import { convertIntToMoney } from "../../helpers/conversions";
import moment from "moment";
import SelectContaPagar from "../../components/selects/SelectContaPagar";
import SelectContaReceber from "../../components/selects/SelectContaReceber";
import { toast } from "react-toastify";

const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '35px',
      height: '35px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: '3px',
      borderBottomRightRadius: '3px',
      boxShadow: state.isFocused ? null : null,
      fontSize: '14px',
      width: 250
    }),
}

const headerDefault = ['Data', 'Categoria', 'NF', 'Descrição', 'Cliente ou fornecedor', 'Conta', 'Valor', 'Status'];

export const Conciliacao = () => {
    const today = new Date();
    const sDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const fDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const [filterStartDate, setFilterStartDate] = useState(sDate);
    const [filterFinalDate, setFilterFinalDate] = useState(fDate);

    const [loading, setLoading] = useState(false);
    const [transacoes, setTransacoes] = useState([]);
    const [data, setData] = useState({header: headerDefault, rows: []});

    const [selectedConta, setSelectedConta] = useState(null);

    const [showImportLancamentos, setShowImportLancamentos] = useState(false);

    const [transacaoToEdit, setTransacaoToEdit] = useState(null);
    const [showEditTransacao, setShowEditTransacao] = useState(false);

    const [selectedContaPagar, setSelectedContaPagar] = useState(null);
    const [selectedContaReceber, setSelectedContaReceber] = useState(null);

    useEffect(() => {
        getTransacaoes();
    }, []);

    useEffect(() => {
        generateData();
    }, [transacoes]);

    useEffect(() => {
        if(!showEditTransacao) clean();
    }, [showEditTransacao]);

    const getTransacaoes = () => {
        setLoading(true);

        api.get(`/caixa/transacao`).then(res => {
            setTransacoes(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Data', 'Categoria', 'NF', 'Empresa', 'Descrição', 'Valor', 'Status'];
        const rows = [];
        
        transacoes.map(transacao => {
            rows.push({
                data: [
                    moment(transacao.data).format('DD/MM/YYYY'),
                    transacao?.categoria,
                    '',
                    transacao?.empresa,
                    transacao?.descricao,
                    convertIntToMoney(transacao.valor, true),
                    transacao.status,
                ],
                action: () => editTransacao(transacao)
            });
        });

        setData({header, rows});
    }

    const editTransacao = transacao => {
        setTransacaoToEdit(transacao);
        setShowEditTransacao(true);
    }

    const updateTransacao = () => {
        setLoading(true);

        let data = {};

        if(transacaoToEdit.tipo === 'entrada'){
            data.conta_receber_id = selectedContaReceber
        } else {
            data.conta_pagar_id = selectedContaPagar
        }

        api.put(`/caixa/transacao/${transacaoToEdit.id}`, data).then(res => {
            toast.success('Conta atualizada');
            setShowEditTransacao(false);
            getTransacaoes();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const importLancamentos = () => {
        let form = new FormData();
        let el = document.getElementById('file');
        let file = el.files[0];

        if(file === undefined){
            alert('Você precisa informar um arquivo');
            return;
        }

        form.append('caixa_id', selectedConta);
        form.append('file', file);

        setLoading(true);
        
        api.post(`/caixa/transacao`, form, { 
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(res => {
            window.location.reload();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const clean = () => {
        setSelectedContaPagar(null);
        setSelectedContaReceber(null);
        setTransacaoToEdit(null);
    }


    return (
        <>
            <Modal
                title={`Editar transação`}
                show={showEditTransacao}
                close={() => setShowEditTransacao(false)}
                footer={
                    <>
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={`Salvar alterações`}
                            svg={`save-white`}
                            action={updateTransacao}
                            loading={loading} 
                        />
                    </>
                }
            >
                <div className="modal-section-title">
                    <div className="left">
                        <h2>Detalhes da transação</h2>
                    </div>
                </div>
                <div className="section" style={{ padding: 0}}>
                    <Table
                        header={['Data', 'Descrição', 'Valor']}
                        rows={[{data: [
                            transacaoToEdit ? moment(transacaoToEdit.data).format('DD/MM/YYYY') : '',
                            transacaoToEdit?.descricao,
                            transacaoToEdit ? convertIntToMoney(transacaoToEdit.valor, true) : ''
                        ]}]}
                        />
                </div>
                <div className="modal-section-title">
                    <div className="left">
                        <h2>Conciliação</h2>
                    </div>
                </div>
                <div className="section">
                    {transacaoToEdit?.valor > 0 &&
                        <SelectContaReceber
                            selectedContaReceber={selectedContaReceber}
                            setSelectedContaReceber={setSelectedContaReceber}
                        />
                    }
                    {transacaoToEdit?.valor < 0 &&
                        <SelectContaPagar
                            selectedContaPagar={selectedContaPagar}
                            setSelectedContaPagar={setSelectedContaPagar}
                        />
                    }
                </div>
                
            </Modal>
            <Modal
                title={`Importar extrato`}
                show={showImportLancamentos}
                close={() => setShowImportLancamentos(false)}
                footer={
                    <>
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={`Importar extrato`}
                            svg={`save-white`}
                            action={importLancamentos}
                            loading={loading} 
                        />
                    </>
                }
            >
                <div className="section">
                    <SelectCaixa
                        selectedCaixa={selectedConta}
                        setSelectedCaixa={setSelectedConta}
                    />
                    <p><b>Importe um arquivo no formato ofx</b></p>
                    <input type="file" id="file" accept=".ofx" style={{ marginTop: 15, marginBottom: 15}}/>
                </div>
            </Modal>
            <PanelTemplate>
                <PageHeader
                    left={
                        <>
                            {/* <Select
                                placeholder="Escolha uma empresa..."
                                options={empresas}
                                onChange={e => setSelectedEmpresa(e.value)}
                                value={selectedEmpresa}
                                styles={customStyles}
                            /> */}

                                {/* <SelectCaixa 
                                    removeLabel={true}
                                    selectedCaixa={selectedConta}
                                    setSelectedCaixa={setSelectedConta}
                                /> */}

                            <span>Início:</span>
                            <DatePicker 
                                className={`date-filter`}
                                format="dd/MM/y"
                                onChange={setFilterStartDate}
                                value={filterStartDate}
                            />
                            <span>Fim:</span>
                            <DatePicker 
                                className={`date-filter`}
                                format="dd/MM/y"
                                onChange={setFilterFinalDate}
                                value={filterFinalDate}
                            />
                        </>
                    }

                    right={
                        <>
                            <Button type={`primary`} size={`small`} text={`Importar extrato`} action={() => setShowImportLancamentos(true)} />
                        </>
                    }
                />
                {/* <div className="row-caixa"> */}
                    {/* <div className="col-caixa-list"> */}
                        <Box className={`no-padding with-pagination with-filter-inline`}>
                            <Table 
                                header={data.header}
                                rows={data.rows}
                                loading={loading}
                            />
                        </Box>
                    {/* </div> */}
                    {/* <div className="col-caixa-details">
                        <div className="detail">
                            <span className="title">Saldo atual da conta</span>
                            <span className="value green">R$ 0,00</span>
                        </div>
                        <h2>Informações do período</h2>
                        <div className="detail">
                            <span className="title">Saldo inicial</span>
                            <span className="value green">R$ 0,00</span>
                        </div>
                        <div className="detail">
                            <span className="title">Entradas</span>
                            <span className="value green">R$ 0,00</span>
                        </div>
                        <div className="detail">
                            <span className="title">Saídas</span>
                            <span className="value green">R$ 0,00</span>
                        </div>
                        <div className="detail">
                            <span className="title">Saldo final</span>
                            <span className="value green">R$ 0,00</span>
                        </div>
                        <h2>Outras opções</h2>
                        <ul>
                            <li><a href="#">Editar contas</a></li>
                            <li><a href="#">Editar categorias</a></li>
                        </ul>
                    </div> */}
                {/* </div> */}
            </PanelTemplate>
        </>
    );
}

export default Conciliacao;