import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import PanelTemplate from "../../templates/Panel";
import PageFooter from "../../components/PageFooter";
import Button from "../../components/Button";
import Box from "../../components/Box";
import Table from "../../components/Table";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import Input from "../../components/Input";
import { convertIntToMoney, convertMoenyToInt } from "../../helpers/conversions";
import InputSelect from "../../components/InputSelect";
import states from "../../seeds/states";
import axios from "axios";
import InputTextarea from "../../components/InputTextarea";
import { toast } from "react-toastify";
import InputEditorTiny from "../../components/InputEditorTiny";
import InputCheckboxUnique from "../../components/InputCheckboxUnique";
import { hasPermission } from "../../services/auth";
import InputDate from "../../components/InputDate";
import moment from "moment";
import SelectPaymentForm from "../../components/selects/SelectPaymentForm";
import SelectPaymentTerm from "../../components/selects/SelectPaymentTerm";

const recipientTypes = [
    {label: 'Pessoa física', value: 'PF'},
    {label: 'Pessoa jurídica', value: 'PJ'}
];

const shippingTypes = [
    {label: 'CIF', value: 'CIF'},
    {label: 'FOB', value: 'FOB'}
];

export const OrderCreate = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [quote, setQuote] = useState(null);

    const [replace, setReplace] = useState(false);
    const [replaceCode, setReplaceCode] = useState(0);

    const [selectedShippingType, setSelectedShippingType] = useState(null);
    const [shippingConsult, setShippingConsult] = useState(true);
    const [shippingFee, setShippingFee] = useState('R$ 0,00');
    const [shippingDate, setShippingDate] = useState(null);

    const [paymentTermId, setPaymentTermId] = useState(null);
    const [paymentFormId, setPaymentFormId] = useState(null);

    const [comments, setComments] = useState('');

    const [recipientCpf, setRecipientCpf] = useState('');
    const [recipientName, setRecipientName] = useState('');

    const [recipientCompanyName, setRecipientCompanyName] = useState('');
    const [recipientTradeName, setRecipientTradeName] = useState('');
    const [recipientZipCode, setRecipientZipCode] = useState('');
    const [recipientStreet, setRecipientStreet] = useState('');
    const [recipientNumber, setRecipientNumber] = useState('');
    const [recipientComplement, setRecipientComplement] = useState('');
    const [recipientCity, setRecipientCity] = useState('');
    const [recipientState, setRecipientState] = useState('');
    const [recipientDistrict, setRecipientDistrict] = useState('');
    const [recipientCnpj, setRecipientCnpj] = useState('');
    const [recipientIe, setRecipientIe] = useState('');
    const [recipientRg, setRecipientRg] = useState('');
    const [recipientPhone, setRecipientPhone] = useState('');
    const [recipientEmail, setRecipientEmail] = useState('');

    const [selectedRecipientType, setSelectedRecipientType] = useState(null);

    useEffect(() => {
        getQuote();
    }, []);

    useEffect(() => {
        getAddress();
    }, [recipientZipCode]);

    const getQuote = () => {
        setLoading(true);

        api.get(`/budget/${id}`).then(res => {
            let resource = res.data.resource;
            setQuote(resource);
            setSelectedShippingType(resource.shipping_type);
            setShippingFee('R$ ' + convertIntToMoney(resource.shipping_fee));
            setShippingConsult(resource.shipping_consult);
            setPaymentFormId(resource.payment_form_id);
            setPaymentTermId(resource.payment_term_id);
            setComments(`
            COMPRAS:<br/><br/>
            ARTES:<br/><br/>
            GRAVAÇÃO:<br/><br/>
            PRODUÇÃO:<br/><br/>
            FINANCEIRO:<br/><br/>
            `);

            setSelectedRecipientType(resource.type);
            setRecipientCpf(resource.customer.cpf);
            setRecipientName(resource.customer.name);
            if(resource.type === "PJ"){
                setRecipientCompanyName(resource.company.company_name);
                setRecipientTradeName(resource.company.trade_name);
                setRecipientCnpj(resource.company.cnpj);
            }
            setRecipientPhone(resource.customer.phone);
            setRecipientEmail(resource.customer.email);

            setRecipientZipCode(resource.shipping_zip_code);
            setRecipientStreet(resource.shipping_street);
            setRecipientNumber(resource.shipping_number);
            setRecipientComplement(resource.shipping_complement);
            setRecipientState(resource.shipping_state);
            setRecipientCity(resource.shipping_city);
            setRecipientDistrict(resource.shipping_district);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const getAddress = () => {
        if(recipientZipCode === null) return;
        console.log(recipientZipCode)
        let convertedZipCode = recipientZipCode.replace('-', '');
        if(convertedZipCode.length === 8){
            axios.get(`https://viacep.com.br/ws/${convertedZipCode}/json/`).then(res => {
                let address = res.data;
                setRecipientStreet(address.logradouro);
                setRecipientDistrict(address.bairro);
                setRecipientCity(address.localidade);
                setRecipientState(address.uf);
            })
        }
    }

    const store = () => {
        setLoading(true);
        
        api.post(`/order`, {
            budget_id: quote.id,
            replace_code: replace ? replaceCode : null,
            recipient_type: selectedRecipientType,
            recipient_company_name: recipientCompanyName,
            recipient_trade_name: recipientTradeName,
            recipient_zip_code: recipientZipCode,
            recipient_street: recipientStreet,
            recipient_number: recipientNumber,
            recipient_complement: recipientComplement,
            recipient_city: recipientCity,
            recipient_state: recipientState,
            recipient_district: recipientDistrict,
            recipient_cnpj: recipientCnpj,
            recipient_ie: recipientIe,
            recipient_rg: recipientIe,
            recipient_phone: recipientPhone,
            recipient_email: recipientEmail,
            shipping_fee: convertMoenyToInt(shippingFee),
            shipping_type: selectedShippingType,
            shipping_date: moment(shippingDate).format('YYYY-MM-DD'),
            shipping_consult: shippingConsult,
            payment_term_id: paymentTermId,
            payment_form_id: paymentFormId,
            comments,
        }).then(res => {
            toast.success(`Pedido emitido com sucesso`);
            navigate(`/order/edit/${res.data.resource.id}`);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const setRecipientData = () => {
        setSelectedRecipientType(quote.type);
        console.log(quote);
        setRecipientCpf(quote.customer.cpf);
        setRecipientName(quote.customer.name);

        if(quote.type === "PJ"){
            setRecipientCompanyName(quote.company.company_name);
            setRecipientTradeName(quote.company.trade_name);
        }

        setRecipientCnpj(quote.type === "PF" ? quote.customer.cpf : quote.company.cnpj);

        setRecipientPhone(quote.customer.phone);
        setRecipientEmail(quote.customer.email);

        setRecipientZipCode(quote.type === "PF" ? quote.customer.zip_code : quote.company.zip_code);
        setRecipientStreet(quote.type === "PF" ? quote.customer.street : quote.company.street);
        setRecipientNumber(quote.type === "PF" ? quote.customer.number : quote.company.number);
        setRecipientComplement(quote.type === "PF" ? quote.customer.complement : quote.company.complement);
        setRecipientCity(quote.type === "PF" ? quote.customer.city : quote.company.city);
        setRecipientState(quote.type === "PF" ? quote.customer.state : quote.company.state);
        setRecipientDistrict(quote.type === "PF" ? quote.customer.district : quote.company.district);
        
    }

    return (
        <>
            <PanelTemplate id="order-create">
                <Box
                    title={`Dados do pedido`}
                >
                    {replace &&
                        <div className="row">
                            <div className="col-3">
                                <p>O pedido com o código informado abaixo <b>será removido</b> e substituído por este que você está criando.</p>
                                <br />
                            </div>
                        </div>
                    }
                    <div className="row">
                        {replace &&
                            <div className="col-3">
                                <Input
                                    label={`Código do pedido`} 
                                    value={replaceCode}
                                    change={setReplaceCode}
                                    mask={'convertToInt'}
                                    placeholder={`Ex: 120`}
                                />
                            </div>
                        }
                        {hasPermission('replace_order') &&
                            <div className="col-9" style={{ display: 'flex', alignItems: 'center'}}>
                                <InputCheckboxUnique checked={replace} change={setReplace} label={`Substituir pedido existente`} />
                            </div>
                        }
                    </div>
                    <div className="row">
                        <div className="col-3" >
                            <Input label={`Código do orçamento`} value={quote?.code} disabled={true} />
                            <SelectPaymentForm 
                                selectedPaymentForm={paymentFormId}
                                setSelectedPaymentForm={setPaymentFormId}
                            />
                            <SelectPaymentTerm 
                                selectedPaymentTerm={paymentTermId}
                                setSelectedPaymentTerm={setPaymentTermId}
                            />
                        </div>
                        
                        <div className="col-9">
                            <InputEditorTiny label={`Observações`} value={comments} change={setComments} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <InputSelect
                                label={`Tipo do frete`}
                                options={shippingTypes}
                                value={selectedShippingType}
                                change={setSelectedShippingType}
                            />
                        </div>
                        <div className="col-3">
                            <Input 
                                label={`Valor do frete`}
                                value={shippingConsult ? '' : shippingFee}
                                change={setShippingFee}
                                mask={`convertToMoney`}
                                disabled={shippingConsult}
                            />
                            <div style={{ marginTop: -10 }}>
                                <InputCheckboxUnique checked={shippingConsult} change={setShippingConsult} label={`A consultar`} />
                            </div>
                        </div>
                        <div className="col-3">
                            <InputDate 
                                label={`Prazo de entrega`}
                                value={shippingDate}
                                change={setShippingDate}
                            />
                        </div>
                    </div>
                </Box>
                <Box
                    title={`Endereço de entrega`}
                >
                    {!quote?.shipping_street &&
                        <div className="row">
                            <div className="col-3">
                                {quote?.street}
                                <Button 
                                    full={true}
                                    type={`secondary`} 
                                    size={`regular`} 
                                    text={`Preencher do cliente`}
                                    action={setRecipientData}
                                    />
                                <br />
                                <br />
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-3">
                            <InputSelect label={`Tipo de recebdor`} options={recipientTypes} value={selectedRecipientType} change={setSelectedRecipientType} />
                        </div>
                    </div>
                    {selectedRecipientType === "PJ" &&
                        <div className="row">
                            <div className="col-3">
                                <Input type={`text`} label={`CNPJ`} value={recipientCnpj} change={setRecipientCnpj} mask={`convertToCnpj`} />
                            </div>
                            <div className="col-4">
                                <Input type={`text`} label={`Nome fantasia`} value={recipientTradeName} change={setRecipientTradeName} />
                            </div>
                            <div className="col-4">
                                <Input type={`text`} label={`Razão social`} value={recipientCompanyName} change={setRecipientCompanyName} />
                            </div>
                        </div>
                    }
                    {selectedRecipientType === "PF" &&
                        <div className="row">
                            <div className="col-3">
                                <Input type={`text`} label={`CPF`} value={recipientCpf} change={setRecipientCpf} mask={`convertToCpf`} />
                            </div>
                            <div className="col-4">
                                <Input type={`text`} label={`Nome`} value={recipientName} change={setRecipientName} />
                            </div>
                        </div>
                    }
                    {selectedRecipientType !== null &&
                    <>
                    <div className="row">
                        <div className="col-3">
                            <Input type={`text`} label={`Telefone`} value={recipientPhone} change={setRecipientPhone} mask={`convertToPhone`} />
                        </div>
                        <div className="col-4">
                            <Input type={`text`} label={`E-mail`} value={recipientEmail} change={setRecipientEmail} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <Input type={`text`} label={`CEP`} value={recipientZipCode} change={setRecipientZipCode} mask={`convertToCep`} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`Logradouro`} value={recipientStreet} change={setRecipientStreet} />
                        </div>
                        <div className="col-3">
                            <Input type={`text`} label={`Número`} value={recipientNumber} change={setRecipientNumber} />
                        </div>
                        <div className="col-3">
                            <Input type={`text`} label={`Complemento`} value={recipientComplement} change={setRecipientComplement} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <InputSelect label={`Estado`} options={states} value={recipientState} change={setRecipientState} />
                        </div>
                        <div className="col-3">
                            <Input type={`text`} label={`Cidade`} value={recipientCity} change={setRecipientCity} />
                        </div>
                        <div className="col-3">
                            <Input type={`text`} label={`Bairro`} value={recipientDistrict} change={setRecipientDistrict} />
                        </div>
                    </div>
                    </>
                    }
                </Box>
                <PageFooter>
                    <Button type={`primary`} size={`small`} svg={`save-white`} text={`Emitir pedido`} action={store} loading={loading} />
                </PageFooter>
            </PanelTemplate>
        </>
    );
}

export default OrderCreate;