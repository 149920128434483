import React, { useEffect, useState } from "react";
import PanelTemplate from "../../templates/Panel";
import PageHeader from "../../components/PageHeader";
import Button from "../../components/Button";
import Box from "../../components/Box";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import InputDate from "../../components/InputDate";
import InputTextarea from "../../components/InputTextarea";
import DatePicker from "react-date-picker";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import moment from "moment";
import { convertIntToMoney, convertMoenyToInt } from "../../helpers/conversions";
import SelectCompany from "../../components/selects/SelectCompany";
import SelectBillingCompany from "../../components/selects/SelectBillingCompany";
import SelectCaixa from "../../components/selects/SelectCaixa";
import SelectContaPagarCategoria from "../../components/selects/SelectContaPagarCategoria";
import SelectPaymentForm from "../../components/selects/SelectPaymentForm";
import { toast } from "react-toastify";
import { ModalDelete } from "../../components/ModalDelete";
import { Link } from "react-router-dom";
import FilterSimple from "../../components/FilterSimple";

const statuses = [
    {label: 'Pendente', value: 'pendente'},
    {label: 'Aprovada', value: 'aprovada'}
];

const ocorrencias = [
    {label: 'Única', value: 'unica'},
    {label: 'Semanal', value: 'semanal'},
    {label: 'Mensal', value: 'mensal'},
    {label: 'Bimestral', value: 'bimestral'},
    {label: 'Trimestral', value: 'trimestral'},
    {label: 'Semestral', value: 'semestral'},
    {label: 'Anual', value: 'anual'}
]

const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '35px',
      height: '35px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: '3px',
      borderBottomRightRadius: '3px',
      boxShadow: state.isFocused ? null : null,
      fontSize: '14px',
      width: 250
    }),
}

const headerDefault = ['Vencimento', 'Fornecedor', 'Categoria', 'Nº documento', 'Valor', 'Situação'];

export const ContaPagar = () => {
    const today = new Date();
    const sDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const fDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const [filterStartDate, setFilterStartDate] = useState(sDate);
    const [filterFinalDate, setFilterFinalDate] = useState(fDate);
    const [filters, setFilters] = useState('');

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({header: headerDefault, rows: []});
    const [contas, setContas] = useState([]);

    const [totalResultados, setTotalResultados] = useState(0);
    const [valorResultados, setValorResultados] = useState(0);

    const [id, setId] = useState(null);
    const [selectedCaixa, setSelectedCaixa] = useState(null);
    const [selectedCategoria, setSelectedCategoria] = useState(null);
    const [selectedEmpresa, setSelectedEmpresa] = useState(null);
    const [selectedFornecedor, setSelectedFornecedor] = useState(null);
    const [selectedPaymentForm, setSelectedPaymentForm] = useState(null);
    const [selectedOcorrencia, setSelectedOcorrencia] = useState('unica');

    const [emissao, setEmissao] = useState(new Date());
    const [vencimento, setVencimento] = useState(today);
    const [nDoc, setNDoc] = useState('');
    const [valor, setValor] = useState('R$ 0,00');
    const [parcelas, setParcelas] = useState('');
    const [comments, setComments] = useState('');

    const [selectedIds, setSelectedIds] = useState([]);
    const [showConfirmMultipleDelete, setShowConfirmMultipleDelete] = useState(false);
    const [contaToDelete, setContaToDelete] = useState(null);

    const [pagamentos, setPagamentos] = useState([]);

    const [showEditConta, setShowEditConta] = useState(false);
    const [showCreateConta, setShowCreateConta] = useState(false);
    const [showCreatePagamento, setShowCreatePagamento] = useState(false);

    const [pagamentoData, setPagamentoData] = useState(today);
    const [pagamentoSelectedCaixa, setPagamentoSelectedCaixa] = useState(null);
    const [pagamentoJuros, setPagamentoJuros] = useState('R$ 0,00');
    const [pagamentoDesconto, setPagamentoDesconto] = useState('R$ 0,00');
    const [pagamentoAcrescimo, setPagamentoAcrescimo] = useState('R$ 0,00');
    const [pagamentoValor, setPagamentoValor] = useState('R$ 0,00');

    const [pagamentoToDelete, setPagamentoToDelete] = useState(null);

    const [status, setStatus] = useState('aprovada');
    const [pagamentoStatus, setPagamentoStatus] = useState('');
    const [pagamentoStatusTexto, setPagamentoStatusTexto] = useState('');
    const [total, setTotal] = useState('R$ 0,00');
    const [saldo, setSaldo] = useState('R$ 0,00');

    useEffect(() => {
        getContas();
    }, [filters, filterStartDate, filterFinalDate]);

    useEffect(() => {
        generateData();
    }, [contas]);

    useEffect(() => {
        if(!showCreateConta) clean();
    }, [showCreateConta]);

    useEffect(() => {
        if(!showEditConta) clean();
    }, [showEditConta]);

    useEffect(() => {
        updateValorPagamentos();
    }, [pagamentoJuros, pagamentoDesconto, pagamentoAcrescimo]);

    const updateValorPagamentos = () => {
        let juros = convertMoenyToInt(pagamentoJuros);
        let desconto = convertMoenyToInt(pagamentoDesconto);
        let acrescimos = convertMoenyToInt(pagamentoAcrescimo);
        
        let toPagamentoValor = juros - desconto + acrescimos + convertMoenyToInt(saldo);
        toPagamentoValor = convertIntToMoney(toPagamentoValor, true);
        setPagamentoValor(toPagamentoValor)
    }

    const getContas = () => {
        setLoading(true);

        let args = `?filters[0][vencimento][bigger_or_equal]=${moment(filterStartDate).format('YYYY-MM-DD')}`;
        args += `&filters[0][vencimento][less_or_equal]=${moment(filterFinalDate).format('YYYY-MM-DD')}`;
        args += `&${filters}`;

        api.get(`/conta-pagar${args}`).then(res => {
            setContas(res.data.resources);
            setTotalResultados(res.data.total);
            setValorResultados(res.data.valor);
        }).catch(err => {
            renderError(err);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Vencimento', 'Fornecedor', 'Categoria', 'Pedido', 'Valor', 'Status', 'Pagamento'];
        const rows = [];
        
        contas.map(conta => {
            rows.push({
                data: [
                    moment(conta.vencimento).format('DD/MM/YYYY'),
                    conta?.fornecedor?.nome,
                    conta?.categoria?.nome,
                    conta?.order ? <Link target={`_blank`} to={`/order/edit/${conta.order.id}`}>{conta.order.code}</Link> : '',
                    convertIntToMoney(conta.total, true),
                    <span className={`conta-status ${conta.status}`}>{conta.status}</span>,
                    <span className={`conta-pagamento-status ${conta.pagamento_status}`}>{conta.pagamento_status_texto}</span>
                ],
                action: () => editConta(conta),
                id: conta.id
            });
        });

        setData({header, rows});
    }

    const editConta = conta => {
        setId(conta.id);
        setSelectedFornecedor(conta?.fornecedor?.id ?? null);
        setSelectedEmpresa(conta?.empresa?.id ?? null);
        setSelectedCaixa(conta?.caixa?.id ?? null);
        setSelectedCategoria(conta?.categoria?.id ?? null);
        setEmissao(conta.emissao ? new Date(`${conta.emissao} 00:00:00`) : null);
        setNDoc(conta?.n_doc ?? '');
        setValor(convertIntToMoney(conta.valor, true));
        setSelectedPaymentForm(conta?.payment_form?.id ?? null);
        setComments(conta.comments ?? '');
        setVencimento(new Date(`${conta.vencimento} 00:00:00`));
        setShowEditConta(true);
        setPagamentos(conta?.pagamentos ?? []);

        setTotal(convertIntToMoney(conta.total, true));
        setSaldo(convertIntToMoney(conta.saldo, true));
        setStatus(conta.status);
        setPagamentoStatus(conta.pagamento_status);
        setPagamentoStatusTexto(conta.pagamento_status_texto);
    }

    const storeConta = () => {
        setLoading(true);

        api.post(`/conta-pagar`, {
            status,
            fornecedor_id: selectedFornecedor,
            empresa_id: selectedEmpresa,
            caixa_id: selectedCaixa,
            conta_pagar_categoria_id: selectedCategoria,
            emissao: emissao ? moment(emissao).format('YYYY-MM-DD') : null,
            n_doc: nDoc,
            valor: convertMoenyToInt(valor),
            payment_form_id: selectedPaymentForm,
            comments,
            ocorrencia: selectedOcorrencia,
            vencimento: moment(vencimento).format('YYYY-MM-DD'),
            parcelas: parcelas ? parseInt(parcelas) : null
        }).then(() => {
            toast.success('Conta cadastrada com sucesso');
            setShowCreateConta(false);
            getContas();
        }).catch(err => {
            renderError(err);
            setLoading(false);
        });
    }

    const updateConta = () => {
        setLoading(true);

        api.put(`/conta-pagar/${id}`, {
            status,
            fornecedor_id: selectedFornecedor,
            empresa_id: selectedEmpresa,
            caixa_id: selectedCaixa,
            conta_pagar_categoria_id: selectedCategoria,
            emissao: emissao ? moment(emissao).format('YYYY-MM-DD') : null,
            n_doc: nDoc,
            valor: convertMoenyToInt(valor),
            payment_form_id: selectedPaymentForm,
            comments,
            vencimento: moment(vencimento).format('YYYY-MM-DD')
        }).then(() => {
            toast.success('Conta atualizada com sucesso');
            setShowEditConta(false);
            getContas();
        }).catch(err => {
            renderError(err);
            setLoading(false);
        });
    }

    const clean = () => {
        setId(null);
        setSelectedFornecedor(null);
        setSelectedEmpresa(null);
        setSelectedCaixa(null);
        setSelectedCategoria(null);
        setEmissao(new Date());
        setNDoc('');
        setValor('R$ 0,00');
        setSelectedPaymentForm(null);
        setComments('');
        setSelectedOcorrencia('unica');
        setVencimento(new Date());
        setParcelas('');
    }

    const deleteConta = () => {
        setLoading(true);

        api.delete(`/conta-pagar/${contaToDelete}`).then(res => {
            setContaToDelete(null);
            setShowEditConta(false);
            getContas();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const deleteMultipleContas = () => {
        setLoading(true);

        api.delete(`/conta-pagar/multiple`, {
            data: {
                ids: selectedIds
            }
        }).then(res => {
            toast.success('Contas apagadas');
            setSelectedIds([]);
            getContas();
            setShowConfirmMultipleDelete(false);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const getContaEmEdicao = () => {
        setLoading(true);

        api.get(`/conta-pagar/${id}`).then(res => {
            editConta(res.data.resource);
            getContas();
        }).catch(err => {
            renderError(err);
            setLoading(false);
        });
    }

    const deletePagamento = () => {
        setLoading(true);

        api.delete(`/conta-pagar/pagamento/${pagamentoToDelete}`).then(res => {
            toast.success('Pagamento excluído com sucesso');
            setPagamentoToDelete(null);
            getContaEmEdicao();
        }).catch(err => {
            renderError(err);
            setLoading(false);
        });
    }

    const storePagamento = () => {
        setLoading(true);

        api.post(`/conta-pagar/pagamento`, {
            conta_pagar_id: id,
            caixa_id: pagamentoSelectedCaixa,
            data: moment(pagamentoData).format('YYYY-MM-DD'),
            juros: convertMoenyToInt(pagamentoJuros),
            desconto: convertMoenyToInt(pagamentoDesconto),
            acrescimo: convertMoenyToInt(pagamentoAcrescimo),
            valor: convertMoenyToInt(pagamentoValor)
        }).then(res => {
            toast.success('Pagamento registrado com sucesso');
            getContaEmEdicao();
            setShowCreatePagamento(false);
        }).catch(err => {
            renderError(err);
            setLoading(false);
        });
    }

    const handleSetFilter = value => {
        // setCurrentPage(1);
        setFilters(value);
    }

    const Pagamentos = () => {
        const header = ['Data', 'Juros', 'Desconto', 'Acréscimo', 'Valor', 'Conta', ''];
        const [rows, setRows] = useState([]);

        useEffect(() => {
            getRows();
        }, [pagamentos]);

        const getRows = () => {
            let toRows = [];

            pagamentos.map(pagamento => {
                toRows.push({
                    data: [
                        moment(pagamento.data).format('DD/MM/YYYY'),
                        convertIntToMoney(pagamento.juros, true),
                        convertIntToMoney(pagamento.desconto, true),
                        convertIntToMoney(pagamento.acrescimo, true),
                        convertIntToMoney(pagamento.valor, true),
                        pagamento?.caixa?.nome
                    ],
                    buttons: [
                        <Button type={`delete`} svg={`delete`} action={() => setPagamentoToDelete(pagamento.id)} />
                    ]
                });
            });

            setRows(toRows);
        }

        const editPagamento = () => {
            setPagamentoValor(saldo);
            setPagamentoData(vencimento);
            setPagamentoSelectedCaixa(selectedCaixa);
            setShowCreatePagamento(true);
        }

        return (
            <>
                <div className="section" style={{ padding: 0}}>
                    <Table
                        header={['Status', 'Pagamento', 'Valor', 'Saldo']}
                        rows={[{data: [
                            <span className={`conta-status ${status}`}>{status}</span>,
                            <span className={`conta-pagamento-status ${pagamentoStatus}`}>{pagamentoStatusTexto}</span>,
                            total, 
                            saldo
                        ]}]}
                        />
                </div>
                <div className="modal-section-title">
                    <div className="left">
                        <h2>Pagamentos realizados</h2>
                    </div>
                </div>

                <Button type={`secondary`} size={`small`} text={`Cadastrar pagamento`} style={{ marginBottom: 15 }} action={editPagamento} />
                
                <div className="section" style={{ padding: 0}}>
                    <Table
                        header={header}
                        rows={rows}
                        />
                </div>
            </>
        );
    }

    return (
        <>
            <Modal
                title={`Cadastrar conta`}
                show={showCreateConta}
                close={() => setShowCreateConta(false)}
                footer={
                    <>
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={`Salvar alterações`}
                            svg={`save-white`}
                            action={storeConta}
                            loading={loading} 
                        />
                    </>
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <InputSelect 
                                options={statuses}
                                label={`Status`}
                                value={status}
                                change={setStatus}
                            />
                        </div>
                        <div className="col-6">
                            <SelectCompany
                                label={`Cliente ou fornecedor`}
                                selectedCompany={selectedFornecedor}
                                setSelectedCompany={setSelectedFornecedor}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <SelectBillingCompany
                                selectedBillingCompany={selectedEmpresa}
                                setSelectedBillingCompany={setSelectedEmpresa}
                            />
                        </div>
                        <div className="col-6">
                            <SelectCaixa
                                selectedCaixa={selectedCaixa}
                                setSelectedCaixa={setSelectedCaixa}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <SelectContaPagarCategoria
                                selectedContaPagarCategoria={selectedCategoria}
                                setSelectedContaPagarCategoria={setSelectedCategoria}
                            />
                        </div>
                        <div className="col-6">
                            <SelectPaymentForm
                                selectedPaymentForm={selectedPaymentForm}
                                setSelectedPaymentForm={setSelectedPaymentForm}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Input 
                                label={`Nº documento`} 
                                value={nDoc}
                                change={setNDoc}
                            />
                        </div>
                        <div className="col-6">
                            <InputDate 
                                value={emissao}
                                change={setEmissao}
                                label={`Emissão`}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <InputSelect label={`Ocorrência *`} options={ocorrencias} value={selectedOcorrencia} change={setSelectedOcorrencia} />
                        </div>
                        {selectedOcorrencia !== 'unica' &&
                            <div className="col-6">
                                <Input 
                                    label={`Quant. de parcelas`} 
                                    type={`text`} 
                                    mask={`convertToInt`}
                                    value={parcelas}
                                    change={setParcelas}
                                />
                            </div>
                        }
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Input 
                                label={`Valor *`}
                                mask={`convertToMoney`}
                                value={valor}
                                change={setValor}
                            />
                        </div>
                        <div className="col-6">
                            <InputDate 
                                value={vencimento}
                                change={setVencimento}
                                label={selectedOcorrencia === 'unica' ? `Vencimento *` : `Primeiro vencimento *`}
                            />
                        </div>
                    </div>
                    <InputTextarea label={`Observações`} value={comments} change={setComments} />
                </div>
            </Modal>
            <Modal
                title={`Editar conta`}
                size={`big`}
                show={showEditConta}
                close={() => setShowEditConta(false)}
                footer={
                    <>
                        <Button
                            type={`secondary`}
                            size={`small`}
                            text={`Excluir`}
                            svg={`delete-small`}
                            action={() => setContaToDelete(id)}
                            loading={loading} 
                        />
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={`Salvar alterações`}
                            svg={`save-white`}
                            action={updateConta}
                            loading={loading} 
                        />
                    </>
                }
            >
                <Pagamentos />
                <div className="modal-section-title"><div className="left"><h2>Detalhes da conta</h2></div></div>
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <InputSelect 
                                options={statuses}
                                label={`Status`}
                                value={status}
                                change={setStatus}
                            />
                        </div>
                        <div className="col-6">
                            <SelectCompany
                                label={`Cliente ou fornecedor`}
                                selectedCompany={selectedFornecedor}
                                setSelectedCompany={setSelectedFornecedor}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <SelectBillingCompany
                                selectedBillingCompany={selectedEmpresa}
                                setSelectedBillingCompany={setSelectedEmpresa}
                            />
                        </div>
                        <div className="col-6">
                            <SelectCaixa
                                selectedCaixa={selectedCaixa}
                                setSelectedCaixa={setSelectedCaixa}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <SelectContaPagarCategoria
                                selectedContaPagarCategoria={selectedCategoria}
                                setSelectedContaPagarCategoria={setSelectedCategoria}
                            />
                        </div>
                        <div className="col-6">
                            <SelectPaymentForm
                                selectedPaymentForm={selectedPaymentForm}
                                setSelectedPaymentForm={setSelectedPaymentForm}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Input 
                                label={`Nº documento`} 
                                value={nDoc}
                                change={setNDoc}
                            />
                        </div>
                        <div className="col-6">
                            <InputDate 
                                value={emissao}
                                change={setEmissao}
                                label={`Emissão`}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Input 
                                label={`Valor *`}
                                mask={`convertToMoney`}
                                value={valor}
                                change={setValor}
                            />
                        </div>
                        <div className="col-6">
                            <InputDate 
                                value={vencimento}
                                change={setVencimento}
                                label={`Vencimento *`}
                            />
                        </div>
                    </div>
                    <InputTextarea label={`Observações`} value={comments} change={setComments} />
                </div>
            </Modal>
            <Modal
                title={`Cadastrar pagamento`}
                size={`big`}
                show={showCreatePagamento}
                close={() => setShowCreatePagamento(false)}
                footer={
                    <>
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={`Salvar alterações`}
                            svg={`save-white`}
                            action={storePagamento}
                            loading={loading} 
                        />
                    </>
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-3">
                            <Input 
                                label={`Vencimento`}
                                value={moment(vencimento).format('DD/MM/YYYY')}
                                disabled={true}
                            />
                        </div>
                        <div className="col-3">
                            <Input
                                label={`Saldo`}
                                value={saldo}
                                disabled={true}
                            />
                        </div>
                        <div className="col-3">
                            <InputDate 
                                label={`Data do pagamento`}
                                value={pagamentoData}
                                change={setPagamentoData}
                            />
                        </div>
                        <div className="col-3">
                            <SelectCaixa 
                                selectedCaixa={pagamentoSelectedCaixa}
                                setSelectedCaixa={setPagamentoSelectedCaixa}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Input
                                label={`Juros`}
                                value={pagamentoJuros}
                                change={setPagamentoJuros}
                                mask={`convertToMoney`}
                            />
                        </div>
                        <div className="col">
                            <Input
                                label={`Desconto`}
                                value={pagamentoDesconto}
                                change={setPagamentoDesconto}
                                mask={`convertToMoney`}
                            />
                        </div>
                        <div className="col">
                            <Input
                                label={`Acréscimos`}
                                value={pagamentoAcrescimo}
                                change={setPagamentoAcrescimo}
                                mask={`convertToMoney`}
                            />
                        </div>
                        <div className="col">
                            <Input
                                label={`Valor`}
                                value={pagamentoValor}
                                change={setPagamentoValor}
                                mask={`convertToMoney`}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            <ModalDelete
                show={showConfirmMultipleDelete}
                text={<p>Tem certeza que deseja apagar <strong>{selectedIds.length} {selectedIds.length > 1 ? 'contas' : 'conta'}</strong>?</p>}
                action={deleteMultipleContas}
                loading={loading}
                close={() => setShowConfirmMultipleDelete(false)}
            />
            <ModalDelete
                show={contaToDelete !== null}
                action={deleteConta}
                loading={loading}
                close={() => setContaToDelete(null)}
            />
            <ModalDelete
                show={pagamentoToDelete !== null}
                action={deletePagamento}
                loading={loading}
                close={() => setPagamentoToDelete(null)}
            />
            <PanelTemplate>
                <PageHeader
                    left={
                        <>
                            <FilterSimple
                                params={[
                                    {name: 'Fornecedor ou cliente', key: '[related][fornecedor.trade_name]', type: 'text' },
                                    {name: 'Status', key: '[status]', type: 'text' },
                                    {name: 'Código do pedido', key: '[related][order.code]', type: 'text' },
                                ]}
                                filter={handleSetFilter}
                            />
                            <span>Início:</span>
                            <DatePicker 
                                className={`date-filter`}
                                format="dd/MM/y"
                                onChange={setFilterStartDate}
                                value={filterStartDate}
                            />
                            <span>Fim:</span>
                            <DatePicker 
                                className={`date-filter`}
                                format="dd/MM/y"
                                onChange={setFilterFinalDate}
                                value={filterFinalDate}
                            />
                        </>
                    }

                    right={
                        <>
                            {selectedIds.length > 0 &&
                                <Button
                                    type={`secondary`} 
                                    size={`small`} 
                                    svg={`delete`}
                                    text={`Excluir ${selectedIds.length} selecionados`} 
                                    action={() => setShowConfirmMultipleDelete(true)}  
                                />
                            }
                            <Button type={`primary`} size={`small`} text={`Cadastrar conta a pagar`} svg="plus-white" action={() => setShowCreateConta(true)}  />
                        </>
                    }
                />
                <div className="row-caixa">
                    <div className="col-caixa-list">
                        <Box className={`no-padding with-pagination with-filter-inline`}>
                            <Table 
                                header={data.header}
                                rows={data.rows}
                                checks={false}
                                loading={loading}
                                selectedIds={selectedIds}
                                setSelectedIds={setSelectedIds}
                            />
                        </Box>
                    </div>
                    <div className="col-caixa-details">
                        <h2>Informações do período</h2>
                        <div className="detail">
                            <span className="title">Quantidade de contas a pagar</span>
                            <span className="value green">{totalResultados}</span>
                        </div>
                        <div className="detail">
                            <span className="title">Valor total</span>
                            <span className="value green">{convertIntToMoney(valorResultados, true)}</span>
                        </div>
                        {/* <h2>Outras opções</h2>
                        <ul>
                            <li><a href="#">Editar categorias</a></li>
                        </ul> */}
                    </div>
                </div>
            </PanelTemplate>
        </>
    );
}

export default ContaPagar;