import React from "react";
import Box from "../../Box";
import { TableProductStatus } from "../../TableProductStatus";
import moment from "moment";

export const DashboardPedidos = ({orders, loading}) => {
    return (
        <div className="dashboard-pedidos">
            {orders.map((order, index) => (
                <div key={index} className="os">
                    {/* <div className="os-details">
                        <table>
                            <tr>
                                <th>OS</th>
                                <th>Prazo de entrega</th>
                                <th>Consultor</th>
                                <th>Cliente</th>
                            </tr>
                            <tr>
                                <td><a href={`/order/edit/${order.id}`}>{order.code}</a></td>
                                <td>{moment(order.shipping_date).format('DD/MM/YYYY')}</td>
                                <td>{order.admin_name ?? ''}</td>
                                <td>{order.type === 'PF' ? order.customer_name : order.customer_company_trade_name}</td>
                            </tr>
                        </table>
                    </div> */}
                    {order.items.map((item, index) => (
                        <TableProductStatus key={index} showProduct={true} item={item} id={order.id} code={order.code} />
                    ))}
                </div>
            ))}
        </div>
    );
}

export default DashboardPedidos;