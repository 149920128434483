import React, { useEffect, useState } from "react";

import PanelTemplate from "../../../templates/Panel";
import Table from "../../../components/Table";
import Box from "../../../components/Box";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { renderError } from "../../../helpers/errors";
import moment from "moment";
import PageHeader from "../../../components/PageHeader";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import Input from "../../../components/Input";
import InputFile from "../../../components/InputFile";
import InputSelect from "../../../components/InputSelect";
import { toast } from "react-toastify";
import BoxTabs from "../../../components/BoxTabs";
import InputTextarea from "../../../components/InputTextarea";
import InputCheckbox from "../../../components/InputCheckbox";
import InputCheckboxUnique from "../../../components/InputCheckboxUnique";
import { ModalDelete } from "../../../components/ModalDelete";
import { createOptions } from "../../../helpers/selects";

const roleOptions = [
    { label: 'Administrador', value: '9aa99173-078f-4979-a797-faf1b561a444' },
    { label: 'Consultor', value: 'a193ab00-f097-4e7f-be45-0f590dcddfc1' }
];

const RelatorioComercial = () => {
    const navigate = useNavigate();

    const [data, setData] = useState({header: [], rows: []});

    useEffect(() => {
        generateData();
    }, []);

    const generateData = () => {
        const header = ['Nome do relatório'];
        const rows = [
            {
                data: ['Histórico de clientes geral'],
                action: () => window.open(`${process.env.REACT_APP_URL}/company/export`)
            },
            {
                data: ['Histórico de clientes por vendedor'],
                action: () => window.open(`${process.env.REACT_APP_URL}/company/admin/export`)
            }
        ];
        
        setData({header, rows});
    }

    return (
        <>
            <PanelTemplate id="relatorio-comercial">
                <Box className={`no-padding`}>
                    <Table header={data.header} rows={data.rows} />
                </Box>
            </PanelTemplate>
        </>
    );
}

export default RelatorioComercial;