import React, { useEffect, useState } from "react";
import { renderError } from "../../helpers/errors";
import api from "../../services/api";

export const TableProductStatus = ({showProduct, item, code, id}) => {
    const [gettingStatuses, setGettingStatuses] = useState(true);

    const [statusesTarefas, setStatusesTarefas] = useState([]);
    const [statusesDatas, setStatusesDatas] = useState([]);
    const [statusesCompras, setStatusesCompras] = useState([]);
    const [statusesControleCompras, setStatusesControleCompras] = useState([]);
    const [statusesNotaFiscal, setStatusesNotaFiscal] = useState([]);
    const [statusesArte, setStatusesArte] = useState([]);
    const [statusesGravacao, setStatusesGravacao] = useState([]);
    const [statusesProducao, setStatusesProducao] = useState([]);

    const [statusTarefasId, setStatusTarefasId] = useState(null);
    const [statusDatasId, setStatusDatasId] = useState(null);
    const [statusComprasId, setStatusComprasId] = useState(null);
    const [statusControleComprasId, setStatusControleComprasId] = useState(null);
    const [statusNotaFiscalId, setStatusNotaFiscalId] = useState(null);
    const [statusArteId, setStatusArteId] = useState(null);
    const [statusGravacaoId, setStatusGravacaoId] = useState(null);
    const [statusProducaoId, setStatusProducaoId] = useState(null);

    useEffect(() => {
        getStatuses();
        setStatusIds();
    }, []);

    const setStatusIds = () => {
        setStatusTarefasId(item?.status_tarefas_id ?? null);
        setStatusDatasId(item?.status_datas_id ?? null);
        setStatusComprasId(item?.status_compras_id ?? null);
        setStatusControleComprasId(item?.status_controle_compras_id ?? null);
        setStatusNotaFiscalId(item?.status_nota_fiscal_id ?? null);
        setStatusArteId(item?.status_arte_id ?? null);
        setStatusGravacaoId(item?.status_gravacao_id ?? null);
        setStatusProducaoId(item?.status_producao_id ?? null);
    }

    const updateStatus = params => {
        api.put(`/order/item/${item.id}/status`, {
            ...params
        }).catch(error => {
            renderError(error);
        });
    }

    const getStatuses = () => {
        setGettingStatuses(true);

        api.get(`/status`).then(res => {
            let statuses = res.data.resources;
            setStatusesTarefas(getStatusOptions(statuses, 'pedido_tarefas'));
            setStatusesDatas(getStatusOptions(statuses, 'pedido_datas'));
            setStatusesCompras(getStatusOptions(statuses, 'pedido_compras'));
            setStatusesControleCompras(getStatusOptions(statuses, 'pedido_controle_compras'));
            setStatusesNotaFiscal(getStatusOptions(statuses, 'pedido_nota_fiscal'));
            setStatusesArte(getStatusOptions(statuses, 'pedido_arte'));
            setStatusesGravacao(getStatusOptions(statuses, 'pedido_gravacao'));
            setStatusesProducao(getStatusOptions(statuses, 'pedido_producao'));
        }).catch(error => {
            renderError(error);
        }).then(() => {
            setGettingStatuses(false);
        });
    }

    const getStatusOptions = (statuses, type) => {
        let options = [];
        statuses.filter(status => status.type === type).map(status => {
            options.push({
                label:  status.name,
                value: status.id,
                color: status.color
            });
        });

        return options;
    }

    const getStatusColor = (statuses, statusId) => {
        let selectedStatus = statuses.filter(status => status.value === statusId);
        
        if(selectedStatus.length > 0){
            return selectedStatus[0].color;
        }

        return 'transparent';
    }

    const handleStatusChange = e => {
        let value = e.target.value;
        let name = e.target.name;
        let params = {};

        params[name] = value;

        updateStatus(params);

        switch (name) {
            case 'status_tarefas_id':
                setStatusTarefasId(value);
                break;
            case 'status_datas_id':
                setStatusDatasId(value);
                break;
            case 'status_compras_id':
                setStatusComprasId(value);
                break;
            case 'status_controle_compras_id':
                setStatusControleComprasId(value);
                break;
            case 'status_nota_fiscal_id':
                setStatusNotaFiscalId(value);
                break;
            case 'status_arte_id':
                setStatusArteId(value);
                break;
            case 'status_gravacao_id':
                setStatusGravacaoId(value);
                break;
            case 'status_producao_id':
                setStatusProducaoId(value);
                break;
        }
    }

    return (
        <div className="product-statuses">
            <table>
                <thead>
                    <tr>
                        <th style={{ width: 40 }}>OS</th>
                        {showProduct &&
                            <th></th>
                        }
                        {/* <th>Tarefas</th> */}
                        {/* <th>Datas</th> */}
                        <th>Compras</th>
                        <th>Controle compras</th>
                        <th>Nota fiscal</th>
                        <th>Arte</th>
                        <th>Gravação</th>
                        <th>Produção</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ width: 30 }}><a target="_blank" href={`/order/edit/${id}`}>{code}</a></td>
                        {showProduct &&
                            <td className="td-produto">
                                {item.subproduct_image !== null &&
                                    <img src={item.subproduct_image_url} />
                                }
                                {item.subproduct_image === null &&
                                    <img src={ require(`../../assets/images/svgs/default.svg`).default } />
                                }
                                <p>{item.product_name}</p>
                            </td>
                        }

                        {/* <td className="td-status">
                            <select
                                name="status_tarefas_id"
                                value={statusTarefasId}
                                onChange={handleStatusChange}
                                style={{ backgroundColor: getStatusColor(statusesTarefas, statusTarefasId)}}
                            >
                                <option>Selecione...</option>
                                {statusesTarefas.map((status, index) => (
                                    <option key={index} value={status.value}>{status.label}</option>
                                ))}
                            </select>
                        </td> */}
                        {/* <td className="td-status">
                            <select
                                name="status_datas_id"
                                value={statusDatasId}
                                onChange={handleStatusChange}
                                style={{ backgroundColor: getStatusColor(statusesDatas, statusDatasId)}}
                            >
                                <option>Selecione...</option>
                                {statusesDatas.map((status, index) => (
                                    <option key={index} value={status.value}>{status.label}</option>
                                ))}
                            </select>
                        </td> */}
                        <td className="td-status">
                            <select
                                name="status_compras_id"
                                value={statusComprasId}
                                onChange={handleStatusChange}
                                style={{ backgroundColor: getStatusColor(statusesCompras, statusComprasId)}}
                            >
                                {/* <option>Selecione...</option> */}
                                {statusesCompras.map((status, index) => (
                                    <option key={index} value={status.value}>{status.label}</option>
                                ))}
                            </select>
                        </td>
                        <td className="td-status">
                            <select
                                name="status_controle_compras_id"
                                value={statusControleComprasId}
                                onChange={handleStatusChange}
                                style={{ backgroundColor: getStatusColor(statusesControleCompras, statusControleComprasId)}}
                            >
                                {/* <option>Selecione...</option> */}
                                {statusesControleCompras.map((status, index) => (
                                    <option key={index} value={status.value}>{status.label}</option>
                                ))}
                            </select>
                        </td>
                        <td className="td-status">
                            <select
                                name="status_nota_fiscal_id"
                                value={statusNotaFiscalId}
                                onChange={handleStatusChange}
                                style={{ backgroundColor: getStatusColor(statusesNotaFiscal, statusNotaFiscalId)}}
                            >
                                {/* <option>Selecione...</option> */}
                                {statusesNotaFiscal.map((status, index) => (
                                    <option key={index} value={status.value}>{status.label}</option>
                                ))}
                            </select>
                        </td>
                        <td className="td-status">
                            <select
                                name="status_arte_id"
                                value={statusArteId}
                                onChange={handleStatusChange}
                                style={{ backgroundColor: getStatusColor(statusesArte, statusArteId)}}
                            >
                                {/* <option>Selecione...</option> */}
                                {statusesArte.map((status, index) => (
                                    <option key={index} value={status.value}>{status.label}</option>
                                ))}
                            </select>
                        </td>
                        <td className="td-status">
                            <select
                                name="status_gravacao_id"
                                value={statusGravacaoId}
                                onChange={handleStatusChange}
                                style={{ backgroundColor: getStatusColor(statusesGravacao, statusGravacaoId)}}
                            >
                                {/* <option>Selecione...</option> */}
                                {statusesGravacao.map((status, index) => (
                                    <option key={index} value={status.value}>{status.label}</option>
                                ))}
                            </select>
                        </td>
                        <td className="td-status">
                            <select
                                name="status_producao_id"
                                value={statusProducaoId}
                                onChange={handleStatusChange}
                                style={{ backgroundColor: getStatusColor(statusesProducao, statusProducaoId)}}
                            >
                                {/* <option>Selecione...</option> */}
                                {statusesProducao.map((status, index) => (
                                    <option key={index} value={status.value}>{status.label}</option>
                                ))}
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}