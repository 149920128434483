import moment from "moment";
import React, { useEffect, useState } from "react";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Modal from "../../../components/Modal";
import Table from "../../../components/Table";
import { convertIntToMoney } from "../../../helpers/conversions";
import { renderError } from "../../../helpers/errors";
import api from "../../../services/api";

const LogTab = ({order}) => {
    const [data, setData] = useState({rows: [], header:[]});

    useEffect(() => {
        generateData();
    }, [order]);

    const generateData = () => {
        const header = ['Data da alteração', 'Descrição'];
        const rows = [];
        
        order.logs.map(log => {
            rows.push({
                data: [
                    moment(log.created_at).format('DD/MM/YYYY HH:mm'),
                    log.description
                ]
            });
        });

        setData({header, rows});
    }

    return <Table rows={data.rows} header={data.header} />;
} 

export default LogTab;