import React, { useEffect, useState } from "react";
import { renderError } from "../../../helpers/errors";
import { createOptions } from "../../../helpers/selects";
import api from "../../../services/api";
import InputSelect from "../../InputSelect";

export const SelectCaixa = ({selectedCaixa, setSelectedCaixa, removeLabel}) => {
    const [loading, setLoading] = useState(true);
    const [caixas, setCaixas] = useState([]);

    useEffect(() => {
        getCaixas();
    }, []);

    const getCaixas = () => {
        setLoading(true);

        api.get(`/caixa`).then(res => {
            setCaixas(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => {
            setLoading(false);
        });
    }

    return (
        <InputSelect
            loading={loading}
            label={removeLabel === true ? '' : `Conta`}
            options={createOptions(caixas, 'nome', 'id')} 
            value={selectedCaixa} 
            change={setSelectedCaixa}
        />
    );
}

export default SelectCaixa;