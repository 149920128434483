import React, { useEffect, useState } from "react";
import Box from "../../components/Box";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Modal from "../../components/Modal";
import { ModalDelete } from "../../components/ModalDelete";
import PageHeader from "../../components/PageHeader";
import Table from "../../components/Table";
import PanelTemplate from "../../templates/Panel";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import { toast } from "react-toastify";
import { convertIntToPercentage, convertPercentageToInt } from "../../helpers/conversions";
import { convertToInt } from "../../helpers/masks";

const defaultInstallments = [
    {
        term: 1,
        interest: '0,0000 %'
    }
];

const InstallmentInputs = ({updateInstallment, quantity, installments}) => {
    let inputs = [];

    for (let i = 0; i < quantity; i++) {
        inputs.push(
            <div key={i} className="row">
                <div className="col-2"><br />{i + 1}ª</div>
                <div className="col-5">
                    <Input
                        value={installments[i] ? installments[i].term : ''}
                        change={value => updateInstallment(i, 'term', value)}
                        mask={`convertToInt`}
                    />
                </div>
                <div className="col-5">
                    <Input
                        value={installments[i] ? installments[i].interest : ''}
                        change={value => updateInstallment(i, 'interest', value)}
                        mask={`convertToPercentage`}
                    />
                </div>
            </div>
        );
    }

    return inputs;
}

export const PaymentTerm = () => {
    const [loading, setLoading] = useState(false);
    const [prazos, setPrazos] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [installments, setInstallments]  = useState(defaultInstallments);

    const [prazoToDelete, setPrazoToDelete] = useState(null);

    useEffect(() => {
        getPrazos();
    }, []);

    useEffect(() => {
        generateData();
    }, [prazos]);

    useEffect(() => {
        if(!showCreate) clean();
    }, [showCreate]);

    useEffect(() => {
        if(!showEdit) clean();
    }, [showEdit]);

    const getPrazos = () => {
        api.get('/paymentterm').then(res => {
            setPrazos(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Nome', 'Quant. de parcelas'];
        const rows = [];
        
        prazos.map(prazo => {
            rows.push({
                data: [
                    prazo.name,
                    prazo.installments.length,
                ],
                action: () => editPrazo(prazo)
            });
        });

        setData({header, rows});
    }

    const storePrazo = () => {
        setLoading(true);

        api.post(`/paymentterm`, {
            name,
            installments: convertInterestsToInteger(installments)
        }).then(res => {
            getPrazos();
            setShowCreate(false);
            toast.success('Prazo de pagamento cadastrado com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const editPrazo = prazo => {
        setId(prazo.id);
        setName(prazo.name);
        setInstallments(convertInterestsToString(prazo.installments));
        setQuantity(prazo.installments.length);
        setShowEdit(true);
    }

    const convertInterestsToString = installments => {
        console.log(installments);
        let newInstallments = [...installments];
        newInstallments.map((installment, index) => {
            installment.term = installment.term.toString();
            installment.interest = convertIntToPercentage(installment.interest);
        });

        return newInstallments;
    }

    const convertInterestsToInteger = installments => {
        let newInstallments = [...installments];

        newInstallments.map((installment, index) => {
            installment.term = parseInt(installment.term);
            installment.interest = convertPercentageToInt(installment.interest);
        });

        if(newInstallments.length > quantity){
            newInstallments = newInstallments.slice(0, quantity);
        }

        return newInstallments;
    }

    const updatePrazo = () => {
        setLoading(true);

        api.put(`/paymentterm/${id}`, {
            name,
            installments: convertInterestsToInteger(installments)
        }).then(res => {
            getPrazos();
            setShowEdit(false);
            toast.success('Prazo de pagamento atualizado com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const clean = () => {
        setId('');
        setName('');
        setInstallments(defaultInstallments);
        setQuantity(1);
    }

    const deletePrazo = () => {
        setLoading(true);

        api.delete(`/paymentterm/${prazoToDelete}`).then(res => {
            setPrazoToDelete(null);
            setShowEdit(false);
            getPrazos();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const updateInstallment = (index, name, value) => {
        let newInstallments = [...installments];

        if(newInstallments[index] === undefined){
            newInstallments[index] = {
                term: '',
                interest: '0,0000 %'
            };
        }

        newInstallments[index][name] = value;
        setInstallments(newInstallments);
    }
    
    return (
        <>
            <Modal
                title={`${showCreate ? 'Cadastrar' : 'Editar'} prazo de pagamento`}
                show={showCreate || showEdit}
                close={() => showCreate ? setShowCreate(false) : setShowEdit(false)}
                footer={
                    <>
                        {showEdit &&
                            <Button
                                type={`secondary`}
                                size={`small`}
                                text={`Excluir`}
                                svg={`delete-small`}
                                action={() => setPrazoToDelete(id)}
                                loading={loading} 
                            />
                        }
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={showCreate ? 'Cadastrar' : 'Salvar alterações'}
                            svg={`save-white`}
                            action={() => showCreate ? storePrazo() : updatePrazo()}
                            loading={loading} 
                        />
                    </>
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-8">
                            <Input type={`text`} label={`Nome`} value={name} change={setName} />
                        </div>
                        <div className="col-4">
                            <Input type={`text`} label={`Quant. de parcelas`} value={quantity} change={setQuantity} mask="convertToInt" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2"></div>
                        <div className="col-5">Prazo em dias</div>
                        <div className="col-5">Juros</div>
                    </div>

                    <InstallmentInputs
                        updateInstallment={updateInstallment}
                        quantity={quantity}
                        installments={installments}
                    />
                </div>
            </Modal>
            <ModalDelete
                show={prazoToDelete !== null}
                action={deletePrazo}
                close={() => setPrazoToDelete(null)}
                loading={loading}
            />
            <PanelTemplate id="prazo-pagamento">
                <PageHeader
                    right={
                        <>
                            <Button type={`primary`} size={`small`} text={`Cadastrar prazo de pagamento`} svg="plus-white" action={() => setShowCreate(true)}  />
                        </>
                    }
                />
                <Box className="no-padding">
                    <Table header={data.header} rows={data.rows} loading={loading} />
                </Box>
            </PanelTemplate>
        </>
    )
}