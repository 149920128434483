import React, { useEffect, useState } from "react";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import api from "../../../services/api";
import { convertIntToMoney } from "../../../helpers/conversions";
import { renderError } from "../../../helpers/errors";
import { toast } from "react-toastify";
import Box from "../../../components/Box";

const statuses = {
    waiting: 'Aguardando',
    approved: 'Aprovado',
    disapproved: 'Reprovado'
};

export const Approval = ({order}) => {
    const [loading, setLoading] = useState(true);

    const [aprovados, setAprovados] = useState(0);
    const [reprovados, setReprovados] = useState(0);
    const [aguardando, setAguardando] = useState(0);

    const [items, setItems] = useState([]);

    useEffect(() => {
        getApproval();
    }, []);

    const getApproval = () => {
        setLoading(true);

        api.get(`/order/${order.id}/approval`).then(res => {
            setAprovados(res.data.total.aprovados);
            setReprovados(res.data.total.reprovados);
            setAguardando(res.data.total.aguardando);

            setItems(res.data.items);

            setLoading(false);
        });
    }

    const update = (itemId, status) => {
        setLoading(true);

        api.put(`/order/item/${itemId}/approval`, {
            status
        }).then(() => {
            toast.success('Status atualizado');
            getApproval();
        }).catch(error => {
            renderError(error);
            setLoading(false)
        });
    }


    return (
        <div className="approval">
            <div className="row">
                <div className="col-4">
                    <div className="approval-count success">
                        <strong>{aprovados}</strong>
                        <span>Itens aprovados</span>
                    </div>
                </div>
                <div className="col-4">
                    <div className="approval-count danger">
                        <strong>{reprovados}</strong>
                        <span>Itens reprovados</span>
                    </div>
                </div>
                <div className="col-4">
                    <div className="approval-count waiting">
                        <strong>{aguardando}</strong>
                        <span>Itens aguardando</span>
                    </div>
                </div>
            </div>
            <Box className={`no-padding-bottom`} style={{ marginTop: 20}}>
                <div className="row">
                    <div className="col-3">
                        <Input label={`Tipo do frete`} value={order?.shipping_type} disabled={true} />
                    </div>
                    <div className="col-3">
                        <Input label={`Valor do frete`} value={order?.shipping_consult === true ? 'A consultar' : convertIntToMoney(order?.shipping_fee, true)} disabled={true} />
                    </div>
                </div>
            </Box>
            {loading && 
                <p>Carregando...</p>
            }
            {!loading && items.map((item, index) => (
                <div key={index} className="approval-group">
                    <span className={`status-tag ${item.status}`}>{statuses[item.status]}</span>
                    <div className="row">
                        {item.products.map((product, index) => (
                            <div key={index} className="col-4">
                                <div className="approval-item">
                                    <img src={product.image} />
                                    <h2>{product.name}</h2>
                                    <h3>{product.sku}</h3>
                                    <div className="row">
                                        <div className="col-6">
                                            <span className="cost-title">Custo original</span>
                                            <Input label="Fornecedor" disabled={true} value={product.original_company ?? 'Não informado'} />
                                            <Input label="Quantidade" disabled={true} value={product.original_quantity} />
                                            <Input label="Quebra" disabled={true} value={product.original_quebra} />
                                            <Input label="Custo unit." disabled={true} value={convertIntToMoney(product.original_cost, true)} />
                                            <Input label="Custo quebra" disabled={true} value={convertIntToMoney(product.original_quebra_cost, true)} />
                                            <Input label="Total" disabled={true} value={convertIntToMoney(product.original_total, true)} />
                                        </div>
                                        <div className="col-6">
                                            <span className="cost-title">Custo negociado</span>
                                            <Input label="Fornecedor" disabled={true} value={product.real_company} />
                                            <Input label="Quantidade" disabled={true} value={product.real_quantity} />
                                            <Input label="Quebra" disabled={true} value={product.real_quebra} />
                                            <Input label="Custo unit." disabled={true} value={convertIntToMoney(product.real_cost, true)} />
                                            <Input label="Custo quebra" disabled={true} value={convertIntToMoney(product.real_quebra_cost, true)} />
                                            <Input label="Total" disabled={true} value={convertIntToMoney(product.real_total, true)} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <Input 
                                                label="Frete" 
                                                disabled={true} 
                                                value={product.shipping_cost ? convertIntToMoney(product.shipping_cost, true) : ''} 
                                            />
                                        </div>
                                        <div className="col-6">
                                            <Input label="Prazo pagto." 
                                                disabled={true} 
                                                value={product.payment_term_name} 
                                            />
                                        </div>
                                    </div>
                                    <p><b>Resultado:</b></p>
                                    <p className={`status-color ${product.discont < 0 ? 'negative' : 'positive'}`}>{convertIntToMoney(product.discont, true)}</p>
                                    <br />
                                </div>
                            </div>
                        ))}
                        {item.customizations.map((customization, index) => (
                            <div key={index} className="col-4">
                                <div className="approval-item">
                                    <img src="https://sistema.asgabrindes.imaxinformatica.com.br/images/customization.png" />
                                    <h2>Gravação</h2>
                                    <h3>{customization.name}</h3>
                                    <div className="row">
                                        <div className="col-6">
                                            <span className="cost-title">Custo original</span>
                                            <Input label="Quantidade" disabled={true} value={customization.original_quantity} />
                                            <Input label="Num. de cores" disabled={true} value={customization.original_num_of_colors} />
                                            <Input label="Quant. gravações" disabled={true} value={customization.original_num_of_customizations} />
                                            <Input label="Total" disabled={true} value={convertIntToMoney(customization.original_cost, true)} />
                                        </div>
                                        <div className="col-6">
                                            <span className="cost-title">Custo negociado</span>
                                            <Input label="Quantidade" disabled={true} value={customization.real_quantity} />
                                            <Input label="Num. de cores" disabled={true} value={customization.real_num_of_colors} />
                                            <Input label="Quant. gravações" disabled={true} value={customization.real_num_of_customizations} />
                                            <Input label="Total" disabled={true} value={convertIntToMoney(customization.real_cost, true)} />
                                        </div>
                                    </div>
                                    <Input label="Prazo pagto." disabled={true} value={customization.payment_term_name} />
                                    <p><b>Resultado:</b></p>
                                    <p className={`status-color ${customization.discont < 0 ? 'negative' : 'positive'}`}>{convertIntToMoney(customization.discont, true)}</p>
                                    <br />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="approval-footer">
                        <div className="row">
                            <div className="col-4">
                                <Button disabled={item.status === 'approved'} type="success" text="Aprovar" full={true} action={() => update(item.id, 'approved')} />
                            </div>
                            <div className="col-4">
                                <Button disabled={item.status === 'disapproved'} type="danger" text="Reprovar" full={true}  action={() => update(item.id, 'disapproved')} />
                            </div>
                            <div className="col-4">
                                <Button type="primary" text="Calculadora" full={true} />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}