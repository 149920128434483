import React from "react";

export const TableImage = ({url}) => {
    return (
        <>
            {url &&
                <img src={url} />
            }
            {!url &&
                <img src={ require(`../../assets/images/svgs/default.svg`).default } />
            }
        </>
    );
}

export default TableImage;