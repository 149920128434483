import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";

const ModalItem = ({item}) => {
    
    return (
        <Modal
            show={true}
        >

        </Modal>
    );
}

export default ModalItem;