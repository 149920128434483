import React from "react";

export const Box = ({className, title, tools, style, children}) => {
    return (
        <div className={`box ${className ?? ''}`} style={style ?? null}>
            {(title || tools) &&
                <div className="header">
                    <span>{title}</span>
                    {tools}
                </div>
            }
            <div className="body">
                {children}
            </div>
        </div>
    );
}

export default Box;