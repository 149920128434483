import React, { useEffect, useState } from "react";
import InputRadio from "../../../../components/InputRadio";
import { getIndexFromObjectArray } from "../../../../helpers/helpers";
import { createOptions } from "../../../../helpers/selects";
import Input from "../../../../components/Input";
import { impostos } from "../impostos";
import { convertFloatToPercentage, convertIntToMoney, convertMoenyToInt, convertPercentageToFloat } from "../../../../helpers/conversions";
import api from '../../../../services/api';

const calculationTypeOptions = [
    { label: 'Cálculo automático', value: 'automatic' },
    { label: 'Cálculo manual', value: 'manual' }
];

const ICMSVenda = ({productDestinacao, productDestinoUf, productOrigem, productNcm, index, param, productParams, setProductParams, totalSalePrice}) => {
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [value, setValue] = useState('R$ 0,00');

    const [calculationType, setCalculationType] = useState('automatic');

    useEffect(() => {
        getValue();
    }, [totalSalePrice, productParams[index]?.value]);

    const getValue = () => {
        let total = totalSalePrice ? convertMoenyToInt(totalSalePrice) : 0;
        let calculatedValue = total * convertPercentageToFloat(productParams[index]?.value ?? '0');
        let convertedValue = convertIntToMoney(calculatedValue, true);
        setValue(convertedValue);
    }

    useEffect(() => {
        calculate();
    }, [,productDestinoUf,productOrigem,productNcm,calculationType]);

    const calculate = async () => {
        if(calculationType === 'manual') return;
        let icms = await getFromApi();
        handleParamChange(convertFloatToPercentage(icms));
    }

    const getFromApi = async () => {
        setLoading(true);

        try {
            let destinacao = productDestinacao === "consumo" ? "3" : "1";
            let origemProduto = productOrigem === "0" ? "NAC" : "IMP";
            let ufDestino = productDestinoUf;
            let ufOrigem = "SP";

            const response = await api.get(`imposto/st`, {
                params: {
                    ncm: productNcm,
                    estado_origem: ufOrigem,
                    estado_destino: ufDestino,
                    destinacao_mercadoria: destinacao
                }
            });

            const hasSt = response.data.st;

            setHasError(false);
            setLoading(false);

            if(hasSt){
                if(ufDestino === 'SP'){
                    return 0;
                }

                return getFromTable(origemProduto, ufDestino);
            } else {
                if(origemProduto === "IMP"){
                    return ufDestino === "SP" ? 0.18 : 0.04;
                }

                return getFromTable(origemProduto, ufDestino);
            }
        } catch (error) {
            console.log('errorlegis', error);
            setHasError(true);
        }

        setLoading(false);
    }

    const getFromTable = (origemProduto, ufDestino) => {
        let imposto = impostos.filter(imposto => imposto.uf === `${ufDestino} ${origemProduto}`)[0];
        return parseFloat(imposto.aliquota_interestadual);
    }

    const handleParamChange = value => {
        setProductParams(prev => {
            let toProductParams = [...prev];
            toProductParams[index].value = value;
            return toProductParams;
        });
    }

    return (
        <>
            <div className="modal-section-title">
                <div className="left">
                    <h2>{param.name}</h2>
                </div>
                <div className="right">
                    <InputRadio
                        className={`no-margin`}
                        options={calculationTypeOptions}
                        value={calculationType}
                        change={setCalculationType}
                    />
                </div>
            </div>
            <div className="section">
                {loading &&
                    <p style={{ marginBottom: 15}}>Carregando...</p>
                }
                {!loading && !hasError &&
                    <div className="row">
                        <div className="col-3">
                            <Input label={`Porcentagem`} disabled={calculationType === 'automatic'} value={productParams[index].value} change={handleParamChange} mask={`convertToPercentage`} />
                        </div>
                        <div className="col-3">
                            <Input
                                label={`Valor`}
                                disabled={true}
                                value={value}
                            />
                        </div>
                    </div>
                }
                {!loading && hasError &&
                    <InputRadio 
                        options={createOptions(param.options, 'description', 'value')}
                        value={productParams[index].value}
                        change={handleParamChange}
                    />
                }
            </div>
        </>
    );
}

export default ICMSVenda;