import React, { useEffect, useState } from "react";

import PanelTemplate from "../../templates/Panel";
import BoxTabs from "../../components/BoxTabs";
import InputSelect from "../../components/InputSelect";
import Input from "../../components/Input";
import InputEditor from "../../components/InputEditor";
import PageFooter from "../../components/PageFooter";
import Button from "../../components/Button";
import api from "../../services/api";
import { createOptions } from "../../helpers/selects";
import InputCheckboxesSublevels from "../../components/InputCheckboxesSublevels";
import { renderError } from "../../helpers/errors";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { convertToSlug } from "../../helpers/masks";
import InputTextarea from "../../components/InputTextarea";

const statusOptions = [
    { label: 'Ativo', value: true },
    { label: 'Inativo', value: false }
];

const featuredOptions = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false }
];

const ProductDetailsTab = ({status, setStatus, sku, setSku, featured, setFeatured, recent, setRecent, name, setName, categories, setSelectedCategories, selectedCategories, description, setDescription, techDescription, setTechDescription, tags, tag, setTag, minQuantity, setMinQuantity}) => {
    return (
        <div>
            <div className="row">
                <div className="col-6">
                    <h2 className="section-title">Informações gerais</h2>
                    <div className="row">
                        <div className="col-6">
                            <InputSelect label={`Status`} options={statusOptions} value={status} change={setStatus} />
                        </div>
                        <div className="col-6">
                            <Input label={`Código SKU`} value={sku} change={setSku} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <InputSelect label={`Destaque`} options={featuredOptions} value={featured} change={setFeatured} />
                        </div>
                        <div className="col-6">
                            <InputSelect label={`Novidade`} options={featuredOptions} value={recent} change={setRecent} />
                        </div>
                    </div>
                    <Input label={`Nome do produto`} value={name} change={setName} />
                    <div className="row">
                        <div className="col-6">
                            <InputSelect label={`Selo`} options={createOptions(tags, 'text', 'id', true)} value={tag} change={setTag} null />
                        </div>
                        <div className="col-6">
                            <Input 
                                label={`Quant. mínima`}
                                value={minQuantity}
                                change={setMinQuantity}
                                mask={`convertToInt`}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    {/* <h2 className="section-title">Descrição técnica</h2>
                    <InputTextarea
                        value={techDescription}
                        change={setTechDescription}
                        rows={12}
                    /> */}
                    <h2 className="section-title">Descrição do produto</h2>
                    <InputEditor value={description} change={setDescription} />

                    <h2 className="section-title">Categorias</h2>
                    <InputCheckboxesSublevels
                        options={categories}
                        selecteds={selectedCategories}
                        setSelecteds={setSelectedCategories}
                    />
                </div>
            </div>
        </div>
    );
}

const ImagesTab = () => {
    return (
        <p>Salve o produto para ver esta aba.</p>
    );
}

const VariationsTab = () => {
    return (
        <p>Salve o produto para ver esta aba.</p>
    );
}

const KeyWordsTab = () => {
    return (
        <p>Salve o produto para ver esta aba.</p>
    );
}

const CustomizationsTab = () => {
    return (
        <p>Salve o produto para ver esta aba.</p>
    );
}

const SeoTab = () => {
    return (
        <p>Salve o produto para ver esta aba.</p>
    );
}

export const ProductCreate = () => {
    const navigete = useNavigate();

    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const [tags, setTags] = useState([]);
    
    const [status, setStatus] = useState(false);
    const [sku, setSku] = useState('');
    const [featured, setFeatured] = useState(false);
    const [recent, setRecent] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [techDescription, setTechDescription] = useState('');
    const [tag, setTag] = useState(null);
    const [minQuantity, setMinQuantity] = useState(1);


    useEffect(() => {
        getCategories();
        getTags();
    }, []);

    const getTags = () => {
        api.get(`/tag`).then(res => {
            setTags(res.data.resources);
        });
    }

    const getCategories = () => {
        api.get(`/category`).then(res => {
            let toCategories = [];

            res.data.resources.map(category => {
                let options = [];

                category.categories.map(subcategory => {
                    options.push({
                        label: subcategory.name,
                        value: subcategory.id
                    })
                });
                toCategories.push({
                    label: category.name,
                    value: category.id,
                    options
                })
            })
            setCategories(toCategories);
        });
    }

    const storeProduct = () => {
        setLoading(true);

        api.post(`/product`, {
            status,
            recent,
            featured,
            name,
            sku,
            description,
            tech_description: techDescription,
            meta_title: name,
            meta_description: name,
            categories: selectedCategories,
            keywords: [],
            customizations: [],
            subproducts: [],
            slug: name,
            tag_id: tag,
            min_quantity: minQuantity
        }).then(res => {
            toast.success('Produto cadastrado com sucesso');
            navigete(`/product/edit/${res.data.resource.id}`);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            <PanelTemplate id="product-edit">
                <BoxTabs
                    titles={[
                        'Detalhes dos produtos',
                        'Imagens',
                        'Cores, estoque e preço',
                        'Palavras-chave', 
                        'Gravações', 
                        'SEO',
                        'Tributação',
                    ]}
                    contents={[
                        <ProductDetailsTab
                            status={status}
                            setStatus={setStatus}
                            sku={sku}
                            setSku={setSku}
                            featured={featured}
                            setFeatured={setFeatured}
                            recent={recent}
                            setRecent={setRecent}
                            name={name}
                            setName={setName}
                            categories={categories}
                            setSelectedCategories={setSelectedCategories}
                            selectedCategories={selectedCategories}
                            setDescription={setDescription}
                            description={description}
                            setTechDescription={setTechDescription}
                            techDescription={techDescription}
                            tags={tags}
                            tag={tag}
                            setTag={setTag}
                            minQuantity={minQuantity}
                            setMinQuantity={setMinQuantity}
                        />,
                        <ImagesTab />,
                        <VariationsTab />,
                        <KeyWordsTab />,
                        <CustomizationsTab />,
                        <SeoTab />,
                        <p>Salve o produto para ver esta aba.</p>
                    ]}
                />
                <PageFooter>
                    <Button 
                        type={`primary`}
                        size={`small`}
                        svg={`save-white`}
                        text={`Salvar alterações`}
                        action={storeProduct}
                        loading={loading}
                    />
                </PageFooter>
            </PanelTemplate>
        </>
    );
}

export default ProductCreate;