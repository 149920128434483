import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Box from "../../components/Box";
import Button from "../../components/Button";
import InputCheckboxUnique from "../../components/InputCheckboxUnique";
import InputTextarea from "../../components/InputTextarea";
import Table from "../../components/Table";
import { renderError } from "../../helpers/errors";
import api from "../../services/api";
import PanelTemplate from "../../templates/Panel";
import ModalProductVariable from "./ModalProductVariable";

const globalVariables = {
    header: ['Nome', 'Tipo'],
    rows: [
        {
            data: [
                'Imposto', 'Porcentagem'
            ],
            to: '/company/list'
        },
        {
            data: [
                'PIS', 'Porcentagem'
            ],
            to: '/company/list'
        },
        {
            data: [
                'COFINS', 'Porcentagem'
            ],
            to: '/company/list'
        },
        {
            data: [
                'CSLL', 'Porcentagem',
            ],
            to: '/company/list'
        },
        {
            data: [
                'IRPJ', 'Porcentagem'
            ],
            to: '/company/list'
        },
        {
            data: [
                'Adicionais IRPJ', 'Porcentagem'
            ],
            to: '/company/list'
        },
        {
            data: [
                'DIFAL', 'Porcentagem'
            ]
        }
    ]
};

const Calculation = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({header: [], rows: []});
    const [params, setParams] = useState([]);
    const [paramToEdit, setParamToEdit] = useState(null);
    const [createProductVariable, setCreateProductVariable] = useState(false);
    const [users, setUsers] = useState([]);
    const [companies, setCompanies] = useState([]);

    const [formulasPrice, setFormulasPrice] = useState([]);

    const [formulaCommission, setFormulaCommission] = useState('');
    const [formulaCommissionId, setFormulaCommissionId] = useState('');

    useEffect(() => {
        getParams();
        getUsers();
        getCompanies();
    }, []);

    useEffect(() => {
        generateData();
    }, [params]);

    const getUsers = () => {
        api.get(`/list`).then(res => {
            setUsers(res.data.resources);
        })
    }

    const getCompanies = () => {
        api.get(`/company?filters[0][type][contain]=billing`).then(res => {
            setCompanies(res.data.resources);
        }).catch(error => {
            renderError(error);
        });
    }

    const getParams = () => {
        api.get('/param').then(res => {
            let toParams = [];
            let toFormulasPrice = [];
            res.data.resources.map(resource => {
                if(resource.type === 'price_calculation'){
                    toFormulasPrice.push({
                        name: resource.name,
                        value: resource.value,
                        id: resource.id,
                        company_ids: resource?.options?.company_ids ?? []
                    });
                    return;
                }
                if(resource.type === 'commission_calculation'){
                    setFormulaCommission(resource.value);
                    setFormulaCommissionId(resource.id);
                    return;
                }
                toParams.push(resource);
            })
            setFormulasPrice(toFormulasPrice);
            setParams(toParams);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Nome', 'Origem', 'Posição'];
        const rows = [];

        rows.push({
            data: [
                'Quantidade',
                'Sistema',
                ''
            ]
        });
        rows.push({
            data: [
                'Custo unitário',
                'Sistema',
                ''
            ]
        });
        rows.push({
            data: [
                'Gravação',
                'Sistema',
                ''
            ]
        });
        
        
        params.map(param => {
            rows.push({
                data: [
                    param.name,
                    'Personalizada',
                    param.position
                ],
                action: () => setParamToEdit(param)
            })
        });

        

        setData({header, rows});
    }

    const storeFormulaPrice = async () => {
        setLoading(true);
        let hasError = false;

        formulasPrice.map((formulaPrice, index) => {
            api.put(`/param/${formulaPrice.id}`, {
                name: formulaPrice.name,
                type: 'price_calculation',
                value: formulaPrice.value,
                options: { company_ids: formulaPrice.company_ids },
                required: true
            }).then(res => {
                if((index + 1) === formulasPrice.length && !hasError){
                    toast.success('Fórmula do preço de venda salvo com sucesso');
                    getParams();
                }
            }).catch(error => {
                hasError = true;
                renderError(error);
                setLoading(false);
            });
        });
    }

    const storeFormulaCommission = () => {
        setLoading(true);

        api.put(`/param/${formulaCommissionId}`, {
            name: 'Cálculo da comissão',
            type: 'commission_calculation',
            value: formulaCommission
        }).then(res => {
            toast.success('Fórmula da comissão salva com sucesso');
            getParams();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const handleFormulaPriceChange = (value, index) => {
        let toFormulasPrice = [...formulasPrice];
        toFormulasPrice[index].value = value;
        setFormulasPrice(toFormulasPrice);
    }

    const handleFormulaPriceCompanyChange = (checked, id, index) => {
        let toFormulasPrice = [...formulasPrice];
        let toIds = [...toFormulasPrice[index].company_ids];
        if(toIds.includes(id) && !checked){
            toIds = toIds.filter(toId => toId !== id);
        } else {
            toIds.push(id);
        }

        toFormulasPrice[index].company_ids = toIds;

        setFormulasPrice(toFormulasPrice);
    }
        

    return (
        <>
            {(paramToEdit !== null || createProductVariable) &&    
                <ModalProductVariable
                    close={() => {
                        setCreateProductVariable(false);
                        setParamToEdit(null);
                    }}
                    loading={loading}
                    setLoading={setLoading}
                    params={params}
                    getParams={getParams}
                    paramToEdit={paramToEdit}
                    users={users}
                />
            }
            <PanelTemplate id="calculation">
                <div className="row">
                    <div className="col-6">
                        <Box
                            title={`Cálculo do preço de venda`}
                        >
                            <h2>Instruções</h2>
                            <p>Use apenas os seguintes caraceteres: +, -, /, *, (, ) <br />
                            Para utilizar variáveis, coloque-as entre colchetes. Exemplo: [Custo unitário]
                            </p>
                            {formulasPrice.map((formulaPrice, index) => (
                                <div key={index}>
                                    <InputTextarea
                                        label={formulaPrice.name} 
                                        value={formulaPrice.value} 
                                        change={value => handleFormulaPriceChange(value, index)} 
                                        rows={6}
                                    />
                                    {companies.map((company, companyIndex) => (
                                        <InputCheckboxUnique key={companyIndex} label={company.trade_name} change={checked => handleFormulaPriceCompanyChange(checked, company.id, index)} checked={formulaPrice.company_ids.includes(company.id)} />
                                    ))}
                                </div>
                            ))}
                        </Box>
                        <Button type={`primary`} size={`small`} svg={`save-white`} loading={loading} text={`Salvar cálculo`} action={storeFormulaPrice} />
                        <Box
                            title={`Cálculo da comissão`}
                        >
                            <h2>Instruções</h2>
                            <p>Use apenas os seguintes caraceteres: +, -, /, *, (, ) <br />
                            Para utilizar variáveis, coloque-as entre colchetes. Exemplo: [Custo unitário] <br />
                            Você pode usar a variável [Preço de venda]</p>
                            <InputTextarea 
                                label={`Fórmula`}
                                value={formulaCommission} 
                                change={setFormulaCommission} 
                                rows={6} 
                            />
                        </Box>
                        <Button type={`primary`} size={`small`} svg={`save-white`} loading={loading} text={`Salvar cálculo`} action={storeFormulaCommission} />
                    </div>
                    <div className="col-6">
                        <Box
                            title={`Variáveis globais`}
                            className={`no-padding`}
                            // tools={<Button type={`secondary`} text={`Cadastrar variável`} size={`small`} svg={`plus-blue`} />}
                        >
                            <Table header={globalVariables.header} rows={globalVariables.rows} />
                        </Box>
                        <Box
                            title={`Variáveis do produto`}
                            className={`no-padding`}
                            tools={
                                <Button
                                    type={`secondary`}
                                    text={`Cadastrar variável`}
                                    size={`small`}
                                    svg={`plus-blue`}
                                    action={() => setCreateProductVariable(true)}
                                />
                            }
                        >
                            <Table header={data.header} rows={data.rows} loading={loading} />
                        </Box>
                    </div>
                </div>
            </PanelTemplate>
        </>
    );
}

export default Calculation;