import React, { useEffect, useState } from "react";

import PanelTemplate from "../../templates/Panel";
import moment from "moment";
import DashboardPedidos from "../../components/dashboard/Pedidos";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";

export const Dashboard = () => {
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setLoading(true);

        api.get(`/dashboard`).then(res => {
            setOrders(res.data.orders);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    return (
        <>
            <PanelTemplate id="dashboard">
                <DashboardPedidos orders={orders} loading={loading} />
            </PanelTemplate>
        </>
    );
}

export default Dashboard;