import React, { useEffect, useState } from "react";
import { renderError } from "../../../helpers/errors";
import { createOptions } from "../../../helpers/selects";
import api from "../../../services/api";
import InputSelect from "../../InputSelect";

export const SelectCustomizationCompany = ({selectedCompany, setSelectedCompany}) => {
    const [loading, setLoading] = useState(true);

    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        getCompanies();
    }, []);

    const getCompanies = () => {
        setLoading(true);

        api.get(`/company?filters[0][type][contain]=customization`).then(res => {
            setCompanies(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => {
            setLoading(false);
        });
    }

    return (
        <InputSelect
            loading={loading}
            label={`Fornecedor`}
            options={createOptions(companies, 'trade_name', 'id')} 
            value={selectedCompany} 
            change={setSelectedCompany}
        />
    );
}

export default SelectCustomizationCompany;