import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import DotStatus from "../../../components/DotStatus";
import Input from "../../../components/Input";
import InputDate from "../../../components/InputDate";
import InputEditorTiny from "../../../components/InputEditorTiny";
import InputSelect from "../../../components/InputSelect";
import Modal from "../../../components/Modal";
import { ModalDelete } from "../../../components/ModalDelete";
import SelectProductCompany from "../../../components/selects/SelectProductCompany";
import SelectStatus from "../../../components/selects/SelectStatus";
import Table from "../../../components/Table";
import TableImage from "../../../components/TableImage";
import { convertIntToMoney, convertMoenyToInt } from "../../../helpers/conversions";
import { renderError } from "../../../helpers/errors";
import { createItemOptions, createOptions, createProductOptions } from "../../../helpers/selects";
import api from "../../../services/api";

const types = [
    {label: 'Pedido de gravação', value: 'customization'},
    {label: 'Pedido de compra', value: 'purchase'}
];

const ReceiptTab = ({order}) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({rows: [], header:[]});
    const [showCreateReceipt, setShowCreateReceipt] = useState(false);
    const [showEditReceipt, setShowEditReceipt] = useState(false);

    const [orderReceipts, setOrderReceipts] = useState([]);
    const [orderReceiptIdToDelete, setOrderReceiptIdToDelete] = useState(null);

    const [orderReceiptId , setOrderReceiptId ] = useState(null);
    const [orderItemId , setOrderItemId ] = useState(null);
    const [quantityOfPieces , setQuantityOfPieces ] = useState('0');
    const [deliveryDate , setDeliveryDate ] = useState(null);
    const [type, setType] = useState(null);
    const [orderCustomizationId , setOrderCustomizationId ] = useState(null);
    const [orderPurchaseId, setOrderPurchaseId] = useState(null);
    const [nfUrl, setNfUrl] = useState('');

    const [orderPurchases, setOrderPurchases] = useState([]);
    const [filteredOrderPurchases, setFilteredOrderPurchases] = useState([]);

    const [orderCustomizations, setOrderCustomizations] = useState([]);
    const [filteredOrderCustomizations, setFilteredOrderCustomizations] = useState([]);

    const [removeFile, setRemoveFile] = useState(false);

    useEffect(() => {
        if(order){
            getOrderPurchases();
            getOrderCustomizations();
            getOrderReceipts();
        }
    }, [order]);

    useEffect(() => {
        filterOrderPurchases();
    }, [orderPurchases, orderItemId]);

    useEffect(() => {
        filterOrderCustomizations();
    }, [orderCustomizations, orderItemId]);

    useEffect(() => {
        if(showCreateReceipt === false) clean();
    }, [showCreateReceipt]);

    useEffect(() => {
        if(showEditReceipt === false) clean();
    }, [showEditReceipt])

    useEffect(() => {
        generateData();
    }, [orderReceipts]);

    const filterOrderPurchases = () => {
        let toFilteredOrderPurchases = [];
        orderPurchases.map(orderPurchase => {
            if(orderPurchase.order_item_id === orderItemId){
                toFilteredOrderPurchases.push(orderPurchase);
            }
        });

        setFilteredOrderPurchases(toFilteredOrderPurchases);
    }

    const filterOrderCustomizations = () => {
        let toFilteredOrderCustomizations = [];
        orderCustomizations.map(orderCustomization => {
            if(orderCustomization.order_item_id === orderItemId){
                toFilteredOrderCustomizations.push(orderCustomization);
            }
        });

        setFilteredOrderCustomizations(toFilteredOrderCustomizations);
    }

    const getOrderCustomizations = () => {
        api.get(`/order/customization?filters[0][order_id][equal]=${order.id}`).then(res => {
            setOrderCustomizations(res.data.resources);
        }).catch(error => {
            renderError(error);
        });
    }

    const getOrderPurchases = () => {
        api.get(`/order/purchase?filters[0][order_id][equal]=${order.id}`).then(res => {
            setOrderPurchases(res.data.resources);
        }).catch(error => {
            renderError(error);
        });
    }

    const getOrderReceipts = () => {
        api.get(`/order/receipt?filters[0][order_id][equal]=${order.id}`).then(res => {
            setOrderReceipts(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Imagem', 'SKU', 'Nome', 'Quant. de peças', 'Data do recebimento', 'Origem', 'Pedido'];
        const rows = [];
        
        
        orderReceipts.map(orderReceipt => {
            let buttons = [];

            if(orderReceipt.nf_url){
                buttons.push(
                    <Button 
                        type={`secondary`}
                        size={`small`}
                        svg={`download`}
                        text={`Download da nota`}
                        action={() => downloadFile(orderReceipt.nf_url)}
                    />
                );
            }

            rows.push({
                data: [
                    <TableImage url={orderReceipt?.item_image_url} />,
                    orderReceipt?.item_sku,
                    orderReceipt?.item_name,
                    orderReceipt?.quantity_of_pieces,
                    moment(orderReceipt?.delivery_date).format(`DD/MM/YYYY`),
                    orderReceipt?.type === 'purchase' ? 'Compra' : 'Gravação',
                    orderReceipt?.order_description
                ],
                action: () => edit(orderReceipt),
                buttons
            });
        });

        setData({header, rows});
    }

    const edit = orderReceipt => {
        setOrderReceiptId(orderReceipt.id);
        setOrderItemId(orderReceipt.order_item_id);
        setQuantityOfPieces(orderReceipt.quantity_of_pieces);
        setDeliveryDate(new Date(orderReceipt.delivery_date));
        setType(orderReceipt.type);
        setOrderCustomizationId(orderReceipt.order_customization_id);
        setOrderPurchaseId(orderReceipt.order_purchase_id);
        setNfUrl(orderReceipt.nf_url);
        setShowEditReceipt(true);
    }

    const storeReceipt = () => {
        let form = new FormData();
        // let el = document.getElementById('nf');
        // let file = el.files[0];

        // if(file !== undefined){
        //     form.append('nf', file);
        // } 

        form.append('quantity_of_pieces', quantityOfPieces);
        form.append('delivery_date', moment(deliveryDate).format('YYYY-MM-DD'));
        form.append('type', type);
        form.append('order_item_id', orderItemId);
        form.append('order_id', order.id);
        

        if(type === 'customization'){
            form.append('order_customization_id', orderCustomizationId);
        } else {
            form.append('order_purchase_id', orderPurchaseId);
        }

        setLoading(true);
        
        api.post(`/order/receipt`, form, { 
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(res => {
            getOrderReceipts();
            setShowCreateReceipt(false);
            toast.success('Recebimento cadastrado com sucesso');
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const updateReceipt = () => {
        let form = new FormData();
        form.append('quantity_of_pieces', quantityOfPieces);
        form.append('delivery_date', moment(deliveryDate).format('YYYY-MM-DD'));
        form.append('type', type);
        form.append('order_item_id', orderItemId);
        form.append('order_id', order.id);

        if(type === 'customization'){
            form.append('order_customization_id', orderCustomizationId);
        } else {
            form.append('order_purchase_id', orderPurchaseId);
        }

        if(removeFile){
            let el = document.getElementById('file');
            let file = el.files[0];
            
            if(file === undefined){
                toast.error('Você precisa informar um arquivo');
                return;
            }

            form.append('nf', file);
        }

        setLoading(true);
        
        api.post(`/order/receipt/${orderReceiptId}?_method=PUT`, form, { 
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(res => {
            getOrderReceipts();
            setShowEditReceipt(false);
            toast.success('Recebimento atualizado com sucesso');
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const clean = () => {
        setOrderReceiptId(null);
        setRemoveFile(false);
        setOrderItemId(null);
        setQuantityOfPieces('0');
        setDeliveryDate(null);
        setType(null);
        setOrderCustomizationId(null);
        setOrderPurchaseId(null);
        setNfUrl('');
    }

    const deleteReceipt = () => {
        setLoading(true);

        api.delete(`/order/receipt/${orderReceiptIdToDelete}`).then(res => {
            toast.success('Recebimento deletado com sucesso');
            setOrderReceiptIdToDelete(null);
            getOrderReceipts();
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const confirmDelete = id => {
        setOrderReceiptIdToDelete(id);
        setShowEditReceipt(false);
    }

    const downloadFile = nfUrl => {
        console.log(nfUrl);
        window.open(nfUrl);
    }

    return (
        <>
            <ModalDelete
                action={deleteReceipt}
                close={() => setOrderReceiptIdToDelete(null)}
                show={orderReceiptIdToDelete !== null}
                loading={loading}
            />
            {(showCreateReceipt || showEditReceipt) &&
            <Modal 
                show={true}
                size={'big'}
                close={() => showCreateReceipt ?  setShowCreateReceipt(false) : setShowEditReceipt(false)}
                title={`${showEditReceipt ? 'Editar' : 'Cadastrar'} recebimento`}
                footer={
                    <>
                        {showEditReceipt &&
                            <Button
                                type={`secondary`}
                                size={`small`}
                                text={`Excluir`}
                                svg={`delete-small`}
                                action={() => confirmDelete(orderReceiptId)}
                                loading={loading} 
                            />
                        }
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={showCreateReceipt ? 'Cadastrar' : 'Salvar alterações'}
                            svg={`save-white`}
                            action={() => showCreateReceipt ? storeReceipt() : updateReceipt()}
                            loading={loading} 
                        />
                    </>
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-8">
                            <InputSelect
                                label={`Produto`}
                                options={createItemOptions(order.items)} 
                                value={orderItemId} 
                                change={setOrderItemId}
                            />
                        </div>
                        <div className="col-4">
                            <InputSelect
                                label={`Origem`}
                                options={types} 
                                value={type} 
                                change={setType}
                            />
                        </div>
                    </div>
                </div>
                {type === 'customization' &&
                    <div className="section">
                        <InputSelect
                            label={`Escolha o pedido de gravação`}
                            options={createOptions(orderCustomizations, 'description', 'id')} 
                            value={orderCustomizationId} 
                            change={setOrderCustomizationId}
                        />
                    </div>
                }
                {type === 'purchase' &&
                    <div className="section">
                        <InputSelect
                            label={`Escolha o pedido de compra`}
                            options={createOptions(orderPurchases, 'description', 'id')} 
                            value={orderPurchaseId} 
                            change={setOrderPurchaseId}
                        />
                    </div>
                }
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <InputDate
                                label={`Data do recebimento`} 
                                value={deliveryDate}
                                change={setDeliveryDate}
                            />
                        </div>
                        <div className="col-6">
                            <Input 
                                label={`Quant. de peças`} 
                                value={quantityOfPieces} 
                                change={setQuantityOfPieces} 
                                mask={`convertToInt`}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="section" style={{ paddingBottom: 15}}>
                    {(removeFile || showCreateReceipt) &&
                        <div className="row">
                            <div className="col-7">
                                <input type={`file`} id="nf" accept=".pdf,.xml" />
                            </div>
                        </div>
                    }
                    {showEditReceipt && !removeFile &&
                        <div className="row">
                            <div className="col-4">
                                <Button 
                                    full={true}
                                    type={`secondary`}
                                    size={`small`}
                                    svg={`download`}
                                    text={`Download nf`}
                                    action={() => downloadFile(nfUrl)}
                                />
                            </div>
                            <div className="col-4">
                                <Button 
                                    full={true}
                                    type={`secondary`}
                                    size={`small`}
                                    svg={`delete`}
                                    text={`Remover nf`}
                                    action={() => setRemoveFile(true)}
                                />
                            </div>
                        </div>
                    }
                </div> */}
            </Modal>
            }
            <div className="row">
                <div className="col">
                    <Button 
                        type={`secondary`}
                        size={`small`}
                        text={`Cadastrar recebimento`}
                        action={() => setShowCreateReceipt(true)}
                    />
                    <br />
                </div>
            </div>
            <Table rows={data.rows} header={data.header} loading={loading} />
        </>
    );
} 

export default ReceiptTab;