import React, { useEffect, useState } from "react";
import { renderError } from "../../../helpers/errors";
import { createOptions } from "../../../helpers/selects";
import api from "../../../services/api";
import InputSelect from "../../InputSelect";

export const SelectAdmin = ({selectedAdmin, setSelectedAdmin}) => {
    const [loading, setLoading] = useState(true);

    const [admins, setAdmins] = useState([]);

    useEffect(() => {
        getAdmins();
    }, []);

    const getAdmins = () => {
        setLoading(true);

        api.get(`/list`).then(res => {
            setAdmins(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => {
            setLoading(false);
        });
    }

    return (
        <InputSelect
            loading={loading}
            label={`Consultor`}
            options={createOptions(admins, 'name', 'id')} 
            value={selectedAdmin} 
            change={setSelectedAdmin}
        />
    );
}

export default SelectAdmin;