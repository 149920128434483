import React, { useEffect, useState } from "react";
import { renderError } from "../../../helpers/errors";
import { createOptions } from "../../../helpers/selects";
import api from "../../../services/api";
import InputSelect from "../../InputSelect";

export const SelectContaReceberCategoria = ({selectedContaReceberCategoria, setSelectedContaReceberCategoria}) => {
    const [loading, setLoading] = useState(true);
    const [contaReceberCategorias, setContaReceberCategorias] = useState([]);

    useEffect(() => {
        getContaReceberCategorias();
    }, []);

    const getContaReceberCategorias = () => {
        setLoading(true);

        api.get(`/conta-receber/categoria`).then(res => {
            setContaReceberCategorias(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => {
            setLoading(false);
        });
    }

    return (
        <InputSelect
            loading={loading}
            label={`Categoria`}
            options={createOptions(contaReceberCategorias, 'nome', 'id')} 
            value={selectedContaReceberCategoria} 
            change={setSelectedContaReceberCategoria}
        />
    );
}

export default SelectContaReceberCategoria;