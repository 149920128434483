export const impostos = [
    {
      uf: "AC IMP",
      aliquota_interna: "0.19",
      aliquota_interestadual: "0.04",
      difal: "0.15"
    },
    {
      uf: "AC NAC",
      aliquota_interna: "0.19",
      aliquota_interestadual: "0.07",
      difal: "0.12"
    },
    {
      uf: "AL IMP",
      aliquota_interna: "0.19",
      aliquota_interestadual: "0.04",
      difal: "0.15"
    },
    {
      uf: "AL NAC",
      aliquota_interna: "0.19",
      aliquota_interestadual: "0.07",
      difal: "0.12"
    },
    {
      uf: "AM IMP",
      aliquota_interna: "0.2",
      aliquota_interestadual: "0.04",
      difal: "0.16"
    },
    {
      uf: "AM NAC",
      aliquota_interna: "0.2",
      aliquota_interestadual: "0.07",
      difal: "0.13"
    },
    {
      uf: "AP IMP",
      aliquota_interna: "0.18",
      aliquota_interestadual: "0.04",
      difal: "0.14"
    },
    {
      uf: "AP NAC",
      aliquota_interna: "0.18",
      aliquota_interestadual: "0.07",
      difal: "0.11"
    },
    {
      uf: "BA IMP",
      aliquota_interna: "0.19",
      aliquota_interestadual: "0.04",
      difal: "0.15"
    },
    {
      uf: "BA NAC",
      aliquota_interna: "0.19",
      aliquota_interestadual: "0.07",
      difal: "0.12"
    },
    {
      uf: "CE IMP",
      aliquota_interna: "0.18",
      aliquota_interestadual: "0.04",
      difal: "0.14"
    },
    {
      uf: "CE NAC",
      aliquota_interna: "0.18",
      aliquota_interestadual: "0.07",
      difal: "0.11"
    },
    {
      uf: "DF IMP",
      aliquota_interna: "0.18",
      aliquota_interestadual: "0.04",
      difal: "0.14"
    },
    {
      uf: "DF NAC",
      aliquota_interna: "0.18",
      aliquota_interestadual: "0.07",
      difal: "0.11"
    },
    {
      uf: "ES IMP",
      aliquota_interna: "0.17",
      aliquota_interestadual: "0.04",
      difal: "0.13"
    },
    {
      uf: "ES NAC",
      aliquota_interna: "0.17",
      aliquota_interestadual: "0.07",
      difal: "0.1"
    },
    {
      uf: "GO IMP",
      aliquota_interna: "0.17",
      aliquota_interestadual: "0.04",
      difal: "0.13"
    },
    {
      uf: "GO NAC",
      aliquota_interna: "0.17",
      aliquota_interestadual: "0.07",
      difal: "0.1"
    },
    {
      uf: "MA IMP",
      aliquota_interna: "0.2",
      aliquota_interestadual: "0.04",
      difal: "0.16"
    },
    {
      uf: "MA NAC",
      aliquota_interna: "0.2",
      aliquota_interestadual: "0.07",
      difal: "0.13"
    },
    {
      uf: "MG IMP",
      aliquota_interna: "0.18",
      aliquota_interestadual: "0.04",
      difal: "0.14"
    },
    {
      uf: "MG NAC",
      aliquota_interna: "0.18",
      aliquota_interestadual: "0.12",
      difal: "0.06"
    },
    {
      uf: "MS IMP",
      aliquota_interna: "0.17",
      aliquota_interestadual: "0.04",
      difal: "0.13"
    },
    {
      uf: "MS NAC",
      aliquota_interna: "0.17",
      aliquota_interestadual: "0.07",
      difal: "0.1"
    },
    {
      uf: "MT IMP",
      aliquota_interna: "0.17",
      aliquota_interestadual: "0.04",
      difal: "0.13"
    },
    {
      uf: "MT NAC",
      aliquota_interna: "0.17",
      aliquota_interestadual: "0.07",
      difal: "0.1"
    },
    {
      uf: "PA IMP",
      aliquota_interna: "0.19",
      aliquota_interestadual: "0.04",
      difal: "0.15"
    },
    {
      uf: "PA NAC",
      aliquota_interna: "0.19",
      aliquota_interestadual: "0.07",
      difal: "0.12"
    },
    {
      uf: "PB IMP",
      aliquota_interna: "0.18",
      aliquota_interestadual: "0.04",
      difal: "0.14"
    },
    {
      uf: "PB NAC",
      aliquota_interna: "0.18",
      aliquota_interestadual: "0.07",
      difal: "0.11"
    },
    {
      uf: "PE IMP",
      aliquota_interna: "0.18",
      aliquota_interestadual: "0.04",
      difal: "0.14"
    },
    {
      uf: "PE NAC",
      aliquota_interna: "0.18",
      aliquota_interestadual: "0.07",
      difal: "0.11"
    },
    {
      uf: "PI IMP",
      aliquota_interna: "0.21",
      aliquota_interestadual: "0.04",
      difal: "0.17"
    },
    {
      uf: "PI NAC",
      aliquota_interna: "0.21",
      aliquota_interestadual: "0.07",
      difal: "0.14"
    },
    {
      uf: "PR IMP",
      aliquota_interna: "0.19",
      aliquota_interestadual: "0.04",
      difal: "0.15"
    },
    {
      uf: "PR NAC",
      aliquota_interna: "0.19",
      aliquota_interestadual: "0.12",
      difal: "0.07"
    },
    {
      uf: "RJ IMP",
      aliquota_interna: "0.2",
      aliquota_interestadual: "0.04",
      difal: "0.16"
    },
    {
      uf: "RJ NAC",
      aliquota_interna: "0.2",
      aliquota_interestadual: "0.12",
      difal: "0.08"
    },
    {
      uf: "RN IMP",
      aliquota_interna: "0.2",
      aliquota_interestadual: "0.04",
      difal: "0.16"
    },
    {
      uf: "RN NAC",
      aliquota_interna: "0.2",
      aliquota_interestadual: "0.07",
      difal: "0.13"
    },
    {
      uf: "RO IMP",
      aliquota_interna: "0.175",
      aliquota_interestadual: "0.04",
      difal: "0.135"
    },
    {
      uf: "RO NAC",
      aliquota_interna: "0.175",
      aliquota_interestadual: "0.07",
      difal: "0.105"
    },
    {
      uf: "RR IMP",
      aliquota_interna: "0.2",
      aliquota_interestadual: "0.04",
      difal: "0.16"
    },
    {
      uf: "RR NAC",
      aliquota_interna: "0.2",
      aliquota_interestadual: "0.07",
      difal: "0.13"
    },
    {
      uf: "RS IMP",
      aliquota_interna: "0.17",
      aliquota_interestadual: "0.04",
      difal: "0.13"
    },
    {
      uf: "RS NAC",
      aliquota_interna: "0.17",
      aliquota_interestadual: "0.12",
      difal: "0.05"
    },
    {
      uf: "SC IMP",
      aliquota_interna: "0.17",
      aliquota_interestadual: "0.04",
      difal: "0.13"
    },
    {
      uf: "SC NAC",
      aliquota_interna: "0.17",
      aliquota_interestadual: "0.12",
      difal: "0.05"
    },
    {
      uf: "SE IMP",
      aliquota_interna: "0.22",
      aliquota_interestadual: "0.04",
      difal: "0.18"
    },
    {
      uf: "SE NAC",
      aliquota_interna: "0.22",
      aliquota_interestadual: "0.07",
      difal: "0.15"
    },
    {
      uf: "SP IMP",
      aliquota_interna: "0.18",
      aliquota_interestadual: "0.18",
      difal: "0"
    },
    {
      uf: "SP NAC",
      aliquota_interna: "0.18",
      aliquota_interestadual: "0.18",
      difal: "0"
    },
    {
      uf: "TO IMP",
      aliquota_interna: "0.2",
      aliquota_interestadual: "0.04",
      difal: "0.16"
    },
    {
      uf: "TO NAC",
      aliquota_interna: "0.2",
      aliquota_interestadual: "0.07",
      difal: "0.13"
    }
];