import React, { useEffect, useState } from "react";
import { renderError } from "../../../helpers/errors";
import { createOptions } from "../../../helpers/selects";
import api from "../../../services/api";
import InputSelect from "../../InputSelect";

export const SelectPaymentForm = ({selectedPaymentForm, setSelectedPaymentForm}) => {
    const [loading, setLoading] = useState(true);

    const [paymentForms, setPaymentForms] = useState([]);

    useEffect(() => {
        getPaymentForms();
    }, []);

    const getPaymentForms = () => {
        setLoading(true);

        api.get(`/paymentform`).then(res => {
            setPaymentForms(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => {
            setLoading(false);
        });
    }

    return (
        <InputSelect
            loading={loading}
            label={`Método de pagamento`}
            options={createOptions(paymentForms, 'name', 'id')} 
            value={selectedPaymentForm} 
            change={setSelectedPaymentForm}
        />
    );
}

export default SelectPaymentForm;