import React, { useEffect, useState } from "react";
import Select from 'react-select';
import moment from 'moment';

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import DotStatus from "../../components/DotStatus";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import api from "../../services/api";
import { toast } from "react-toastify";
import { renderError } from "../../helpers/errors";
import axios from "axios";
import InputRadio from "../../components/InputRadio";
import origins from "../../seeds/origins";
import { createOptions } from "../../helpers/selects";
import { ModalDelete } from "../../components/ModalDelete";
import { hasPermission } from "../../services/auth";
import Pagination from "../../components/Pagination";
import SelectCustomerCompany from "../../components/selects/SelectCustomerCompany";

const types = [
    {label: 'Prestador de serviço de gravação', value: 'customization'},
    {label: 'Fornecedor de produto', value: 'product'},
    {label: 'Cliente', value: 'customer'},
    {label: 'Faturamento', value: 'billing'}
];

export const CustomerList = () => {
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState('');

    const [customers, setCustomers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    const [showCreateCustomer, setShowCreateCustomer] = useState(false);

    const [customerName, setCustomerName] = useState('');
    const [customerPhone, setCustomerPhone] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [customerCompanies, setCustomerCampanies] = useState([]);
    const [customerAdmin, setCustomerAdmin] = useState(null);
    const [customerType, setCustomerType] = useState([]);

    const [customerToDelete, setCustomerToDelete] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [totalResults, setTotalResults] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPrevPage, setHasPrevPage] = useState(false);

    const [selectedIds, setSelectedIds] = useState([]);
    const [showConfirmMultipleDelete, setShowConfirmMultipleDelete] = useState(false);

    useEffect(() => {
        getCustomers();
    }, [currentPage,filters]);

    useEffect(() => {
        if(!showCreateCustomer) clean();
    }, [showCreateCustomer]);

    useEffect(() => {
        setTypesAndAdmin();
    }, [customerCompanies]);

    useEffect(() => {
        generateData();
    }, [customers]);

    const setTypesAndAdmin = () => {
        let selectedAdmin = customerAdmin;
        let selectedTypes = [...customerType];
        let selectedCompanies = companies.filter(company => customerCompanies.includes(company.id));
        selectedCompanies.map(selectedCompany => {
            if(selectedCompany.admin_id !== null){
                selectedAdmin = selectedCompany.admin_id;
            }
            selectedCompany.type.map(type => {
                if(!selectedTypes.includes(type)){
                    selectedTypes.push(type);
                }
            });
        });
        setCustomerType(selectedTypes);
        setCustomerAdmin(selectedAdmin);
    }

    const generateData = () => {
        let header = ['Código', 'Nome', 'E-mail', 'Telefone', 'Empresa', 'Cadastrado em', 'Atualizado em'];
        const rows = [];

        if(hasPermission('delete_customer')){
            header.push('');
        }

        customers.map(customer => {
            const companies = customer.companies.map(company => company.trade_name).join(', ');

            let buttons = [];

            if(hasPermission('delete_customer')){
                buttons.push(
                    <Button type={`delete`} svg={`delete`} action={() => setCustomerToDelete(customer.id)} />
                )
            }
            rows.push({
                data: [
                    customer.code,
                    customer.name,
                    customer.email,
                    customer.phone,
                    companies,
                    customer.created_at ? moment(customer.created_at).format('DD/MM/YYYY HH:mm') : '',
                    customer.created_at ? moment(customer.updated_at).format('DD/MM/YYYY HH:mm') : ''
                ],
                buttons,
                id: customer.id,
                to: `/customer/edit/${customer.id}`
            })
        });

        setData({header, rows});
    }

    const getCustomers = () => {
        setCustomers([]);
        setLoading(true);

        api.get(`/customer/paginate?${filters}&page=${currentPage}`).then(res => {
            setCustomers(res.data.customers);
            setCurrentPage(res.data.pagination.current_page);
            setLastPage(res.data.pagination.last_page)
            setPerPage(res.data.pagination.per_page)
            setTotalResults(res.data.pagination.total)
            setHasNextPage(res.data.pagination.next_page_url !== null);
            setHasPrevPage(res.data.pagination.prev_page_url !== null);
        }).catch(error => {
            
        }).then(() => setLoading(false));
    }

    const storeCustomer = () => {
        setLoading(true);

        api.post(`/customer`, {
            name: customerName,
            email: customerEmail,
            phone: customerPhone,
            company_ids: customerCompanies,
            // admin_id: customerAdmin,
            type: customerType
        }).then(res => {
            toast.success('Pessoa cadastrada com sucesso');
            setShowCreateCustomer(false);
            getCustomers();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const clean = () => {
        setCustomerName('');
        setCustomerEmail('');
        setCustomerPhone('');
        setCustomerCampanies([]);
    }

    const deleteCustomer = () => {
        setLoading(true);

        api.delete(`/customer/${customerToDelete}`).then(res => {
            setCustomerToDelete(null);
            getCustomers();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const handleSetFilter = value => {
        setCurrentPage(1);
        setFilters(value);
    }

    const deleteMultipleCustomers = () => {
        setLoading(true);

        api.delete(`/customer/multiple`, {
            data: {
                ids: selectedIds
            }
        }).then(res => {
            setSelectedIds([]);
            getCustomers();
            setShowConfirmMultipleDelete(false);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            <ModalDelete
                show={customerToDelete !== null}
                action={deleteCustomer}
                loading={loading}
                close={() => setCustomerToDelete(null)}
            />
            <ModalDelete
                show={showConfirmMultipleDelete}
                text={<p>Tem certeza que deseja apagar <strong>{selectedIds.length} {selectedIds.length > 1 ? 'pessoas' : 'pessoa'}</strong>?</p>}
                action={deleteMultipleCustomers}
                loading={loading}
                close={() => setShowConfirmMultipleDelete(false)}
            />
            <Modal
                title={`Cadastrar pessoa`}
                show={showCreateCustomer}
                close={() => setShowCreateCustomer(false)}
                footer={
                    <Button 
                        type={`primary`}
                        size={`small`}
                        text={`Cadastrar`}
                        svg={`save-white`}
                        action={storeCustomer}
                        loading={loading}
                    />
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col">
                            <Input type={`text`} label={`Nome`} change={setCustomerName} value={customerName}/>
                        </div>
                    </div>   
                    <div className="row">
                        <div className="col">
                            <Input type={`text`} label={`E-mail`} change={setCustomerEmail} value={customerEmail} />
                        </div>
                    </div>   
                    <div className="row">
                        <div className="col">
                            <Input type={`text`} label={`Telefone`} change={setCustomerPhone} value={customerPhone} mask={`convertToPhone`} />
                        </div>
                    </div>   
                    <div className="row">
                        <div className="col">
                            <SelectCustomerCompany
                                isMulti={true}
                                selectedCustomerCompany={customerCompanies}
                                setSelectedCustomerCompany={setCustomerCampanies}
                            />
                        </div>
                    </div>   
                    <div className="row">
                        <div className="col">
                            <InputSelect label={`Marcadores`} options={types} value={customerType} change={setCustomerType} isMulti={true} />
                        </div>
                    </div>
                    {/* {customerType.includes('customer') &&
                        <div className="row">
                            <div className="col">
                                <InputSelect label={`Consultor`} options={createOptions(admins, 'name', 'id', true)} value={customerAdmin} change={setCustomerAdmin} />
                            </div>
                        </div>
                    }    */}
                </div>
            </Modal>
            <PanelTemplate id="customer-list">
                <PageHeader
                    left={
                        <FilterSimple 
                            params={[
                                {name: 'Nome', key: '[name]', type: 'text' },
                                {name: 'E-mail', key: '[email]', type: 'text' },
                                {name: 'Código', key: '[code]', type: 'text' },
                                {name: 'Nome fantasia da empresa', key: '[related][companies.trade_name]', type: 'text' },
                                {name: 'Razão social da empresa', key: '[related][companies.company_name]', type: 'text' },
                            ]}
                            filter={handleSetFilter}
                        />
                    }

                    right={
                        <>
                            {hasPermission('delete_customer') && selectedIds.length > 0 &&
                                <Button 
                                type={`secondary`} 
                                size={`small`} 
                                svg={`delete`}
                                text={`Excluir ${selectedIds.length} selecionados`} 
                                action={() => setShowConfirmMultipleDelete(true)}  
                                />
                            }
                            {hasPermission('export_customer') &&
                                <Button type={`secondary`} size={`small`} text={`Exportar pessoas`} action={() => window.open(`${process.env.REACT_APP_URL}/customer/export`)}  />
                            }
                            {hasPermission('create_customer') &&
                                <Button type={`primary`} size={`small`} text={`Cadastrar pessoa`} svg="plus-white" action={() => setShowCreateCustomer(true)}  />
                            }
                        </>
                    }
                />
                
                <Box className={`no-padding with-pagination`}>
                    <Table 
                        header={data.header} 
                        rows={data.rows} 
                        loading={loading}
                        checks={hasPermission('delete_customer')}
                        selectedIds={selectedIds}
                        setSelectedIds={setSelectedIds}
                    />
                </Box>
                {!loading &&
                    <Pagination
                        currentPage={currentPage}
                        lastPage={lastPage}
                        perPage={perPage}
                        totalResults={totalResults}
                        hasNextPage={hasNextPage}
                        hasPrevPage={hasPrevPage}
                        setCurrentPage={setCurrentPage}
                    />
                }
            </PanelTemplate>
        </>
    );
}

export default CustomerList;