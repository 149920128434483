export const calculateCustomizationTotal = (customizationQuantity, cost, calculationPer, unitOrInterval, productQuantity) => {
    let thousands = customizationQuantity / 1000;
    thousands = Math.ceil(thousands);

    let total = 0;

    if(calculationPer === 'thousand'){
        total = cost * thousands;
    } else {
        if(unitOrInterval === 'interval'){
            total = cost;
        } else {
            total = cost * customizationQuantity;
        }
    }
console.log('chamou 4', total, productQuantity);
    total = total * productQuantity;

    return total;
}