import React, { useEffect, useState } from "react";

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import moment from "moment";
import PageHeader from "../../components/PageHeader";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import InputFile from "../../components/InputFile";
import InputSelect from "../../components/InputSelect";
import { toast } from "react-toastify";
import BoxTabs from "../../components/BoxTabs";
import { ModalDelete } from "../../components/ModalDelete";


const statusOptions = [
    { label: 'Ativo', value: true },
    { label: 'Inativo', value: false }
];

const typeOptions = [
    { label: 'Slider (2.000px por 600px)', value: 'slider' },
    { label: 'Pós slider (1.000px por 300px)', value: 'after_slider' },
    { label: 'Pós lançamentos (1.000px por 580px)', value: 'after_recent' },
    { label: 'Pós destaques (1.000px por 580px)', value: 'after_featured' }
];

const targetOptions = [
    { label: 'Não se aplica', value: null },
    { label: 'Nova aba', value: '_blank' },
    { label: 'Mesma aba', value: '_self' }
];

const positionOptions = [
    { label: '1ª posição', value: 1 },
    { label: '2ª posição', value: 2 },
    { label: '3ª posição', value: 3 },
    { label: '4ª posição', value: 4 },
    { label: '5ª posição', value: 5 }
];

const BannerList = () => {
    const navigate = useNavigate();
    
    const [loading, setLoading] = useState(true);
    const [banners, setBanners] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [status, setStatus] = useState('');
    const [position, setPosition] = useState('');
    const [type, setType] = useState('');
    const [link, setLink] = useState('');
    const [target, setTarget] = useState('');

    const [imageBase64, setImageBase64] = useState('');
    const [imageName, setImageName] = useState('');

    const [bannerToDelete, setBannerToDelete] = useState(null);

    useEffect(() => {
        getBanners();
    }, []);

    useEffect(() => {
        generateData();
    }, [banners]);

    useEffect(() => {
        if(!showCreate) clean();
    }, [showCreate]);

    useEffect(() => {
        if(!showEdit) clean();
    }, [showEdit]);

    const getBanners = () => {
        api.get('/banner').then(res => {
            console.log(res.data.resources);
            setBanners(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Imagem', 'Posição', 'Nome', 'Status', 'Link'];
        const rows = {};
        
        typeOptions.map(type => {
            rows[type.value] = [];
            banners.map(banner => {
                if(banner.type !== type.value) return;

                const image = <img src={banner.image_url.small} />;
                rows[type.value].push({
                    data: [
                        image,
                        banner.name,
                        banner.status ? 'Ativo' : 'Inativo',
                        `${banner.position}º posição`,
                        banner.link
                    ],
                    action: () => editBanner(banner)
                })
            });
        })

        console.log(rows);

        setData({header, rows});
    }

    const storeBanner = () => {
        setLoading(true);

        api.post(`/banner`, {
            name,
            status,
            position,
            type,
            link,
            target,
            image: imageBase64
        }).then(res => {
            getBanners();
            setShowCreate(false);
            toast.success('Banner cadastrado com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const editBanner = banner => {
        setId(banner.id);
        setName(banner.name);
        setStatus(banner.status);
        setPosition(banner.position);
        setType(banner.type);
        setLink(banner.link);
        setTarget(banner.target);
        setImageBase64('');
        setImageName(banner.image_url.small);
        setShowEdit(true);
    }

    const updateBanner = () => {
        setLoading(true);

        api.put(`/banner/${id}`, {
            name,
            status,
            position,
            type,
            link,
            target,
            image: imageBase64
        }).then(res => {
            getBanners();
            setShowEdit(false);
            toast.success('Banner atualizado com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const clean = () => {
        setId('');
        setName('');
        setStatus('');
        setPosition('');
        setType('');
        setLink('');
        setTarget('');
        setImageBase64('');
        setImageName('');
    }

    const deleteBanner = () => {
        setLoading(true);

        api.delete(`/banner/${bannerToDelete}`).then(res => {
            setBannerToDelete(null);
            setShowEdit(false);
            getBanners();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            {(showCreate || showEdit) &&
                <Modal
                    title={`${showCreate ? 'Cadastrar' : 'Editar'} banner`}
                    show={true}
                    close={() => showCreate ? setShowCreate(false) : setShowEdit(false)}
                    footer={
                        <>
                            {showEdit &&
                                <Button
                                    type={`secondary`}
                                    size={`small`}
                                    text={`Excluir`}
                                    svg={`delete-small`}
                                    action={() => setBannerToDelete(id)}
                                    loading={loading} 
                                />
                            }
                            <Button
                                type={`primary`}
                                size={`small`}
                                text={showCreate ? 'Cadastrar' : 'Salvar alterações'}
                                svg={`save-white`}
                                action={() => showCreate ? storeBanner() : updateBanner()}
                                loading={loading} 
                            />
                        </>
                    }
                >
                    <div className="section">
                        <div className="row">
                            <div className="col-6">
                                <InputSelect label={`Status`} options={statusOptions} value={status} change={setStatus} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Input type={`text`} label={`Nome`} value={name} change={setName} />
                            </div>
                            <div className="col-6">
                                <InputFile 
                                    label={`Imagem`} 
                                    base64={imageBase64}
                                    changeBase64={setImageBase64}
                                    name={imageName}
                                    changeName={setImageName}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <InputSelect label={`Tipo`} options={typeOptions} value={type} change={setType} />
                            </div>
                            <div className="col-6">
                                <InputSelect label={`Posição`} options={positionOptions} value={position} change={setPosition} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Input type={`text`} label={`Link`} value={link} change={setLink} />
                            </div>
                            <div className="col-6">
                                <InputSelect label={`Abertura em`} options={targetOptions} value={target} change={setTarget} />
                            </div>
                        </div>
                    </div>
                </Modal>
            }
            <ModalDelete
                show={bannerToDelete !== null}
                action={deleteBanner}
                close={() => setBannerToDelete(null)}
                loading={loading}
            />
            <PanelTemplate id="banner-list">
                <PageHeader
                    right={
                        <>
                            <Button type={`primary`} size={`small`} text={`Cadastrar banner`} svg="plus-white" action={() => setShowCreate(true)}  />
                            {/* <Button type={`secondary`} size={`small`} text={`Filtrar`} svg="filter"  /> */}
                        </>
                    }
                />
                <BoxTabs
                    titles={[
                        'Slider',
                        'Pós slider',
                        'Pís lançamentos',
                        'Pós destaques'
                    ]}
                    contents={[
                        <Table header={data.header} rows={data.rows.slider} loading={loading} />,
                        <Table header={data.header} rows={data.rows.after_slider} loading={loading} />,
                        <Table header={data.header} rows={data.rows.after_recent} loading={loading} />,
                        <Table header={data.header} rows={data.rows.after_featured} loading={loading} />
                    ]}
                />
            </PanelTemplate>
        </>
    );
}

export default BannerList;